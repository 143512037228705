import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import Construction from '../../assets/images/under-construction.svg'
import { useNavigate } from 'react-router-dom'

const ForbiddenError = () => {

    const navigate = useNavigate();

    return (
        <Box sx={{ minHeight: "calc(100vh - 98px)", display: "flex", alignItems: "center", }}>
            <Paper sx={{ py: { xs: 2, sm: 4 }, px: { xs: 2, sm: 4, md: 6 }, width: '100%', }}>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Typography sx={{ fontSize: { xs: "2.5rem", sm: "5rem" }, fontWeight: 600 }}>Oops!</Typography>
                        </Box>
                        <Box sx={{ mt: -1 }}>
                            <Typography sx={{ fontSize: { xs: "1.4rem", sm: "2.4rem" } }}>Under Construction</Typography>
                        </Box>
                        <Box sx={{ mt: 1 }}>
                            <Typography sx={{ fontSize: { xs: '14px', sm: "15px" }, color: "#838383" }}>Our page is currently undergoing scheduled maintenance, will be right back in a few hours.</Typography>
                        </Box>
                        <Box sx={{ mt: 4 }}>
                            {/* <Link to={navigate(-1)} style={{ fontSize: "18px", padding: "10px 30px", backgroundColor: "#00b395", borderRadius: "6px", textDecoration: "none", color: "#fff" }}>Go Back</Link> */}
                            <Button sx={{ fontSize: "18px", padding: "4px 30px", }} onClick={() => { navigate(-1) }}>Back</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img src={Construction} alt="" />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}

export default ForbiddenError