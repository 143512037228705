import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../redux/reducers/auth-slice';
import { axiosFetch } from '../utils/apiUtils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';


const useRefreshToken = () => {
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.ui)
    const { i18n } = useTranslation()

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [])

    const refresh = async () => {
        try {
            const response = await axiosFetch.get('accounts/token/refresh/', {
                withCredentials: true,
            });
            const { access_token, user } = response.data.data
            dispatch(authActions.grantLoginAccess({
                accessToken: access_token,
                user: { ...user },
                isLoggedIn: true
            }));
            return access_token
        } catch (err) {
            console.error("🚀 ~ file: use-refresh-token.js:21 ~ refresh ~ err:", err)
            dispatch(authActions.removeLoginAccess());
            localStorage.removeItem('user');
            window.location.replace('/accounts/signin');
        }
    }
    return refresh;
}

export default useRefreshToken;