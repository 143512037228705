import { Avatar, AvatarGroup, Box, Tooltip, Typography, Stack } from '@mui/material'
import React from 'react'

const CustomAvatarGroup = ({ users = [], limit = 4, open }) => {
    return (
        <AvatarGroup
            renderSurplus={() => {
                return <Stack onClick={open} sx={{ height: 1, width: 1, alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                    <Typography sx={{ fontSize: 12, fontWeight: 600, }} >  +{(users?.length - limit) + 1}</Typography>
                </Stack>
            }}

            max={limit} sx={{ '.MuiAvatar-root': { width: 30, height: 30, fontSize: 14, backgroundColor: '#ffd492', fontWeight: 600, borderColor: '#fff', color: 'primary.main', textTransform: 'capitalize' }, alignItems: 'center', justifyContent: 'center' }}
        >
            {
                users?.map((user) => (
                    <Tooltip key={user?.id} title={`${user?.first_name} ${user?.last_name}`} placement='top' >
                        <Avatar alt={user?.first_name}>{user?.first_name[0]}</Avatar>
                    </Tooltip>
                ))
            }
        </AvatarGroup>
    )
}

export default CustomAvatarGroup