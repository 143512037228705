import { Box, Button, Typography, TextField as MuiTextField, Stack, InputAdornment, Grid, Paper, IconButton, } from '@mui/material'
import { useState } from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../components/TabPanel';
import UsersList from './UsersList';
import { useTranslation } from 'react-i18next';


const User = () => {
    
    const { t } = useTranslation()
    const [userTab, setUserTab] = useState(0);
    const handleUsersTab = (event, newValue) => {
        setUserTab(newValue);
    };

    return (
        <Box>
            <Box sx={{ mt: 1 }}>
                <Paper sx={{ backgroundColor: "background.card", mt: 1.5, borderRadius: 1.5, minHeight: 'calc(90vh - 120px)' }}>
                    <Box sx={{ p: 1 }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'background.light' }}>
                            <Tabs
                                value={userTab}
                                onChange={handleUsersTab}
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab label={t('users.customer')} sx={{ color: 'text.secondary' }} />
                                <Tab label={t('users.employee')} sx={{ color: 'text.secondary' }} />
                            </Tabs>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <TabPanel value={userTab} index={0}>
                                <UsersList userTab={userTab} />
                            </TabPanel>
                            <TabPanel value={userTab} index={1}>
                                <UsersList userTab={userTab} />
                            </TabPanel>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default User
