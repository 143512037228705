import { Alert, Avatar, Box, Button, Grid, IconButton, InputAdornment, Paper, Stack, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import PersonOffIcon from '@mui/icons-material/PersonOff';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../components/TabPanel';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Field, Form, Formik } from "formik";
import { TextField } from 'formik-mui'
import * as Yup from 'yup';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import VerifiedIcon from '@mui/icons-material/Verified';
import PersonalDetails from './PersonalDetails';
import ProfileView from './ProfileView';
import Popup from '../../components/Popup';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { alertMsg } from "../../utils/basicUtils";
import { authActions } from '../../redux/reducers/auth-slice';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/use-axios-private';
import useAuth from '../../hooks/use-auth';
import { useTranslation } from 'react-i18next';

const Profile = () => {
    const [showPop, setShowPop] = useState(false)
    const [headShot, setHeadShot] = useState("")
    const [showCurrentPassword, setShowCurrentPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [userAction, setUserAction] = useState(null)
    const [isCheckUniqueUsername, setIsCheckUniqueUsername] = useState(true)
    const axios = useAxiosPrivate()
    const { t } = useTranslation()
    const { user } = useAuth()
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }


    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const navigate = useNavigate();

    const [userDetail, setUserDetail] = useState({
        "id": 182,
        "username": "",
        "first_name": "",
        "last_name": "",
        "bio": "",
        "gender": "M",
        "dob": "",
        "mobile_number": "",
        "country_code": '',
        "mobile_code": 46,
        "city": "",
        "address_line_one": "",
        "address_line_two": "",
        "state": "",
        "country": "",
        "zipcode": "",
        "uin_types": [],
        "uin_type": "",
        "uin": "",
        "employee_id": "",
        "commencement_date": "",
        "cessation_date": ""
    });

    const checkUniqueUserName = async (usernameData) => {
        return new Promise(async (resolve, reject) => {
            await axios.post('users/check_username/',
                {
                    user_id: user.id,
                    username: usernameData

                }
            )
                .then(res => { resolve(true) })
                .catch(res => { resolve(false) })
        })
    }


    const deleteUserAccount = async () => {
        try {
            const response = await axios.delete(`users/delete/${userDetail.id}`)
            alertMsg(response.data.message, 'success')
            navigate('/accounts/signin')
        }
        catch (err) {
            console.error(err)
        }
    }

    const getProfileDetails = async () => {
        try {
            const response = await axios.get("users/profile/")
            setUserDetail(response.data.data.user)
        }
        catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        getProfileDetails()
    }, [])

    return (
        <Box>
            <Paper sx={{ backgroundColor: "background.card", mt: 1.5, borderRadius: 1.5, minHeight: 'calc(90vh - 120px)' }}>
                <Box sx={{ p: 1.5 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'background.light' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab label={t('menu.profile')} sx={{ color: 'text.secondary' }} />
                            <Tab label={t('profile.personal_details')} sx={{ color: 'text.secondary' }} />
                            <Tab label={t('profile.my_account')} sx={{ color: 'text.secondary' }} />
                            <Tab label={t('profile.change_password')} sx={{ color: 'text.secondary' }} />
                        </Tabs>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <TabPanel value={value} index={0}>
                            <ProfileView userDetail={userDetail} uin_types={userDetail.uin_types} handleTab={(e, tabValue) => { console.log(tabValue); handleChange(e, tabValue) }} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <PersonalDetails userDetail={userDetail} uin_types={userDetail.uin_types} setUserDetail={setUserDetail} />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Box>
                                <Box sx={{ border: '1px solid', borderColor: 'background.light', borderRadius: 2 }}>
                                    <Stack direction='row' sx={{ p: 1.5, gap: .5, alignItems: 'center', borderBottom: 1, borderColor: 'background.light', justify: 'space-between' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 600 }}>{t('profile.general_settings')}</Typography>
                                    </Stack>
                                    <Box sx={{ p: 1.5 }}>
                                        <Formik
                                            initialValues={{
                                                "username": userDetail ? userDetail.username : '',
                                                "email": userDetail ? userDetail.email : '',
                                            }}
                                            validationSchema={
                                                Yup.object({
                                                    username: Yup.string()
                                                        .required(t('profile.user_name_required'))
                                                        .matches(/^[a-zA-Z0-9_]+$/, t('profile.invaild_username'))
                                                        .min(3, t('profile.username_min_character'))
                                                        .max(35, t('profile.username_max_character'))
                                                        .test('username-exists', t('profile.username_already_exist'), () => {
                                                            return isCheckUniqueUsername; // Return false if the username exists
                                                        }),
                                                })
                                            }
                                            enableReinitialize={true}
                                            onSubmit={async (values, { setSubmitting, resetForm }) => {
                                                try {
                                                    setSubmitting(true)
                                                    const response = await axios.post(`users/update_username/`, {
                                                        "user_id": user.id,
                                                        "username": values.username,
                                                    })
                                                    setSubmitting(false)
                                                    getProfileDetails()
                                                    alertMsg(response.data.message, "success")
                                                }
                                                catch (err) {
                                                    setSubmitting(false)
                                                    console.error(err)
                                                }
                                                setSubmitting(false)
                                            }}

                                        >
                                            {({ isSubmitting, values, setFieldValue }) => (
                                                <Form style={{ width: '100%' }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={6}>
                                                            <Box>
                                                                <Field
                                                                    component={TextField}
                                                                    fullWidth
                                                                    name="username"
                                                                    label={t('profile.username')}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <PersonIcon sx={{ color: "#93939396", mr: 1.5, fontSize: 20 }} />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    onChange={async (e) => {
                                                                        const regex = /^[a-zA-Z0-9_]+$/
                                                                        setUserDetail(prev => ({ ...prev, 'username': e.target.value }))
                                                                        setFieldValue('username', e.target.value)
                                                                        const isValid = e.target.value.trim().length > 3 && regex.test(e.target.value) && await checkUniqueUserName(e.target.value)
                                                                        setIsCheckUniqueUsername(isValid)
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Box>
                                                                <Field
                                                                    disabled
                                                                    component={TextField}
                                                                    fullWidth
                                                                    name="email"
                                                                    label={t('profile.email')}
                                                                    InputProps={{
                                                                        shrink: true,
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <EmailIcon sx={{ color: "#93939396", mr: 1.5, fontSize: 20 }} />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                    <Box sx={{ mt: 2 }}>
                                                        <Button disabled={isSubmitting}
                                                            variant="contained"
                                                            // fullWidth
                                                            type='submit'
                                                            color="primary">{t('common.update')}</Button>
                                                    </Box>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Box>
                                </Box>
                                <Box sx={{ border: '1px solid', borderColor: 'background.light', borderRadius: 2, mt: 2 }}>
                                    <Stack direction='row' sx={{ p: 1.5, gap: .5, alignItems: 'center', borderBottom: 1, borderColor: 'background.light', justify: 'space-between' }}>
                                        <Typography variant="body1" sx={{ fontWeight: 600 }}>{t('profile.account_delete')}</Typography>
                                    </Stack>
                                    <Box sx={{ p: 1.5 }}>
                                        <Box>
                                            <Typography sx={{ color: "text.secondary" }}>{t('profile.account_delete_text')}.</Typography>
                                        </Box>
                                        <Box sx={{ mt: 2 }}>
                                            <Typography sx={{ color: "text.secondary" }}>{t('profile.account_delete_confirm')} <Typography component={"span"} sx={{ color: "#000", fontWeight: 600 }}>{user && user.first_name} {user && user.last_name}</Typography>?</Typography>
                                        </Box>
                                        <Box sx={{ mt: 2 }}>
                                            <Button variant="contained" onClick={() => { setShowPop(true); setUserAction('delete') }}>{t('common.delete_account')}</Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <Box sx={{ border: '1px solid', borderColor: 'background.light', borderRadius: 2 }}>
                                <Stack direction='row' sx={{ p: 1.5, gap: .5, alignItems: 'center', borderBottom: 1, borderColor: 'background.light', justify: 'space-between' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 600 }}>{t('profile.change_password')}</Typography>
                                </Stack>
                                <Box sx={{ p: 1.5 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Box>
                                                <Alert severity="warning" sx={{ boxShadow: 0 }}>{t('profile.change_password_alert')}</Alert>
                                            </Box>
                                            <Box sx={{ mt: 1 }}>
                                                <Formik
                                                    initialValues={{
                                                        "current_password": "",
                                                        "new_password": "",
                                                        "confirm_password": "",
                                                    }}
                                                    validationSchema={
                                                        Yup.object({
                                                            current_password: Yup.string()
                                                                .required(t('profile.current_password_required')),
                                                            new_password: Yup.string()
                                                                .required(t('profile.new_password_required'))
                                                                .min(6, t('profile.password_min_character'))
                                                                .max(100, t('profile.password_max_character')),
                                                            confirm_password: Yup.string()
                                                                .required(t('profile.confirm_password_required'))
                                                                .oneOf([Yup.ref('new_password'), null], t('profile.confirm_password_match')),
                                                        })
                                                    }
                                                    enableReinitialize={true}
                                                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                                                        try {
                                                            const response = await axios.put(`users/update_password/`, {
                                                                "id": user.id,
                                                                "current_password": values.current_password,
                                                                "new_password": values.new_password,
                                                            })
                                                            alertMsg(response.data.message, "success")
                                                            resetForm()
                                                        }
                                                        catch (err) {
                                                            console.error(err)
                                                        }
                                                        // console.log(values)
                                                        setSubmitting(false)
                                                    }}

                                                >
                                                    {({ isSubmitting, values }) => (
                                                        <Form style={{ width: '100%' }}>
                                                            <Box>
                                                                <Field
                                                                    component={TextField}
                                                                    margin="dense"
                                                                    fullWidth
                                                                    size="small"
                                                                    name="current_password"
                                                                    label={`${t('profile.current_password')} *`}
                                                                    type={showCurrentPassword ? "text" : "password"}
                                                                    InputProps={{
                                                                        // style: { backgroundColor: '#efefefe6' },
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    onClick={() => { setShowCurrentPassword(prev => !prev) }}
                                                                                    onMouseDown={handleMouseDownPassword}
                                                                                    edge="end"
                                                                                    size="small"
                                                                                    sx={{ mr: .5 }}
                                                                                >
                                                                                    {showCurrentPassword ? (
                                                                                        <VisibilityOutlinedIcon sx={{ color: " #93939396", fontSize: 20 }} />
                                                                                    ) : (
                                                                                        <VisibilityOffOutlinedIcon sx={{ color: " #93939396", fontSize: 20 }} />
                                                                                    )}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box>
                                                                <Field
                                                                    component={TextField}
                                                                    margin="dense"
                                                                    fullWidth
                                                                    size="small"
                                                                    name="new_password"
                                                                    label={`${t('profile.new_password')} *`}
                                                                    sx={{ ".MuiFormHelperText-root": { mt: 1.3 } }}
                                                                    type={showNewPassword ? "text" : "password"}
                                                                    InputProps={{
                                                                        // style: { backgroundColor: '#efefefe6' },
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    onClick={() => { setShowNewPassword(prev => !prev) }}
                                                                                    onMouseDown={handleMouseDownPassword}
                                                                                    edge="end"
                                                                                    size="small"
                                                                                    sx={{ mr: .5 }}
                                                                                >
                                                                                    {showNewPassword ? (
                                                                                        <VisibilityOutlinedIcon sx={{ color: " #93939396", fontSize: 20 }} />
                                                                                    ) : (
                                                                                        <VisibilityOffOutlinedIcon sx={{ color: " #93939396", fontSize: 20 }} />
                                                                                    )}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box>
                                                                <Field
                                                                    component={TextField}
                                                                    margin="dense"
                                                                    fullWidth
                                                                    size="small"
                                                                    name="confirm_password"
                                                                    label={`${t('profile.confirm_new_password')} *`}
                                                                    type={showConfirmPassword ? "text" : "password"}
                                                                    InputProps={{
                                                                        // style: { backgroundColor: '#efefefe6' },
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    onClick={() => { setShowConfirmPassword(prev => !prev) }}
                                                                                    onMouseDown={handleMouseDownPassword}
                                                                                    edge="end"
                                                                                    size="small"
                                                                                    sx={{ mr: .5 }}
                                                                                >
                                                                                    {showConfirmPassword ? (
                                                                                        <VisibilityOutlinedIcon sx={{ color: " #93939396", fontSize: 20 }} />
                                                                                    ) : (
                                                                                        <VisibilityOffOutlinedIcon sx={{ color: " #93939396", fontSize: 20 }} />
                                                                                    )}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Box sx={{ mt: 3 }}>
                                                                <Button disabled={isSubmitting}
                                                                    variant="contained"
                                                                    // fullWidth
                                                                    type='submit'
                                                                    color="primary">{t('profile.update_password')}</Button>
                                                            </Box>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', color: "text.secondary", fontWeight: 500, mt: .6 }}>{t('profile.password_notes')}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', color: "text.secondary", fontWeight: 500, mt: .6 }}>{t('profile.password_contain')}</Typography>
                                            </Box>
                                            <Box sx={{ mt: 1 }}>
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: "center", mb: 1 }}>
                                                    <Box sx={{ height: "20px" }}>
                                                        <VerifiedIcon sx={{ fontSize: "20px", color: 'primary.main' }} />
                                                    </Box>
                                                    <Box>
                                                        <Typography>{t('profile.point_one')}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: "center", mb: 1 }}>
                                                    <Box sx={{ height: "20px" }}>
                                                        <VerifiedIcon sx={{ fontSize: "20px", color: 'primary.main' }} />
                                                    </Box>
                                                    <Box>
                                                        <Typography>{t('profile.point_two')}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: "center", mb: 1 }}>
                                                    <Box sx={{ height: "20px" }}>
                                                        <VerifiedIcon sx={{ fontSize: "20px", color: 'primary.main' }} />
                                                    </Box>
                                                    <Box>
                                                        <Typography>{t('profile.point_three')}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: "center", mb: 1 }}>
                                                    <Box sx={{ height: "20px" }}>
                                                        <VerifiedIcon sx={{ fontSize: "20px", color: 'primary.main' }} />
                                                    </Box>
                                                    <Box>
                                                        <Typography>{t('profile.point_four')}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: "center", mb: 1 }}>
                                                    <Box sx={{ height: "20px" }}>
                                                        <VerifiedIcon sx={{ fontSize: "20px", color: 'primary.main' }} />
                                                    </Box>
                                                    <Box>
                                                        <Typography>{t('profile.point_five')}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </TabPanel>
                    </Box>
                </Box>
            </Paper>
            <Popup show={showPop} title={userAction === 'delete' ? "Delete Account" : 'Deactivate Account'} consent={true} variant={userAction === 'delete' ? 'error' : 'warning'} primaryBtnTxt={userAction === 'delete' ? 'delete' : 'deactivate'}
                onPrimaryClick={async (e, loader) => { deleteUserAccount() }} onSecondaryClick={() => { setShowPop(false) }}
            >
                <Box sx={{ textAlign: "center" }}>
                    <Typography color='text.secondarydefault' >
                        Are you sure you want to delete this account ?
                        You can't undo this action.</Typography>
                </Box>
            </Popup>

        </Box >
    )
}

export default Profile