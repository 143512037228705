import { Box, Button, Container, Grid, Typography, Stack } from '@mui/material'
import React from 'react'
import { Formik, Form, Field } from "formik";
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import { alertMsg } from '../../utils/basicUtils';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { axiosPrivate } from '../../utils/apiUtils';
import { useSearchParams } from "react-router-dom"
import useAxios from "../../hooks/use-axios";
import { CircularProgress } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useEffect } from "react"
import InputAdornment from '@mui/material/InputAdornment';
import ActivateImg from '../../assets/images/resetPassword.svg'
import Logoimage from '../../assets/images/logo.png'
import { useTheme } from '@emotion/react'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import PasswordStrength from '../../components/PasswordStrength';

const RenderReset = () => {
    const navigate = useNavigate();
    const [isShowPassword, setIsShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [paramsString] = useSearchParams();
    const axios = useAxios()
    const [forgetPasswordStatus, setForgetPasswordStatus] = useState('LOADING')

    const activateForgetPassword = async () => {
        try {
            const resp = await axios.get('accounts/reset_password/', {
                params: {
                    uid: paramsString.get('uid'), token: paramsString.get('token')
                }
            })
            setForgetPasswordStatus(resp.data.data.reason)
        }
        catch (error) {
            console.error(error)
            setForgetPasswordStatus(error.response.data.data.reason)
        }
    }

    useEffect(() => {
        activateForgetPassword()
    }, [])
    switch (forgetPasswordStatus) {
        case 'LINK_VALID':
            return (
                <div>
                    <Box sx={{ textAlign: "center" }}>
                        <Typography sx={{ fontWeight: 500, fontSize: { xs: '18px', sm: "20px" } }}>Reset Password</Typography>
                    </Box>
                    <Formik
                        initialValues={{
                            password: '',
                            confirm_password: '',
                        }}
                        validationSchema={
                            Yup.object({
                                password: Yup.string()
                                    .required('Password is required')
                                    .min(8, "Must be at least 8 characters")
                                    .max(100, "Password cannot exceed 100 characters"),
                                confirm_password: Yup.string()
                                    .required("Confirm password is required")
                                    .oneOf([Yup.ref('password'), null], "Passwords doesn't match"),
                            })
                        }
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            try {
                                const response = await axiosPrivate.post("accounts/save_forgot_password/",
                                    {
                                        uid: paramsString.get('uid'),
                                        token: paramsString.get('token'),
                                        password: values.password
                                    }
                                )
                                alertMsg(response.data.message, 'success')
                                resetForm()
                            }
                            catch (error) {
                                console.error(error)
                            }
                            setSubmitting(false);
                        }}
                    >

                        {
                            ({ isSubmitting, submitForm, values }) => {
                                return (
                                    <Form>
                                        <Box sx={{ maxWidth: { xs: '100%', sm: '80%' }, mx: 'auto' }}>
                                            <Box sx={{ mt: 3, position: "relative" }}>
                                                <Field
                                                    component={TextField}
                                                    fullWidth
                                                    type={!isShowPassword ? "password" : "text"}
                                                    size="small"
                                                    name="password"
                                                    label="Password *"
                                                    inputProps={{
                                                        style: { backgroundColor: '#fff' }
                                                    }}
                                                    InputProps={{
                                                        style: { backgroundColor: '#fff' },
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() => { setIsShowPassword(prev => !prev) }}
                                                                    edge="end"
                                                                    sx={{ mr: .5 }}
                                                                >
                                                                    {isShowPassword ? (
                                                                        <VisibilityOutlinedIcon sx={{ color: "#93939396", fontSize: 20 }} />
                                                                    ) : (
                                                                        <VisibilityOffOutlinedIcon sx={{ color: "#93939396", fontSize: 20 }} />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <PasswordStrength password={values.password} sx={{ mt: .5 }} ></PasswordStrength>
                                            </Box>
                                            <Box sx={{ mt: 3 }}>
                                                <Field
                                                    component={TextField}
                                                    fullWidth
                                                    type={!showConfirmPassword ? "password" : "text"}
                                                    size="small"
                                                    name="confirm_password"
                                                    label="Confirm New password *"
                                                    inputProps={{
                                                        // maxLength: 15,
                                                        style: { backgroundColor: '#fff' }
                                                    }}
                                                    InputProps={{
                                                        style: { backgroundColor: '#fff' },
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() => { setShowConfirmPassword(prev => !prev) }}
                                                                    edge="end"
                                                                    sx={{ mr: .5 }}
                                                                >
                                                                    {showConfirmPassword ? (
                                                                        <VisibilityOutlinedIcon sx={{ color: "#93939396", fontSize: 20 }} />
                                                                    ) : (
                                                                        <VisibilityOffOutlinedIcon sx={{ color: "#93939396", fontSize: 20 }} />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box sx={{ mt: 3, textAlign: "center" }}>
                                            <Button
                                                variant='contained'
                                                sx={{ px: 5 }}
                                                disabled={isSubmitting} onClick={(e) => {
                                                    if (e.detail === 1) {
                                                        submitForm()
                                                        setTimeout(() => {
                                                            navigate('/accounts/signin')
                                                        }, 5000)
                                                    }
                                                }}>Submit</Button>
                                        </Box>
                                    </Form>
                                )
                            }
                        }
                    </Formik>
                </div >
            )
        case 'LINK_ALREADY_USED':
            return (
                <>
                    <Stack direction={'row'} alignItems={'center'} gap={1} justifyContent={'center'}>
                        <Box>
                            <ErrorOutlineIcon sx={{ fontSize: "30px", padding: '8px', borderRadius: '50%', backgroundColor: '#fff' }} color={'error'}></ErrorOutlineIcon>
                        </Box>
                        <Typography sx={{ fontWeight: 500, fontSize: { xs: '18px', sm: "22px" }, }}>Reset password link is <span style={{ color: '#e78c00', fontWeight: 600 }}>Already used</span></Typography>
                    </Stack>
                    <Box sx={{ mt: 2, textAlign: "center" }}>
                        <Typography sx={{ fontSize: { xs: '12px', sm: "14px" }, width: { xs: '100%', sm: "80%" }, mx: 'auto' }} color='text.secondary'>For further assistance, Please get in touch with our technical support team.</Typography>
                    </Box>
                </>
            )
        case 'LINK_EXPIRED':
            return (
                <>
                    <Stack direction={'row'} alignItems={'center'} gap={1} justifyContent={'center'}>
                        <Box>
                            <ErrorOutlineIcon sx={{ fontSize: "30px", padding: '8px', borderRadius: '50%', backgroundColor: '#fff' }} color={'error'}></ErrorOutlineIcon>
                        </Box>
                        <Typography sx={{ fontWeight: 500, fontSize: { xs: '18px', sm: "22px" }, }}>Reset password link is <span style={{ color: '#e78c00', fontWeight: 600 }}>Expired</span></Typography>
                    </Stack>
                    <Box sx={{ mt: 3, textAlign: "center" }}>
                        <Typography sx={{ fontSize: { xs: '14px', sm: "16px" }, mb: 2 }} color='text.secondary'>The link has been either expired or invalid. Requesting you to generate the link again by visiting our website.</Typography>
                        <Box sx={{ mb: 3, textAlign: "center" }}>
                            <Button variant='contained' type="submit" size='small' color="error" sx={{ textTransform: "none" }} onClick={() => { navigate('/accounts/signin') }}>Back to signin page</Button>
                        </Box>
                        <Typography sx={{ fontSize: { xs: '12px', sm: "14px" }, width: { xs: '100%', sm: "80%" }, mx: 'auto' }} color='text.secondary'>For further assistance, Please get in touch with our technical support team.</Typography>
                    </Box>
                </>
            )

        case 'LINK_INVALID':
            return (
                <>
                    <Stack direction={'row'} alignItems={'center'} gap={1} justifyContent={'center'}>
                        <Box>
                            <ErrorOutlineIcon sx={{ fontSize: "30px", padding: '8px', borderRadius: '50%', backgroundColor: '#fff' }} color={'error'}></ErrorOutlineIcon>
                        </Box>
                        <Typography sx={{ fontWeight: 500, fontSize: { xs: '18px', sm: "22px" }, }}>Reset password link is <span style={{ color: '#e78c00', fontWeight: 600 }}>Invalid</span></Typography>
                    </Stack>
                    <Box sx={{ mt: 3, textAlign: "center" }}>
                        <Typography sx={{ fontSize: { xs: '14px', sm: "16px" }, mb: 2 }} color='text.secondary'>The link has been either expired or invalid. Requesting you to generate the link again by visiting our website.</Typography>
                        <Box sx={{ mb: 3, textAlign: "center" }}>
                            <Button variant='contained' type="submit" size='small' color="error" sx={{ textTransform: "none" }} onClick={() => { navigate('/accounts/signin') }}>Back to signin page</Button>
                        </Box>
                        <Typography sx={{ fontSize: { xs: '12px', sm: "14px" }, width: { xs: '100%', sm: "80%" }, mx: 'auto' }} color='text.secondary'>For further assistance, Please get in touch with our technical support team.</Typography>
                    </Box>
                </>
            )
        case 'LOADING':
            return (
                <>
                    <Box sx={{ textAlign: "center" }}>
                        <CircularProgress />
                    </Box>
                    <Box sx={{ mt: 2, textAlign: "center" }}>
                        <Typography sx={{ fontWeight: 500, fontSize: { xs: '18px', sm: "22px" }, }}>Validating <span style={{ color: '#e78c00', fontWeight: 600 }}>Password Link</span></Typography>
                    </Box>
                    <Box sx={{ mt: 2, textAlign: "center" }}>
                        <Typography sx={{ fontWeight: 400, fontSize: { xs: '14px', sm: "16px" } }}>Please wait while we validating your reset password link </Typography>
                    </Box>
                    <Box sx={{ mt: 2, textAlign: "center" }}>
                        <Typography sx={{ fontSize: { xs: '12px', sm: "14px" }, width: { xs: '100%', sm: "80%" }, mx: 'auto' }} color='text.secondary'>For further assistance, Please get in touch with our technical support team.</Typography>
                    </Box>
                </>
            )
        default:
            return (
                <>
                    <Stack direction={'row'} alignItems={'center'} gap={1} justifyContent={'center'}>
                        <Box>
                            <ErrorOutlineIcon sx={{ fontSize: "30px", padding: '8px', borderRadius: '50%', backgroundColor: '#fff' }} color={'error'}></ErrorOutlineIcon>
                        </Box>
                        <Typography sx={{ fontWeight: 500, fontSize: { xs: '18px', sm: "22px" }, }}>Unable to <span style={{ color: '#e78c00', fontWeight: 600 }}>Reset Password</span></Typography>
                    </Stack>
                    <Box sx={{ mt: 2, textAlign: "center" }}>
                        <Button variant='contained' type="submit" color="error" size='small' onClick={activateForgetPassword}>try again</Button>
                    </Box>
                    <Box sx={{ mt: 2, textAlign: "center" }}>
                        <Typography sx={{ fontSize: { xs: '12px', sm: "14px" }, width: { xs: '100%', sm: "80%" }, mx: 'auto' }} color='text.secondary'>For further assistance, Please get in touch with our technical support team.</Typography>
                    </Box>
                </>
            )
    }
}
const ResetPassword = () => {
    const theme = useTheme()
    return (
        <Box sx={{ backgroundColor: '#FEF3E2' }}>
            <Container maxWidth="lg" sx={{ display: 'flex', alignItems: 'center', minHeight: '100vh' }}>
                <Grid container sx={{ boxShadow: "0 5px 15px #e78c001c", backgroundColor: '#fff', borderRadius: 2, overflow: 'hidden' }}>
                    <Grid item xs={12} md={6} >
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} sx={{ mt: 2 }} gap={0}>
                            <Box>
                                <img src={Logoimage} style={{ height: '100%', width: '100%', minHeight: '40px', maxWidth: '100px', objectFit: 'contain', borderRadius: 50, padding: 5 }} />
                            </Box>
                        </Stack>
                        <Box sx={{ p: 2 }}>
                            <Stack justifyContent={'center'} sx={{ borderRadius: 2, minHeight: '450px', p: 2, border: '1px solid', borderColor: 'primary.main', }}>
                                <RenderReset />
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }} >
                        <Box sx={{ p: 2, height: 'calc(100% - 32px)' }}>
                            <Stack sx={{ border: '1px solid', borderColor: 'primary.main', borderRadius: 2, height: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={ActivateImg} alt="" style={{ width: '400px', height: '400px' }} />
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box >
    )
}

export default ResetPassword