import { Box, Typography, Button, TextField as MuiTextField, Stack, Tooltip, TableContainer, InputAdornment, Badge, Table, TableHead, TableCell, TableRow, TableBody, TableFooter, TablePagination, Avatar, IconButton, MenuItem, ListItemIcon, ListItemText, Grid, Paper, Menu, Autocomplete, Chip, Pagination, ListItem, useMediaQuery } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import PersonIcon from '@mui/icons-material/Person';
import Popup from '../../components/Popup'
import { alertMsg, getAddressJSON } from '../../utils/basicUtils';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import 'react-phone-input-2/lib/material.css'
import useAuth from '../../hooks/use-auth';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import useAxiosPrivate from '../../hooks/use-axios-private';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import ModalBox from '../../components/ModalBox';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Formik, Form, Field } from "formik";
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { ErrorMessage } from 'formik';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import { API_KEY, MAP_SCRIPTS } from '../../constants';
import { CloseOutlined, MailOutlineOutlined, Warning } from '@mui/icons-material';
import Empty from '../../components/Empty';
import AddIcon from '@mui/icons-material/Add';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import Preloader from '../../components/Preloader';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';


const initialUserValues = {
    username: '',
    first_name: '',
    last_name: '',
    company_name: '',
    company_id: '',
    password: '',
    email: '',
    country_code: '',
    mobile_code: 46,
    mobile_number: '',
    street: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
    company: null

}


const UserAction = ({ deleteSelectedUser, setShowDeletePop, userItem, setOpen, getUsersInfo }) => {
    const [isActionsOpen, setIsActionsOpen] = useState(false);
    const { t } = useTranslation()
    const handleActions = (event) => {
        setIsActionsOpen(event.currentTarget);
    };
    const { user } = useAuth()

    return (
        <Box>
            <IconButton size='small' onClick={handleActions} >
                <MoreVertIcon color='primary' />
            </IconButton>
            <Menu
                anchorEl={isActionsOpen}
                open={Boolean(isActionsOpen)}
                onClose={() => setIsActionsOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <MenuItem onClick={
                    async () => {
                        await getUsersInfo(userItem.id);
                        setOpen({ isOpen: true, mode: 1 });
                        setIsActionsOpen(false)
                    }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                        <EditOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('common.edit')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => { deleteSelectedUser(userItem); setShowDeletePop(true); setIsActionsOpen(false) }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                        {userItem.is_active !== 3 ?
                            <DeleteOutlineOutlinedIcon fontSize="small" />
                            :
                            <SettingsBackupRestoreOutlinedIcon fontSize="small" />
                        }
                    </ListItemIcon>
                    <ListItemText>{userItem.is_active !== 3 ? t('common.delete') : 'Restore'}</ListItemText>
                </MenuItem>
            </Menu >
        </Box >
    )
}

const UsersList = ({ userTab }) => {
    const isTabView = useMediaQuery("(max-width: 900px)")
    const axios = useAxiosPrivate()
    const [showDeletePop, setShowDeletePop] = useState(false)
    const [showDeactivePop, setShowDeactivePop] = useState(false)
    const [open, setOpen] = useState({ isOpen: false, mode: null })
    const [selectedUser, setSelectedUser] = useState(null)
    const [isGridView, setIsGridView] = useState(false)
    const [users, setUsers] = useState([])
    const [pageInfo, setPageInfo] = useState({})
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchText, setSearchText] = useState('')
    const { user } = useAuth()
    const [companyLst, setCompanyLst] = useState([])
    const [userValues, setUserValues] = useState(initialUserValues)
    const [isCheckUniqueEmail, setIsCheckUniqueEmail] = useState(false)
    const [isCheckUniqueUsername, setIsCheckUniqueUsername] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [isAddNewCompany, setIsAddNewCompany] = useState(false)
    const [openUserMenu, setOpenUserMenu] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const inputRef = useRef();
    const { t } = useTranslation()
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: API_KEY,
        libraries: MAP_SCRIPTS,
    })


    const getUsersList = async () => {
        try {
            setIsLoading(true)
            const response = await axios.get('users/list/', {
                params: {
                    page: page + 1,
                    search: searchText,
                    limit: rowsPerPage,
                    role_id: userTab + 3,
                    order_by: '-created_at',

                }
            })
            setUsers([...response.data.data.users])
            setPageInfo(response.data.data.page_info)
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            console.error(err)
        }
    }

    const checkUniqueEmail = async (emailData) => {
        return new Promise(async (resolve, reject) => {
            await axios.post('/users/check_email/', {
                user_id: user.id,
                email: emailData

            })
                .then(res => { resolve(true) })
                .catch(res => { resolve(false) })
        })
    }
    const checkUniqueUserName = async (usernameData) => {
        return new Promise(async (resolve, reject) => {
            await axios.post('users/check_username/',
                {
                    user_id: user.id,
                    username: usernameData

                }
            )
                .then(res => { resolve(true) })
                .catch(res => { resolve(false) })
        })
    }

    const getUsersInfo = async (id) => {
        try {
            const response = await axios.get(`users/${id}`)
            setUserValues({ ...response.data.data.user })
        } catch (err) {
            console.error(err)
        }
    }

    const getCompanyList = async () => {
        try {
            const response = await axios.get(`users/company_list/`, {
                params: {
                    has_users: false,
                }
            })
            setCompanyLst([...response.data.data.companies])

        } catch (err) {
            console.error(err)
        }
    }

    const handleUserStatus = async (userId, userStatus) => {
        try {
            const response = await axios.post(`users/change_status/`, {
                user_id: userId
            })
            if (userStatus === 3) {
                alertMsg('Account restore successfully', "success")
            }
            else {
                alertMsg(response.data.message, "success")
            }
            getUsersList()

        } catch (err) {
            console.error(err)
        }
    }
    const handleDeleteUser = async () => {
        try {
            const response = await axios.delete(`users/delete/${selectedUser?.id}`)
            getUsersList()
            alertMsg(response.data.message, 'success')
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        getUsersList()
    }, [userTab, page, rowsPerPage, searchText])

    useEffect(() => {
        getCompanyList()
    }, [])

    useEffect(() => {
        if (isTabView) {
            setIsGridView(true);
        }
        else {
            setIsGridView(false);
        }
    }, [isTabView])

    return (
        <Box>
            <Stack sx={{ mt: { xs: 1, md: 0 }, flexDirection: 'row', gap: 1, alignItems: 'center', flexWrap: "wrap", justifyContent: 'space-between' }}>
                <Box>
                    <MuiTextField
                        fullWidth
                        sx={{ width: "100%", backgroundColor: '#f3f3f3', "& fieldset": { border: "0 !important" } }}
                        placeholder={t('common.search')}
                        size={'small'}
                        value={searchText}
                        onChange={(e) => { setSearchText(e.target.value) }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ ml: 1.2, fontSize: 20 }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Button sx={{ height: '31px', width: '31px', p: '5px ', minWidth: 'auto' }} variant='outlined' onClick={() => setIsGridView(prev => !prev)}>
                            {isGridView ?
                                <ListOutlinedIcon sx={{ fontSize: 20 }} /> : <GridViewOutlinedIcon sx={{ fontSize: 20 }} />
                            }
                        </Button>
                    </Box>
                    <Box>
                        <Button
                            sx={{ whiteSpace: 'nowrap' }}
                            variant='outlined'
                            onClick={() => {
                                setIsAddNewCompany(false)
                                setUserValues(initialUserValues)
                                setOpen({ isOpen: true, mode: 0 })
                            }} startIcon={<AddOutlinedIcon />}>
                            {t('users.add_user')}
                        </Button>
                    </Box>
                </Box>
            </Stack>
            {
                isLoading ?
                    <Box sx={{ mt: 2, minHeight: '64vh', display: 'flex', alignItems: 'center', backgroundColor: '#ffff', border: '2px solid', borderRadius: 1, borderColor: "background.light" }}>
                        <Preloader preload={false} />
                    </Box>
                    :
                    <Box sx={{ mt: 2 }}>
                        {
                            users.length > 0 ? <>
                                {
                                    !isGridView ?
                                        <TableContainer>
                                            <Table stickyHeader size='small' >
                                                <TableHead>
                                                    <TableRow sx={{ '& th': { whiteSpace: 'nowrap', backgroundColor: 'background.card', p: 1.5, border: 0, borderBottom: '1px solid #ebf1fc' } }}>
                                                        <TableCell sx={{ minWidth: { xs: 200, lg: 'auto' } }}>
                                                            <Box> <Typography sx={{ fontWeight: 700 }} variant="body1">{t('users.name')}</Typography> </Box>
                                                        </TableCell>
                                                        <TableCell align={'center'}><Typography sx={{ fontWeight: 700 }}>{t('users.email')}</Typography></TableCell>
                                                        {
                                                            userTab == 0 &&
                                                            <TableCell align={'center'}><Typography sx={{ fontWeight: 700 }} variant="body1">{t('users.company')}</Typography>
                                                            </TableCell>
                                                        }
                                                        <TableCell align={'center'} sx={{ width: { xs: 150, xl: 200 } }}><Typography sx={{ fontWeight: 700 }}>{t('users.status')}</Typography></TableCell>
                                                        <TableCell align={'center'} sx={{ width: { xs: 150, xl: 200 } }}><Typography sx={{ fontWeight: 700 }}>{t('common.action')}</Typography></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        users.map((userItem, index) => (
                                                            <TableRow key={index} sx={{ '& td': { whiteSpace: 'nowrap', px: 0, borderBottom: '1px solid #ebf1fc', py: "2px" }, '& td>div': { px: "12px", py: '2px', backgroundColor: 'background.card', minHeight: 50, }, }}>
                                                                <TableCell>
                                                                    <Stack direction={'row'} sx={{ borderRadius: '6px 0px 0px 6px', gap: 1, alignItems: 'center' }}>
                                                                        <Tooltip title={userItem.is_active === 0 ? t('users.inactive') : userItem.is_active === 1 ? t('users.active') : userItem.is_active === 2 ? t('users.deactivate') : 'Deleted'} arrow placement='top'>
                                                                            <Badge
                                                                                overlap="circular"
                                                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                                variant="dot"
                                                                                sx={{
                                                                                    '& .MuiBadge-badge': {
                                                                                        backgroundColor: userItem.is_active === 0 ? '#ff9800' : userItem.is_active === 1 ? '#4caf50' : userItem.is_active === 2 ? '#4caf50' : 'error.main', height: 8, width: 8, borderRadius: 2, outline: userItem.is_active === 0 ? '1px solid #ff9800' : userItem.is_active === 1 ? '1px solid #4caf50' : userItem.is_active === 2 ? '1px solid #4caf50' : '1px solid #d32f2f', outlineOffset: '1.5px'
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <Avatar src="" sx={{ backgroundColor: '#f3f3f3', cursor: 'pointer' }}>
                                                                                    <PersonIcon sx={{ color: '#b9b9b9' }} />
                                                                                </Avatar>
                                                                            </Badge>
                                                                        </Tooltip>
                                                                        <Typography variant="body1" color="initial" sx={{ whiteSpace: 'nowrap' }}>{userItem.first_name} {userItem.last_name}</Typography>
                                                                    </Stack>
                                                                </TableCell>
                                                                <TableCell align={'center'}>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                        <Typography variant="body1">{userItem.email}</Typography>
                                                                    </Box>
                                                                </TableCell>
                                                                {
                                                                    userTab == 0 &&
                                                                    <TableCell align={'center'}>
                                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                            <Typography variant="body1">{userItem?.company?.company_name}</Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                }
                                                                <TableCell align={'center'} sx={{ width: { xs: 150, xl: 200 } }}>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                        <Chip size='small' variant="body1" sx={{ color: "#fff", backgroundColor: userItem.is_active === 0 ? '#ff9800' : userItem.is_active === 1 ? '#4caf50' : userItem.is_active === 2 ? '#4caf50' : '#d32f2f' }} label={userItem.is_active === 0 ? t('users.inactive') : userItem.is_active === 1 ? t('users.active') : userItem.is_active === 2 ? t('users.inactive') : 'Deleted'} />
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell align={'center'} sx={{ width: { xs: 150, xl: 200 } }}>
                                                                    <Stack direction={'row'} sx={{ borderRadius: '0px 6px 6px 0px', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                                                                        <Box sx={{ height: 40 }}>
                                                                            <Tooltip title={t('common.edit')} arrow placement='top'>
                                                                                <IconButton size='small' onClick={
                                                                                    async () => {
                                                                                        await getUsersInfo(userItem.id);
                                                                                        setOpen({ isOpen: true, mode: 1 })
                                                                                    }} color='success'>
                                                                                    <EditOutlinedIcon sx={{ fontSize: "22px" }} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Box>
                                                                        <Box sx={{ height: 40 }}>
                                                                            <Tooltip title={`${userItem.is_active !== 3 && userItem.is_active !== 0 ? `${t('users.deactivate')} ${t('menu.users')}` : `${t('users.activate')} ${t('menu.users')}`}`} arrow placement='top'>
                                                                                <IconButton disabled={userItem.is_active === 3} size='small' onClick={() => { setSelectedUser(userItem); setShowDeactivePop(true) }} color='warning'>
                                                                                    {
                                                                                        userItem.is_active !== 3 && userItem.is_active !== 0
                                                                                            ?
                                                                                            <VisibilityIcon sx={{ fontSize: "22px" }} />
                                                                                            :
                                                                                            <VisibilityOffIcon sx={{ fontSize: "22px" }} />
                                                                                    }
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Box>
                                                                        <Box sx={{ height: 40 }}>
                                                                            {
                                                                                userItem.is_active !== 3 ?
                                                                                    <Tooltip title={t('common.delete')} arrow placement='top'>
                                                                                        <IconButton size='small' onClick={() => { setSelectedUser(userItem); setShowDeletePop(true) }} color='error'>
                                                                                            <DeleteOutlineIcon sx={{ fontSize: "22px" }} />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    :
                                                                                    <Tooltip title={"Restore user's account"} arrow placement='top'>
                                                                                        <IconButton size='small' onClick={() => { setSelectedUser(userItem); setShowDeletePop(true) }} color='error'>
                                                                                            <SettingsBackupRestoreOutlinedIcon sx={{ fontSize: "22px" }} />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                            }
                                                                        </Box>
                                                                    </Stack>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                                <TableFooter sx={{ backgroundColor: 'background.card', p: 2, borderRadius: '5px' }}>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[10, 25, 50, 100]}
                                                            colSpan={10}
                                                            sx={{ borderBottom: 0, fontSize: 12, px: 0, "& .MuiInputBase-root": { width: "50px !important", mr: 1.3 }, "& input": { visibility: "hidden" }, "& p": { fontSize: 12 }, "& .MuiTablePagination-toolbar": { minHeight: "0px !important" }, "& .MuiSvgIcon-fontSizeMedium": { fontSize: 15 } }}
                                                            count={pageInfo.count}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            onPageChange={(e, newPage) => { setPage(newPage) }}
                                                            onRowsPerPageChange={(e) => { setRowsPerPage(parseInt(e.target.value), 10); setPage(0); }}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </TableContainer>
                                        :
                                        <Stack sx={{ gap: 1, justifyContent: 'space-between', minHeight: '71vh', }}>
                                            <Box sx={{ maxHeight: { xs: 'calc(100vh - 300px)', md: 'calc(100vh - 180px)' }, overflow: 'auto', pr: .2 }} className='scroll-bar'>
                                                <Grid container spacing={2} sx={{ pb: 1, justifyContent: 'center' }}>
                                                    {users.map((userItem, index) => (
                                                        <Grid key={index} item xs={12} sm={6} md={4} xl={3}>
                                                            <Box sx={{ border: '1px solid', borderColor: '#e78c0080', borderRadius: '10px', overflow: 'hidden', py: 3, position: 'relative' }}>
                                                                <Box sx={{ position: 'absolute', top: 10, right: 5 }}>
                                                                    <UserAction
                                                                        userItem={userItem}
                                                                        getUsersInfo={getUsersInfo}
                                                                        setOpen={setOpen}
                                                                        deleteSelectedUser={(user) => {
                                                                            setSelectedUser(user)
                                                                        }}
                                                                        setShowDeletePop={setShowDeletePop}
                                                                        setShowDeactivePop={setShowDeactivePop}
                                                                    />
                                                                </Box>
                                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <Badge
                                                                        overlap="circular"
                                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                        variant="dot"
                                                                        sx={{
                                                                            '& .MuiBadge-badge': {
                                                                                backgroundColor: userItem.is_active === 0 ? '#ff9800' : userItem.is_active === 1 ? '#4caf50' : userItem.is_active === 2 ? '#4caf50' : 'error.main', height: 8, width: 8, borderRadius: 2, outline: userItem.is_active === 0 ? '1px solid #ff9800' : userItem.is_active === 1 ? '1px solid #4caf50' : userItem.is_active === 2 ? '1px solid #4caf50' : '1px solid #d32f2f', outlineOffset: '1.5px', right: 'calc(14% + 4px)'
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Avatar sx={{ backgroundColor: '#f3c57f', width: 70, height: 70, border: '2px solid #fff' }}>
                                                                            <PersonIcon sx={{ color: '#fff', fontSize: 30 }} />
                                                                        </Avatar>
                                                                    </Badge>
                                                                </Box>

                                                                <Stack direction={'column'} sx={{ justifyContent: 'center', textAlign: 'center', gap: .5, mt: 2 }}>
                                                                    <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>{userItem.first_name} {userItem.last_name}</Typography>
                                                                    <Stack direction={'row'} sx={{ alignItems: 'center', gap: .5, justifyContent: 'center' }}>
                                                                        <Box sx={{ height: 18 }}>
                                                                            <MailOutlineOutlined color={'info'} sx={{ fontSize: '18px' }} />
                                                                        </Box>
                                                                        <Box>
                                                                            <Typography sx={{ fontSize: '14px', }}>{userItem.email}</Typography>
                                                                        </Box>
                                                                    </Stack>
                                                                    <Box sx={{ mt: .5 }}>

                                                                        <Button variant='outlined' color={userItem.is_active !== 3 && userItem.is_active !== 0 ? 'error' : 'success'} sx={{ borderRadius: 5 }} disabled={userItem.is_active === 3} onClick={() => { setSelectedUser(userItem); setShowDeactivePop(true) }}>
                                                                            {userItem.is_active !== 3 && userItem.is_active !== 0 ? t('users.deactivate') : t('users.activate')}
                                                                        </Button>
                                                                    </Box>
                                                                </Stack>
                                                            </Box>
                                                        </Grid>
                                                    ))}

                                                </Grid>
                                            </Box>
                                            <Stack sx={{ alignItems: 'center', py: 2 }}>
                                                <Pagination
                                                    variant="outlined"
                                                    color='primary'
                                                    count={pageInfo.num_pages}
                                                    page={page + 1}
                                                    onChange={(event, value) => { setPage(value - 1) }}
                                                />
                                            </Stack>
                                        </Stack >
                                }
                            </>
                                :
                                <>
                                    <Empty text={t('users.empty_text')} icon={<PersonIcon sx={{ fontSize: 30 }} />} sx={{ minHeight: '56vh' }} />
                                </>
                        }

                    </Box>

            }

            <Popup show={showDeletePop}
                title={
                    `${selectedUser?.is_active === 3 ? 'Restore' : t('common.delete')} ${t('menu.users')}`
                }
                consent={true} variant={selectedUser?.is_active === 3 ? 'warning' : 'error'} primaryBtnTxt={selectedUser?.is_active === 3 ? 'Restore' : t('common.delete')}
                onPrimaryClick={async (e, loader) => {
                    if (selectedUser?.is_active !== 3) {
                        handleDeleteUser()
                    }
                    else {
                        handleUserStatus(selectedUser?.id, selectedUser?.is_active)
                    }
                    setShowDeletePop(false)
                }}
                onSecondaryClick={() => { setShowDeletePop(false) }}
            >
                <Box sx={{ textAlign: "center" }}>
                    <Typography color='text.secondary' >{selectedUser?.is_active === 3 ? 'restore' : t('users.delete_text')} <span style={{ fontWeight: 600, color: "#000" }}>
                        {` ${selectedUser?.first_name} ${selectedUser?.last_name}`}
                    </span>.</Typography>
                </Box>
            </Popup>

            <Popup show={showDeactivePop}
                title={
                    `${selectedUser?.is_active === 0 || selectedUser?.is_active === 2 ? t('users.activate') : t('users.deactivate')} ${t('menu.users')}`
                }
                consent={true} variant={'warning'} primaryBtnTxt={selectedUser?.is_active === 0 || selectedUser?.is_active === 2 ? t('users.activate') : t('users.deactivate')}
                onPrimaryClick={async (e, loader) => {
                    handleUserStatus(selectedUser?.id, selectedUser?.is_active)
                    setShowDeactivePop(false)
                }}
                onSecondaryClick={() => { setShowDeactivePop(false) }}
            >
                <Box sx={{ textAlign: "center" }}>
                    <Typography color='text.secondary' >{selectedUser?.is_active === 0 || selectedUser?.is_active === 2 ? t('users.activate_text') : t('users.deactivate_text')} <span style={{ fontWeight: 600, color: "#000" }}>
                        {`${selectedUser?.first_name} ${selectedUser?.last_name}`}
                    </span>.</Typography>
                </Box>
            </Popup>

            <ModalBox
                open={open.isOpen}
                icon={<PersonAddAltOutlinedIcon color='primary' />}
                title={`${open.mode === 0 ? t('common.create') : t('common.edit')} ${userTab === 0 ? t('users.customer') : t('users.employee')}`}
                closeBtn
                onClose={(e, reason) => {
                    if (reason) return;
                    setOpen({ ...open, isOpen: false })
                }}
                sx={{ width: { xs: "100%", sm: "80% !important", md: "70% !important", lg: "45% !important" }, maxWidth: { xs: "95%", sm: "80%", md: "70%", lg: "45%" }, p: { xs: .8, md: 0 } }}>
                <Box>
                    <Box sx={{ mt: 1.5 }}>
                        <Formik
                            initialValues={userValues}
                            validationSchema={
                                Yup.object({
                                    first_name: Yup.string()
                                        .required(t('users.first_name_required'))
                                        .matches(/^[a-zA-Z\s]+$/, t('users.first_name_match'))
                                        .min(3, t('users.first_name_min_character'))
                                        .max(35, t('users.first_name_max_character')),
                                    email:
                                        open.mode === 0 &&
                                        Yup.string().email()
                                            .required(t('users.email_required'))
                                            .email(t('users.invaild_email'))
                                            .test('email-exists', t('users.email_already_exist'), () => {
                                                return isCheckUniqueEmail; // Return false if the email exists
                                            }),
                                    username:
                                        Yup.string()
                                            .required(t('users.user_name_required'))
                                            .matches(/^[a-zA-Z0-9_]+$/, t('users.invaild_username'))
                                            .min(3, t('users.username_min_character'))
                                            .max(35, t('users.username_max_character'))
                                            .test('username-exists', t('users.username_already_exist'), () => {
                                                return isCheckUniqueUsername; // Return false if the username exists
                                            }),
                                    password:
                                        open.mode === 0 &&
                                        Yup.string()
                                            .required(t('users.password_required'))
                                            .min(6, t('users.password_min_character'))
                                            .max(100, t('users.password_max_character')),
                                    mobile_number:
                                        Yup.string()
                                            .required(t('users.mobile_number_required')),
                                })
                            }
                            onSubmit={async (values, { setSubmitting }) => {
                                let formValues = values;
                                if (userTab !== 0) {
                                    values.company_id = 0
                                    values.company_name = ''
                                }
                                else {
                                    if (!isAddNewCompany) {
                                        formValues = { ...values, company_name: values.company.company_name, company_id: values.company.id, }
                                    }
                                    else {
                                        alertMsg('Save new company to create user', 'warning')
                                        return
                                    }
                                }
                                try {
                                    if (userValues.id) {
                                        const response = await axios.put(`users/update/${userValues.id}`, {
                                            ...formValues,
                                            "role_id": userTab + 3 // 2 - Admin, Accepts 3 - Customer, 4 - Employee
                                        })
                                        alertMsg(response.data.message, 'success')
                                    } else {
                                        const response = await axios.post('users/create_user/', {
                                            ...formValues,
                                            "role_id": userTab + 3 // 2 - Admin, Accepts 3 - Customer, 4 - Employee
                                        })
                                        alertMsg(response.data.message, 'success')
                                    }
                                    getCompanyList()
                                    getUsersList()
                                    setOpen({ ...open, isOpen: false })

                                } catch (err) {
                                    console.error(err)
                                    setOpen({ ...open, isOpen: true })
                                }
                                setSubmitting(false);
                            }} >
                            {({ setFieldValue, values, setFieldTouched, isSubmitting, resetForm, setFieldError, setTouched, touched }) => (
                                <Form>
                                    <Grid container spacing={{ xs: 2, }} className='scroll-bar'>
                                        <Grid item xs={12} sm={6}  >
                                            <Box>
                                                <Field
                                                    component={TextField}
                                                    name="first_name"
                                                    label={`${t('users.first_name')} *`}
                                                    inputProps={{ maxLength: 35 }}
                                                    fullWidth />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Box>
                                                <Field
                                                    component={TextField}
                                                    name="last_name"
                                                    label={t('users.last_name')}
                                                    inputProps={{ maxLength: 35 }}
                                                    fullWidth />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}  >
                                            <Box>
                                                <Field
                                                    component={TextField}
                                                    name="username"
                                                    label={`${t('users.user_name')} *`}
                                                    inputProps={{ maxLength: 35 }}
                                                    disabled={values.id}
                                                    onChange={async (e) => {
                                                        const regex = /^[a-zA-Z0-9_]+$/
                                                        setUserValues(prev => ({ ...prev, 'username': e.target.value }))
                                                        setFieldValue('username', e.target.value)
                                                        const isValid = e.target.value.trim().length > 3 && regex.test(e.target.value) && await checkUniqueUserName(e.target.value)
                                                        setIsCheckUniqueUsername(isValid)
                                                    }}
                                                    fullWidth />
                                            </Box>
                                        </Grid>
                                        {
                                            userTab === 0 &&
                                            <Grid item xs={12} sm={6}  >
                                                <Box sx={{ width: '100%' }}>
                                                    {isAddNewCompany
                                                        ?
                                                        <Box>
                                                            <MuiTextField
                                                                // name="company_name"
                                                                label={t('users.add_new_company')}
                                                                sx={{ width: '100%' }}
                                                                autoFocus
                                                                onBlur={(e) => {
                                                                    if ((e.target.value).trim().length !== 0) {
                                                                        setFieldValue('company_name', e.target.value)
                                                                    }
                                                                }}

                                                                placeholder={t('users.add_new_company')}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <Tooltip placement='top' title={t('common.save')}>
                                                                                <IconButton
                                                                                    size='small'
                                                                                    onClick={async () => {
                                                                                        const newCompanyData = {
                                                                                            'id': 0,
                                                                                            'company_name': values.company_name,
                                                                                            'is_add_new_company': true
                                                                                        }
                                                                                        if ((values.company_name).trim().length !== 0) {
                                                                                            await setCompanyLst(prev => {
                                                                                                prev.push(newCompanyData)
                                                                                                return [...prev]
                                                                                            })
                                                                                            setFieldValue('company', newCompanyData)
                                                                                            // setFieldValue('company_name', newCompanyData.company_name)
                                                                                            setFieldValue('company_id', 0)
                                                                                            // setOpenUserMenu(true)
                                                                                            setIsAddNewCompany(false)
                                                                                        }
                                                                                    }}>
                                                                                    <SaveAsOutlinedIcon color='primary' />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip placement='top' title={t('common.cancel')}>
                                                                                <IconButton size='small' onClick={async () => {
                                                                                    setFieldValue('company', null)
                                                                                    // setOpenUserMenu(true)
                                                                                    setIsAddNewCompany(false)

                                                                                }}>
                                                                                    <CloseOutlined color='primary' />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Box>
                                                        :
                                                        <Autocomplete
                                                            open={openUserMenu}
                                                            onOpen={() => { setOpenUserMenu(true) }}
                                                            onClose={() => { setOpenUserMenu(false) }}
                                                            autoHighlight={true}
                                                            // defaultValue={userValues.company}
                                                            value={values.company}
                                                            onChange={(event, newValue, reason) => {
                                                                setFieldValue('company_id', newValue?.id)
                                                                setFieldValue('company', newValue)
                                                                setFieldValue('company_name', newValue?.company_name)
                                                                setIsAddNewCompany(false)
                                                                // setSelectedCompany(newValue)
                                                            }}
                                                            sx={{ flexGrow: 1 }}
                                                            getOptionLabel={option => option.company_name}
                                                            // loading={loading}
                                                            options={companyLst}
                                                            renderOption={(props, option, { selected, index }) => {
                                                                const { key, ...optionProps } = props;
                                                                return (
                                                                    <ListItem key={key} {...optionProps}
                                                                        sx={{
                                                                            py: .4
                                                                        }}
                                                                    >
                                                                        <Box component={'label'} sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                                                                            <Box>
                                                                                <Typography>{option.company_name}</Typography>
                                                                            </Box>
                                                                            {option?.is_add_new_company &&
                                                                                <Box>
                                                                                    <Tooltip placement='top' title={t('common.remove')}>
                                                                                        <IconButton
                                                                                            onClick={async (e) => {
                                                                                                await e.preventDefault()
                                                                                                await setCompanyLst(prev => {
                                                                                                    const indexValue = prev.lastIndexOf(option)
                                                                                                    prev.splice(indexValue, 1)
                                                                                                    return [...prev]
                                                                                                })
                                                                                                setFieldValue('company', null)
                                                                                                await setOpenUserMenu(true)

                                                                                            }}
                                                                                        >
                                                                                            <DoDisturbOnOutlinedIcon color='primary' />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Box>
                                                                            }

                                                                        </Box>
                                                                    </ListItem>
                                                                );
                                                            }}
                                                            renderInput={(params) => (
                                                                <Box sx={{ position: 'relative' }}>
                                                                    <Field
                                                                        component={TextField}
                                                                        name="company_name"
                                                                        {...params}
                                                                        label={t('users.company_name')}

                                                                    />
                                                                </Box>
                                                            )}
                                                            PaperComponent={({ props, children, ...others }) => {
                                                                return (
                                                                    <Paper {...others}>
                                                                        {children}
                                                                        {!(companyLst[companyLst.length - 1]?.is_add_new_company) &&
                                                                            <Box sx={{ p: 1 }} >
                                                                                <Button
                                                                                    size="small"
                                                                                    variant="text"
                                                                                    onMouseDown={async (event) => {
                                                                                        event.preventDefault()
                                                                                        await setIsAddNewCompany(true)


                                                                                        setOpenUserMenu(false)
                                                                                    }}
                                                                                    startIcon={<AddIcon />}
                                                                                    color="primary"
                                                                                    sx={{ flexGrow: 1, fontSize: 12 }}
                                                                                >
                                                                                    {t('users.add_new_company')}
                                                                                </Button>
                                                                            </Box>
                                                                        }
                                                                    </Paper>
                                                                )
                                                            }}
                                                        />
                                                    }
                                                </Box>
                                            </Grid>
                                        }
                                        <Grid item xs={12} sm={6} >
                                            <Box>
                                                <Field
                                                    component={TextField}
                                                    name="email"
                                                    label={`${t('users.email')} *`}
                                                    inputProps={{ maxLength: 235 }}
                                                    disabled={open.mode === 1}
                                                    onChange={async (e) => {
                                                        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                                                        setUserValues(prev => ({ ...prev, 'email': e.target.value }))
                                                        setFieldValue('email', e.target.value)
                                                        const isValid = e.target.value.trim().length > 8 && regex.test(e.target.value) && await checkUniqueEmail(e.target.value)
                                                        setIsCheckUniqueEmail(isValid)
                                                    }}

                                                    fullWidth />
                                            </Box>
                                        </Grid>
                                        {open.mode !== 1 &&
                                            <Grid item xs={12} sm={6} >
                                                <Box>
                                                    <Field
                                                        component={TextField}
                                                        name="password"
                                                        label={`${t('users.password')} *`}
                                                        type={showPassword ? 'text' : 'password'}
                                                        inputProps={{ maxLength: 235 }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => { setShowPassword(prev => !prev) }}
                                                                        edge="end"
                                                                        sx={{ mr: .5 }}
                                                                    >
                                                                        {showPassword ? (
                                                                            <VisibilityOutlinedIcon sx={{ fontSize: 20 }} />
                                                                        ) : (
                                                                            <VisibilityOffOutlinedIcon sx={{ fontSize: 20 }} />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        fullWidth />
                                                </Box>
                                            </Grid>
                                        }
                                        <Grid item xs={12} sm={6} >
                                            <Box>
                                                <PhoneInput
                                                    className="react-tel-input profile-pho-inp"
                                                    country={'se'}
                                                    name="mobile_number"
                                                    countryCodeEditable={false}
                                                    specialLabel=''
                                                    value={(values.mobile_code ? values.mobile_code : "+1") + (values.mobile_number ? values.mobile_number : "")}
                                                    onBlur={() => { setFieldTouched('mobile_number', true) }}
                                                    onChange={(phoneNumber, phoneNumberObj) => {
                                                        setFieldValue('mobile_number', phoneNumber.slice(phoneNumberObj.dialCode.length))
                                                        setFieldValue('mobile_code', phoneNumberObj.dialCode)
                                                        setFieldValue('country_code', phoneNumberObj.countryCode)
                                                    }}
                                                    fullWidth
                                                />
                                                <ErrorMessage component="span" name="mobile_number" />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            {isLoaded &&
                                                <StandaloneSearchBox
                                                    onLoad={ref => inputRef.current = ref}
                                                    onPlacesChanged={(e) => {
                                                        try {
                                                            const [place] = inputRef.current.getPlaces();
                                                            if (place) {
                                                                const placeObj = getAddressJSON(place)
                                                                setFieldValue("street", `${placeObj.street_no ? placeObj.street_no : ""}${placeObj.street ? placeObj.street : ""}`)
                                                                setFieldValue("city", placeObj.city ? placeObj.city : "")
                                                                setFieldValue("state", placeObj.state ? placeObj.state : "")
                                                                setFieldValue("country", placeObj.country ? placeObj.country : "")
                                                                setFieldValue("zipcode", placeObj.zipcode ? placeObj.zipcode : "")
                                                                setTouched({
                                                                    ...touched,
                                                                    street_address: false,
                                                                    "street": false,
                                                                    "city": false,
                                                                    "state": false,
                                                                    "country": false,
                                                                    "zipcode": false,
                                                                })
                                                            }
                                                        } catch (error) {
                                                            console.error('error: ', error.message);
                                                        }
                                                    }} >
                                                    <Field
                                                        component={TextField}
                                                        name="street"
                                                        label={t('users.street')}
                                                        placeholder=''
                                                        inputProps={{ maxLength: 35 }}
                                                        fullWidth
                                                    />
                                                </StandaloneSearchBox>
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box>
                                                <Field
                                                    component={TextField}
                                                    name="city"
                                                    label={t('users.city')}
                                                    inputProps={{ maxLength: 35 }}
                                                    fullWidth />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Box>
                                                <Field
                                                    component={TextField}
                                                    name="state"
                                                    label={t('users.state')}
                                                    inputProps={{ maxLength: 35 }}
                                                    fullWidth />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Box>
                                                <Field
                                                    component={TextField}
                                                    name="country"
                                                    label={t('users.country')}
                                                    inputProps={{ maxLength: 35 }}
                                                    fullWidth />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Box>
                                                <Field
                                                    component={TextField}
                                                    name="zipcode"
                                                    label={t('users.zip_code')}
                                                    inputProps={{ maxLength: 35 }}
                                                    fullWidth />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ mt: 4, display: "flex", justifyContent: "end" }}>
                                        <Box>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                sx={{ mr: 2 }}
                                                onClick={() => { setOpen({ ...open, isOpen: false }); setUserValues(initialUserValues) }}>
                                                {t('common.cancel')}
                                            </Button>
                                        </Box>
                                        <Box>
                                            <Button type="submit" disabled={isSubmitting} variant="contained"> {t('common.save')}</Button>
                                        </Box>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </ModalBox >
        </Box >
    )
}

export default UsersList

const demoFile = [
    {
        "company_name": "outsource 01",
    },
    {
        "company_name": "outsource 02",
    },
    {
        "company_name": "outsource 03",
    },
    {
        "company_name": "outsource 04",
    },
    {
        "company_name": "outsource 05",
    },
    {
        "company_name": "outsource 06",
    },
]