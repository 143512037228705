import { Box, Button, Grid, Paper, Typography, Container } from '@mui/material'
import React from 'react'
import PageNotFound from '../../assets/images/page-not-found.png'
import { useNavigate } from 'react-router-dom'

const Notfound = () => {

    const navigate = useNavigate();

    return (
        <Box sx={{ minHeight: "100vh", display: "flex", alignItems: "center", backgroundColor: 'primary.light' }}>
            <Container maxWidth="xl">
                <Paper sx={{ py: 2, px: { xs: 2, sm: 4, md: 8 }, borderRadius: 4 }}>
                    <Grid container spacing={2} sx={{ alignItems: "center" }}>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <Typography sx={{ fontSize: { xs: "2.8rem", sm: "6rem" }, fontWeight: 600, background: 'linear-gradient( to bottom, #e78c00 45%,  #fff 85% )', WebkitBackgroundClip: 'text', backgroundClip: 'text', WebkitTextFillColor: "transparent" }}>Error 404</Typography>
                            </Box>
                            <Box sx={{ mt: -1 }}>
                                <Typography sx={{ fontSize: { xs: "1.2rem", sm: "2rem" } }}>Somethings gone missing...</Typography>
                            </Box>
                            <Box sx={{ mt: 1.4 }}>
                                <Typography sx={{ fontSize: "15px", color: "#838383", fontWeight: 400 }}>Oops! The page you're looking for doesn't seem to exist. It might have been moved, deleted, or perhaps you typed the URL incorrectly. Please check the URL and try again, or return to our homepage to find what you need.</Typography>
                            </Box>
                            <Box sx={{ mt: 4 }}>
                                <Button sx={{ fontSize: "18px", padding: "4px 30px", }} onClick={() => { navigate('/') }}>Go home</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box src={PageNotFound} component={'img'} sx={{ heigt: '100%', width: '100%', objectFit: 'contain' }} alt="" />
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    )
}

export default Notfound