import { Box } from '@mui/system'
import React from 'react'
import { Typography } from '@mui/material';

const Empty = ({ icon, text, children, sx }) => {
    return (
        <Box sx={{ border: "2px solid #eee", borderRadius: "6px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "20px 15px", textAlign: "center", my: 2, ...sx }}>
            <Box>
                {icon}
            </Box>
            <Box>
                <Typography sx={{ fontSize: 16, color: "grey" }}>{text}</Typography>
            </Box>
            <Box>
                {children}
            </Box>
        </Box>
    )
}

export default Empty