import { forwardRef } from 'react';
import { Box, Modal, Dialog, Typography, Fade, IconButton, Stack, Avatar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

const Transition = forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />
})

const ModalBox = ({ icon, children, open, onClose, sx, title, subtitle, closeBtn = false }) => {
    const style = {
        width: '27%',
        outline: "none",
        backgroundColor: "#fff",
        borderRadius: 2.5,
        border: 1,
        borderColor: "divider",
        boxShadow: "-1px 2px 20px 3px #00000025",
        overflow: 'auto',
        "& *": { boxSizing: "border-box" },
        '@media (max-width: 1400px)': {
            width: '40%',
        },
        '@media (max-width: 1200px)': {
            width: '60%',
        },
        '@media (max-width: 600px)': {
            width: '100%',
            margin: "10px"
        },
        ...sx
    };

    return (
        <Dialog
            open={Boolean(open)}
            TransitionComponent={Transition}
            onClose={onClose}
            maxWidth={"none"}
            PaperProps={{
                sx: { ...style },
                className: 'scroll-bar'
            }}
        >
            <Box>
                {
                    title && <Stack direction={'row'} sx={{ px: 1.5, borderRadius: 2.5, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, py: { xs: .5, sm: 1 }, minHeight: 50, justifyContent: 'end', alignItems: subtitle ? 'start' : 'center', position: 'sticky', top: 0, left: 0, zIndex: 2, borderBottom: '1px solid #d3d3d3', backgroundColor: '#fff' }}>
                        <Stack direction={'row'} sx={{ gap: 1, alignItems: 'center', flexGrow: 1, flexDirection: 'row' }} >
                            {
                                icon &&
                                <Box>
                                    <Avatar sx={{ backgroundColor: 'background.lightSecondary', width: 45, height: 45, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {icon}
                                    </Avatar>
                                </Box>
                            }
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography sx={{ fontSize: { xs: 14, sm: 16 }, fontWeight: 600 }} color="#000">{title}</Typography>
                                {subtitle && <Typography color="text.secondary" sx={{ fontSize: 13 }}>{subtitle}</Typography>}
                            </Box>
                        </Stack>
                        {
                            closeBtn && <Box>
                                <IconButton onClick={onClose} size='small' >
                                    <CloseIcon sx={{ fontSize: 22 }} />
                                </IconButton>
                            </Box>
                        }
                    </Stack>
                }
                <Box className='modal-child' sx={{ "& > div": { p: 1.5 }, bgcolor: '#fff', position: 'relative', ".MuiInputBase-root": { minHeight: 38 }, '& .modal-footer': {} }}>
                    {children}
                </Box>
            </Box>
        </Dialog >
    )
}

export default ModalBox

