import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
// import { jobActions } from "../redux/reducers/job-slice"
import useAxios from "./use-axios"
import { alertMsg } from "../utils/basicUtils"
import { uiActions } from "../redux/reducers/ui-slice"

const useSignout = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const axios = useAxios()


    return async () => {
        try {
            const response = await axios.post("accounts/signout/", {}, { withCredentials: true })
            alertMsg(response.data.message, 'success')
            navigate('/accounts/signin')
            dispatch(uiActions.setDarkMode(false))
            localStorage.clear()
        }
        catch (err) {
            console.error("🚀 ~ file: use-signout.js:19 ~ return ~ err:", err)
        }
    }
}

export default useSignout