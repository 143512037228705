import { Box, List, Stack, Typography, ListItemButton, ListItemIcon, Menu, ListItemText, Collapse, Button, Avatar, IconButton, Drawer, useMediaQuery, Switch, MenuItem, ListItemAvatar, styled } from '@mui/material'
import { useState } from 'react'
import { NavLink, useNavigate, useLocation, } from 'react-router-dom'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import dashboardIcon from '../assets/images/dashboard.png'
import Users from '../assets/images/group.png'
import calendarIcon from '../assets/images/calendar.png'
import SalarySlip from '../assets/images/salary.png'
import Project from '../assets/images/project.png'
import Contracts from '../assets/images/contracts.png'
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Logo from '../assets/images/logo.png'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useSignout from '../hooks/use-signout';
import useAuth from '../hooks/use-auth';
import { useTranslation } from 'react-i18next';
import { uiActions } from '../redux/reducers/ui-slice';
import { useDispatch, useSelector } from 'react-redux';
import { alertMsg } from '../utils/basicUtils';
import useAxiosPrivate from '../hooks/use-axios-private';
import FlagSwitch from '../components/FlagSwitch';




const linkStyles = {
    "& svg": { fill: "#fff" },
    bgcolor: "#ffd492",
    color: "#fff",
    "&:hover": { bgcolor: "#ffd492" },
    "&:after": {
        content: "''",
        position: "absolute",
        height: "100%",
        width: "6px",
        bottom: "0px",
        right: "0px",
        borderRadius: "6px 0px 0px 6px",
        backgroundColor: "#e78c00",
    },
    "& .MuiListItemText-primary": {
        color: "#000",
        fontWeight: 500
    },
    "& .MuiListItemIcon-root p": {
        color: "#000",
        fontWeight: 500
    }
}
const childLinkStyles = {
    position: 'relative',
    pl: 2,
    '&:before': {
        position: 'absolute',
        content: '""',
        height: '100%',
        left: 22,
        top: 0,
        borderRight: '1px dashed #ffffff80'
    },
    "& .MuiListItemText-primary": {
        color: "#ffffff80"
    },
    ".MuiListItemButton-root": {
        position: "relative",
        "&:before": {
            content: "''",
            position: "absolute",
            width: "15px",
            left: "10px",
            borderTop: '1px dashed #ffffff80'
        }
    },
    "& .active > .MuiListItemButton-root .MuiListItemText-primary": {
        color: "#fff"
    },
    "& .active > .MuiListItemButton-root:before": {
        borderColor: "#fff"
    }
}

const ListMenu = ({ menuItem, open, setOpen }) => {
    const [isViewed, setIsViewed] = useState(false);
    const location = useLocation()
    const navigate = useNavigate()
    const { user } = useAuth()



    const handleClick = (e) => {
        setIsViewed(!isViewed)
    };
    return (
        <>
            {menuItem.sub_menu.length ?
                <>
                    <Box className={location.pathname.match(menuItem.menu_link) ? 'active parent-nav' : "parent-nav"}>
                        <Box className='menu-head'>
                            <ListItemButton onClick={(e) => { handleClick(e) }} sx={{ px: 2 }}>
                                <ListItemIcon sx={{ minWidth: 0, mr: 1.5, justifyContent: 'center', flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                                    <Box component={"img"} src={menuItem.menu_icon} sx={{ height: 20 }} alt="" />
                                </ListItemIcon>
                                <ListItemText primary={menuItem.menu_alias_name} />
                                {isViewed ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        </Box>
                        <Collapse in={isViewed} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding sx={{ ...childLinkStyles }}>
                                {menuItem.sub_menu.map((item, index) => (
                                    <NavLink key={index} to={`${menuItem.menu_link}${item.menu_link}`}>
                                        <ListItemButton disableRipple sx={{ pl: 4 }} onClick={() => { navigate(`${menuItem.menu_link}${item.menu_link}`); setOpen(!open) }}>
                                            <ListItemText primary={item.menu_alias_name} />
                                        </ListItemButton>
                                    </NavLink>
                                ))}
                            </List>
                        </Collapse>
                    </Box>

                </>
                :
                menuItem.role_id.includes(user.role_id)
                &&
                <>
                    <NavLink to={menuItem.menu_link} className={"parent-nav"}>
                        <ListItemButton onClick={(e) => { setOpen(!open) }} sx={{ minHeight: 48, px: 2.5, }}>
                            <ListItemIcon sx={{ minWidth: 0, mx: 'auto', mr: 1.5, justifyContent: 'center', flexDirection: "column", alignItems: "center" }}>
                                <Box component={"img"} src={menuItem.menu_icon} sx={{ height: 20 }} alt="" />
                            </ListItemIcon>
                            <ListItemText primary={menuItem.menu_alias_name} />
                        </ListItemButton>
                    </NavLink>
                </>
            }
        </>
    )
}

const Navbar = ({ setOpenSideBar, isOpenSidebar }) => {
    const { user } = useAuth()
    const axios = useAxiosPrivate()
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false)
    const navigate = useNavigate();
    const isTabView = useMediaQuery("(max-width: 900px)")
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const signout = useSignout()
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.ui)

    const menuItems = [
        // {
        // 	"id": 1,
        // 	"menu_name": "Dashboard",
        // 	"menu_alias_name": "Dashboard",
        // 	"menu_link": "/",
        // 	"menu_icon": dashboardIcon,
        // 	"is_active": true,
        // 	"sub_menu": [],
        // 	'role_id': [1, 2, 3, 4]
        // },
        {
            "id": 2,
            "menu_name": t('menu.contract'),
            "menu_alias_name": t('menu.contract'),
            "menu_link": "/",
            "menu_icon": Contracts,
            "is_active": true,
            "sub_menu": [],
            'role_id': [1, 2, 3, 4]
        },
        {
            "id": 3,
            "menu_name": t('menu.timesheet'),
            "menu_alias_name": t('menu.timesheet'),
            "menu_link": "/timesheets",
            "menu_icon": calendarIcon,
            "is_active": true,
            "sub_menu": [],
            'role_id': [1, 2, 3]
        },
        {
            "id": 4,
            "menu_name": t('menu.salary_slip'),
            "menu_alias_name": t('menu.salary_slip'),
            "menu_link": "/salary-slip",
            "menu_icon": SalarySlip,
            "is_active": true,
            "sub_menu": [],
            'role_id': [1, 2, 4]
        },
        {
            "id": 5,
            "menu_name": t('menu.users'),
            "menu_alias_name": t('menu.users'),
            "menu_link": "/users",
            "menu_icon": Users,
            "is_active": true,
            "sub_menu": [],
            'role_id': [1, 2]
        },
        {
            "id": 6,
            "menu_name": user.role_id === 3 ? t('menu.project') : t('menu.customer'),
            "menu_alias_name": user.role_id === 3 ? t('menu.project') : t('menu.customer'),
            "menu_link": "/project",
            "menu_icon": Project,
            "is_active": true,
            "sub_menu": [],
            'role_id': [1, 2, 3]
        },

    ]

    const handleLanguage = async (e) => {
        const check = e.target.checked
        try {
            const response = await axios.put("users/change_website_language/", {
                "language_code": check ? 'sv' : 'en'
            })
            dispatch(uiActions.setLanguage(check ? 'se' : 'en'));
            i18n.changeLanguage(check ? 'se' : 'en')
            alertMsg(response.data.message, 'success')

        } catch (err) {
            console.error(err)
        }
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center", width: 1, justifyContent: { xs: 'space-between', md: 'end', lg: 'space-between' } }}>
            <Box sx={{ display: { xs: 'inline-flex', md: 'none', lg: 'inline-flex' } }}>
                <IconButton onClick={() => { !isTabView ? setOpenSideBar() : setOpen(true) }}><MenuOpenIcon sx={{ transform: !isOpenSidebar && !isTabView ? 'scaleX(-1)' : 'scaleX(1)' }} /></IconButton>
            </Box>
            <Box >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: "end", textAlign: "end" }}>
                    <Box>
                        <FlagSwitch checked={language !== 'en'} onChange={handleLanguage} />
                    </Box>
                    <Box>
                        <Avatar sx={{ cursor: "pointer" }} alt={user && user.first_name} src={user && user.headshot && user.headshot.url} onClick={(event) => { setIsOpenMenu(event.currentTarget); }} />
                    </Box>
                    <Menu
                        anchorEl={isOpenMenu}
                        open={Boolean(isOpenMenu)}
                        onClose={() => setIsOpenMenu(false)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        sx={{ mt: 1, '& .MuiMenuItem-root': { borderRadius: 0, '&:hover': { backgroundColor: 'transparent' } }, '& .MuiMenuItem-root:last-of-type': { borderRadius: '0px 0px 5px 5px' }, '& .MuiMenuItem-root:first-of-type': { borderRadius: '5px 5px 0px 0px' } }}

                    >
                        <MenuItem onClick={() => { setIsOpenMenu(false) }} sx={{ gap: 1, borderBottom: 1, borderColor: 'background.light', minWidth: 180, '&:hover': { backgroundColor: 'transparent' } }} >
                            <ListItemAvatar sx={{ minWidth: 'auto' }}>
                                <Avatar sx={{ cursor: "pointer", width: 35, height: 35 }} alt={user && user.first_name} src={user && user.headshot && user.headshot.url} onClick={(event) => { setIsOpenMenu(event.currentTarget); }} />
                            </ListItemAvatar>
                            <ListItemText>
                                <Typography sx={{ lineHeight: "normal", fontWeight: 600, color: 'text.primary', textAlign: 'left' }}>{`${user.first_name} ${user.last_name}`}</Typography>
                                <Typography sx={{ fontSize: 12, color: 'text.primary', textAlign: 'left' }}>{user.role_id === 1 || user.role_id === 2 ? t('role.admin') : user.role_id === 3 ? t('role.customer') : t('role.employee')}</Typography>
                            </ListItemText>
                        </MenuItem>
                        <MenuItem sx={{ gap: 1, borderBottom: 1, borderColor: 'background.light', px: 1 }} onClick={() => {
                            navigate("/profile")
                            setIsOpenMenu(false)

                        }}>
                            <ListItemIcon sx={{ minWidth: 35, height: 35, backgroundColor: 'background.light', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 1 }}>
                                <AccountCircleIcon color='primary' sx={{ fontSize: 20 }} />
                            </ListItemIcon>
                            <ListItemText sx={{ fontSize: 14 }}>{t('menu.profile')}</ListItemText>
                        </MenuItem>
                        <MenuItem sx={{ gap: 1, borderBottom: 1, borderColor: 'background.light', px: 1 }}
                            onClick={() => {
                                signout()
                                setIsOpenMenu(false)
                            }}
                        >
                            <ListItemIcon sx={{ minWidth: 35, height: 35, backgroundColor: 'background.light', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 1 }}>
                                <LogoutIcon color='primary' sx={{ rotate: "180deg", fontSize: 18, }} />
                            </ListItemIcon>
                            <ListItemText sx={{ fontSize: 14 }}>{t('menu.sign_out')}</ListItemText>
                        </MenuItem>
                    </Menu>
                </Box>
            </Box>
            <Drawer
                anchor="left"
                open={open}
                onClose={() => { setOpen(false) }}
                sx={{ width: 260, display: { xs: 'block', md: 'none' }, '& .MuiPaper-elevation16.MuiDrawer-paper': { borderRadius: '0px' } }}
            >
                <Box sx={{ width: 260, backgroundColor: 'background.sidebar', overflow: "hidden", height: '100vh', "& *": { boxSizing: "border-box" } }} >
                    <Stack sx={{ gap: 2, justifyContent: "space-between", transition: '.5s', height: '100%', }} >
                        <Box>
                            <Box sx={{ py: 2, px: 2, width: 1, boxSizing: "border-box" }}>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: "center", mb: 2 }}>
                                    <img src={Logo} style={{ maxWidth: 100 }} alt="logo" />
                                </Box>
                            </Box>
                            <Box sx={{ maxHeight: "calc(100vh - 255px)", overflowY: "auto", overflowX: "hidden", height: '100%', pt: .2 }} className="scroll-bar">
                                <List component="nav" sx={{ "& *": { transition: ".4s all ease" }, width: '100%', '& *': { color: "#000" }, "& .parent-nav.active > .MuiListItemButton-root , & .parent-nav.active > .menu-head > .MuiListItemButton-root ": { ...linkStyles }, "& a": { textDecoration: "none" } }}>
                                    {menuItems.map((item, index) => <ListMenu open={open} setOpen={setOpen} menuItem={item} key={index} />)}
                                </List>
                            </Box>
                        </Box>
                        <Box sx={{ px: 1, pt: 2, pb: 1, boxSizing: "border-box" }}>
                            <Box sx={{ display: "flex", flexDirection: open ? "row" : "column-reverse", gap: 1, color: '#000' }}>
                                <Button variant="outlined" startIcon={<LogoutIcon sx={{ rotate: "180deg", ml: open ? 0 : 1 }} />} sx={{ color: 'inherit', border: 1, flexGrow: 1, textAlign: "start", fontSize: 12, px: 1, py: 1, minWidth: 0 }}
                                    onClick={() => {
                                        signout()
                                    }}>{t('menu.sign_out')}</Button>
                            </Box>
                            <Box sx={{ mt: 1, textAlign: 'right' }}>
                                <Typography>V {process.env.REACT_APP_VERSION || "DEV"}</Typography>
                            </Box>
                        </Box>
                    </Stack >
                </Box >
            </Drawer >
        </Box >
    )
}

export default Navbar