import { configureStore } from "@reduxjs/toolkit";
import uiSlice from "./reducers/ui-slice";
import orderSlice from "./reducers/order-slice";
import authSlice from "./reducers/auth-slice";

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        ui: uiSlice.reducer,
        order: orderSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;