import { Box, Grid, Typography, Stack, Avatar, IconButton } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person';
import { useEffect, useState } from 'react'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import 'react-phone-input-2/lib/material.css'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import User from '../../assets/images/user.jpg'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { alertMsg } from '../../utils/basicUtils';
import useAuth from '../../hooks/use-auth';
import useAxiosPrivate from '../../hooks/use-axios-private';
import { useDispatch } from 'react-redux';
import { authActions } from '../../redux/reducers/auth-slice';
import { useTranslation } from 'react-i18next';


const ProfileView = ({ userDetail, handleTab }) => {

    const { user } = useAuth()
    const axios = useAxiosPrivate()
    const dispatch = useDispatch();
    const [profileImage, setProfileImage] = useState('')
    const { t } = useTranslation()

    // const handleImageChange = async (e) => {
    //     try {
    //         if (e.target.files[0].size < 2000000) {
    //             const formdata = new FormData()
    //             formdata.append('user_id', user.id)
    //             formdata.append('image', e.target.files[0])
    //             const resp = await axios.post('users/upload_headshot/', formdata,
    //                 {
    //                     headers: {
    //                         "Content-Type": "multipart/form-data",
    //                     },
    //                 }
    //             )
    //             const { headshot } = resp.data.data.user
    //             dispatch(authActions.updateHeadshot(headshot))
    //             // console.log(resp)
    //             alertMsg(resp.data.message, "success")
    //             e.target.value = null;
    //         }
    //         else {
    //             alertMsg("File size should be less than 2MB", 'warning')
    //         }
    //     }
    //     catch (e) {
    //         console.error("?? ~ file: PhotoSettings.jsx:102 ~ handleImageChange ~ e:", e)
    //     }
    // }

    return (
        <Box>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5} lg={4} xl={3}>
                        <Stack sx={{ gap: 2 }}>
                            <Box sx={{ border: '1px solid', borderColor: 'background.light', borderRadius: 1 }}>
                                <Stack sx={{ p: 1.5, alignItems: 'center', gap: 1.5, borderBottom: 1, borderColor: 'inherit' }} direction={'row'}>
                                    <Avatar sx={{ backgroundColor: 'background.light' }}>
                                        <CameraAltOutlinedIcon sx={{ fontSize: 20, color: 'primary.main' }} />
                                    </Avatar>
                                    <Box>
                                        <Typography variant='body1'>{t('menu.profile')}</Typography>
                                    </Box>
                                </Stack>
                                <Box sx={{ minHeight: 218, minWidth: 218, alignBoth: 'center' }}>
                                    <Box sx={{ display: 'inline-block', borderRadius: '50%', position: 'relative', '&:hover .overlay': { opacity: 1 } }}>
                                        <Avatar sx={{ height: 200, width: 200 }} src={profileImage} />
                                        {/* <Box component={'label'} sx={{ position: "absolute", inset: 0, backgroundColor: '#0000003d', borderRadius: '50%', alignBoth: 'center', opacity: 0, transition: '.3s', cursor: 'pointer', userSelect: 'none' }} className='overlay'>
                                            <Box sx={{ color: '#fff', textAlign: 'center' }}>
                                                <CameraAltOutlinedIcon />
                                                <Typography variant="body1">Upload</Typography>
                                            </Box>
                                            <input hidden accept="image/*" type="file" />
                                            <input hidden accept="image/*" type="file" onChange={handleImageChange} />
                                        </Box> */}
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ border: '1px solid', borderColor: 'background.light', borderRadius: 1 }}>
                                <Stack sx={{ p: 1.5, alignItems: 'center', gap: 1.5, borderBottom: 1, borderColor: 'inherit' }} direction={'row'}>
                                    <Avatar sx={{ backgroundColor: 'background.light' }}>
                                        <PersonIcon sx={{ fontSize: 20, color: 'primary.main' }} />
                                    </Avatar>
                                    <Box>
                                        <Typography variant='body1'>{userDetail.first_name}</Typography>
                                        <Typography variant='body2' sx={{ color: 'text.secondary', fontWeight: 600 }}>{userDetail.role_id === 1 || userDetail.role_id === 2 ? t('role.admin') : userDetail.role_id === 3 ? t('role.customer') : t('role.employee')}</Typography>
                                    </Box>
                                </Stack>
                                <Stack sx={{ p: 1.5, alignItems: 'center', gap: 1.5, borderBottom: 1, borderColor: 'inherit' }} direction={'row'}>
                                    <Stack sx={{ alignBoth: 'center', height: 40, width: 40, minWidth: 40, borderRadius: 1, color: 'primary.main', backgroundColor: 'background.light' }}>
                                        <MailOutlinedIcon />
                                    </Stack>
                                    <Stack >
                                        <Typography variant='body1'>{t('profile.email')}</Typography>
                                        <Typography variant='body2' sx={{ color: 'text.secondary', fontWeight: 600 }}>{userDetail.email}</Typography>
                                    </Stack>
                                </Stack>
                                <Stack sx={{ p: 1.5, alignItems: 'center', gap: 1.5, borderBottom: 1, borderColor: 'inherit' }} direction={'row'}>
                                    <Stack sx={{ alignBoth: 'center', height: 40, width: 40, minWidth: 40, borderRadius: 1, color: 'primary.main', backgroundColor: 'background.light' }}>
                                        <LocalPhoneOutlinedIcon />
                                    </Stack>
                                    <Stack>
                                        <Typography variant='body1'>{t('profile.phone')}</Typography>
                                        <Typography variant='body2' sx={{ color: 'text.secondary', fontWeight: 600 }}>{userDetail.mobile_code ? `+${userDetail.mobile_code}` : ""} {userDetail.mobile_number ? userDetail.mobile_number : "-"}</Typography>
                                    </Stack>
                                </Stack>
                                <Stack sx={{ p: 1.5, alignItems: 'center', gap: 1.5 }} direction={'row'}>
                                    <Stack sx={{ alignBoth: 'center', height: 40, width: 40, minWidth: 40, borderRadius: 1, color: 'primary.main', backgroundColor: 'background.light' }}>
                                        <LocationOnOutlinedIcon />
                                    </Stack>
                                    <Stack>
                                        <Typography variant='body1'>{t('profile.address')}</Typography>
                                        {userDetail.street !== '' || userDetail.city !== '' || userDetail.city !== '' ?

                                            <Typography variant='body2' sx={{ color: 'text.secondary', fontWeight: 600 }}>{userDetail.street !== '' && `${userDetail.street} ,`} {userDetail.city !== '' && `${userDetail.city} ,`} {userDetail.zipcode}</Typography>

                                            : <Typography variant='body2' sx={{ color: 'text.secondary', fontWeight: 600 }}>-</Typography>
                                        }
                                    </Stack>
                                </Stack>
                            </Box>
                        </Stack>

                    </Grid>
                    <Grid item xs={12} md={7} lg={8} xl={9}>
                        <Box sx={{ border: '1px solid', borderColor: 'background.light', borderRadius: 1 }}>
                            <Stack sx={{ p: 1.5, alignItems: 'center', gap: 1.5, borderBottom: 1, borderColor: 'inherit' }} direction={'row'}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Typography variant='body1' sx={{ fontWeight: 600 }}>{t('profile.about_me')}</Typography>
                                </Box>
                                <Box>
                                    <IconButton aria-label="" color='info' onClick={(e) => { handleTab(e, 1) }}>
                                        <EditOutlinedIcon />
                                    </IconButton>
                                </Box>
                            </Stack>
                            <Box>
                                {/* <Box sx={{ p: 1.5, borderBottom: 1, borderColor: 'background.light' }}>
                                    <Stack direction='row' sx={{ gap: .5, alignItems: 'center' }}>
                                        <RadioButtonCheckedIcon sx={{ fontSize: 12, color: 'warning.main' }} />
                                        <Typography variant="body1" sx={{ fontWeight: 600 }}>Bio</Typography>
                                    </Stack>
                                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio dolorem in quisquam quas dolores ipsum itaque tempore hic molestiae nesciunt facilis sed vero eum illo adipisci dolor, quaerat quasi culpa! Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio dolorem in quisquam quas dolores ipsum itaque tempore hic molestiae nesciunt facilis sed vero eum illo adipisci dolor, quaerat quasi culpa!</Typography>
                                </Box> */}
                                <Box sx={{ p: 1.5, borderBottom: 1, borderColor: 'background.light' }}>
                                    <Stack direction='row' sx={{ gap: .5, alignItems: 'center' }}>
                                        <RadioButtonCheckedIcon sx={{ fontSize: 12, color: 'warning.main' }} />
                                        <Typography variant="body1" sx={{ fontWeight: 600 }}>{t('profile.personal_details')}</Typography>
                                    </Stack>
                                    <Box sx={{ mt: 1, color: 'text.secondary' }}>
                                        <Stack direction='row' sx={{ gap: 1.5, mb: 1 }}>
                                            <Box sx={{ flexBasis: 100 }}>
                                                <Typography variant="body1">{t('profile.full_name')} </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="body1">:</Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="body1">{userDetail.first_name} {userDetail.last_name}</Typography>
                                            </Box>
                                        </Stack>
                                        <Stack direction='row' sx={{ gap: 1.5, mb: 1 }}>
                                            <Box sx={{ flexBasis: 100 }}>
                                                <Typography variant="body1">{t('profile.address')}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="body1">:</Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="body1">{userDetail.street !== '' && `${userDetail.street} ,`} {userDetail.city !== '' && `${userDetail.city} ,`} {userDetail.zipcode ? userDetail.zipcode : '-'}</Typography>
                                            </Box>
                                        </Stack>
                                        <Stack direction='row' sx={{ gap: 1.5, mb: 1 }}>
                                            <Box sx={{ flexBasis: 100 }}>
                                                <Typography variant="body1">{t('profile.zip_code')}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="body1">:</Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="body1">{userDetail.zipcode ? userDetail.zipcode : '-'}</Typography>
                                            </Box>
                                        </Stack>
                                        <Stack direction='row' sx={{ gap: 1.5, mb: 1 }}>
                                            <Box sx={{ flexBasis: 100 }}>
                                                <Typography variant="body1">{t('profile.phone')}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="body1">:</Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="body1">{userDetail.mobile_code ? `+${userDetail.mobile_code}` : ""} {userDetail.mobile_number ? userDetail.mobile_number : "-"}</Typography>
                                            </Box>
                                        </Stack>
                                        <Stack direction='row' sx={{ gap: 1.5, mb: 1 }}>
                                            <Box sx={{ flexBasis: 100 }}>
                                                <Typography variant="body1">{t('profile.email')}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="body1">:</Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="body1">{userDetail.email}</Typography>
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    )
}

export default ProfileView