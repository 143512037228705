import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material';
import { themeConfiguration } from './themeConfig';
import { useSelector } from 'react-redux';
import MainLayout from './layouts/MainLayout';
// import useAuth from './hooks/use-auth';
import SignIn from './pages/accounts/Signin';
import Dashboard from './pages/dashboard/Dashboard';
import ForbiddenError from './pages/error/ForbiddenError';
import Notfound from './pages/error/Notfound';
import Contracts from './pages/contracts/Contracts';
import Timesheets from './pages/timesheets/Timesheets';
import SalarySlip from './pages/salary-slip/SalarySlip';
import Profile from './pages/profile/Profile';
import User from './pages/user/User';
import ResetPassword from './pages/accounts/ResetPassword';
import Project from './pages/projects/Project';
import { svSE, enUS } from '@mui/material/locale';


const App = () => {
	// const { protector } = useAuth();
	const current_theme = useSelector(state => state.ui.isDarkMode)
	const language = useSelector(state => state.ui.language)
	const theme = createTheme(themeConfiguration(current_theme), language === "en" ? enUS : svSE);

	const router = createBrowserRouter(
		createRoutesFromElements(
			<>
				<Route path="accounts/*">
					<Route path='signin' element={<SignIn />} />
					<Route path='reset_password' element={<ResetPassword />} />
				</Route>
				<Route element={<MainLayout />}>
					{/* <Route index={true} path="/" element={<Dashboard />} /> */}
					<Route index={true} path="/" element={<Contracts />} />
					<Route path="/timesheets" element={<Timesheets />} />
					<Route path="/salary-slip" element={<SalarySlip />} />
					<Route path="/profile" element={<Profile />} />
					<Route path="/users" element={<User />} />
					<Route path="/project" element={<Project />} />
				</Route>
				<Route path="/forbidden" element={<ForbiddenError />} />
				<Route path="*" element={<Notfound />} />
			</>
		)
	)

	return (
		<ThemeProvider theme={theme}>
			<div className={current_theme ? 'dark-mode' : 'light-mode'}>
				<RouterProvider router={router} />
			</div>
		</ThemeProvider>
	);
}

export default App