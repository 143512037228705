import React from 'react'
import { Box, keyframes, Backdrop } from "@mui/material"

const Preloader = ({ preload = true }) => {

    const loadAnimation = keyframes`
        0% { transform: rotate(90deg);}
        50% {  transform: rotate(0deg); }
        100% { transform: rotate(0deg); }
    `
    const styles = {
        "loader": {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',

        },
        "loader_square": {
            height: '20px',
            width: '20px',
            animation: `${loadAnimation} 1.5s linear infinite`,
            backgroundColor: '#e78c00',
            animationTimingFunction: 'cubic-bezier(0.075, 0.82, 0.165, 1)',
        }
    }

    return (
        <>
            {
                preload ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#fef3e278' }}
                        open={true}
                    >
                        <Box sx={{ ...styles.loader }}>
                            {["-0.2s", "1s", ".8s"].map(sec => (
                                <Box key={sec} sx={{ ...styles.loader_square, animationDelay: sec }}></Box>
                            ))}
                        </Box>
                    </Backdrop>
                    :
                    <Box sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box sx={{ ...styles.loader }}>
                            {["-0.2s", "1s", ".8s"].map(sec => (
                                <Box key={sec} sx={{ ...styles.loader_square, animationDelay: sec }}></Box>
                            ))}
                        </Box>
                    </Box>
            }
        </>

    )
}

export default Preloader
