import UsFlag from '../assets/images/us-flag.png'
import SwedenFlag from '../assets/images/sweden-flag.png'
import { Switch, styled } from '@mui/material';

const FlagSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 60,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(33px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#E9E9EA',
                opacity: 1,
                border: 0,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
        backgroundImage: `url(${UsFlag})`,
        backgroundSize: 'contain'
    },
    '& .Mui-checked .MuiSwitch-thumb': {
        backgroundImage: `url(${SwedenFlag})`,
        backgroundSize: 'contain'
    },
    '& .MuiSwitch-track': {
        borderRadius: "26px",
        backgroundColor: '#E9E9EA',
        position: 'relative',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
    '& .MuiSwitch-track::before , & .MuiSwitch-track::after': {
        position: 'absolute',
        fontWeight: 700,
        transform: 'translateY(-50%)',
        top: "50%",
        fontSize: 12,
        width: 1,
    },
    '& .MuiSwitch-track::after': {
        content: '"SE"',
        left: 8,
    },
    '& .MuiSwitch-track::before': {
        content: '"EN"',
        right: 24,
    },
}));


export default FlagSwitch