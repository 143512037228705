import { createSlice } from "@reduxjs/toolkit";
const isDarkMode = localStorage.getItem('isDarkMode') === 'true' ? true : false;
const language = localStorage.getItem('lang') === 'en' ? 'en' : 'se';
const initialState = {
    isDarkMode: isDarkMode,
    isLoading: false,
    language: language
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setDarkMode(state, action) {
            localStorage.setItem('isDarkMode', action.payload)
            return { ...state, isDarkMode: action.payload };
        },
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        setLanguage(state, action) {
            localStorage.setItem('lang', action.payload)
            state.language = action.payload;
        }
    }
});

export const uiActions = uiSlice.actions;

export default uiSlice;