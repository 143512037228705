
import { Box, Paper, useMediaQuery } from '@mui/material'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Sidebar from './Sidebar'
import { useEffect, useState } from 'react';
import Navbar from './Navbar';
import useRefreshToken from '../hooks/use-refresh-token';
import Preloader from '../components/Preloader';
import uiSlice from '../redux/reducers/ui-slice';
import { useSelector } from 'react-redux'

const MainLayout = () => {
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false)
    const [open, setOpen] = useState(true)
    const isLapView = useMediaQuery("(max-width: 1199px)")
    const refresh = useRefreshToken();
    const lastLogIn = localStorage.getItem('lastLogIn')
    const isLoggedIn = localStorage.getItem('isLoggedIn')
    const { language } = useSelector(state => state.ui)
    
    const getAccessToken = async () => {
        try {
            if (lastLogIn && !isNaN(lastLogIn) && (new Date() < new Date(Number(lastLogIn))) && isLoggedIn) {
                await refresh()
                setIsLoaded(true)
            }
            else {
                navigate("/accounts/signin")
            }
        }
        catch (err) {
            console.error("🚀 ~ file: MainLayout.jsx:26 ~ useEffect ~ err:", err)
        }
    }

    useEffect(() => {
        getAccessToken()
    }, [])



    return (
        <>
            {!isLoaded && <Preloader />}
            {
                isLoaded &&
                <Box component={Paper} sx={{ backgroundColor: 'background.light', display: "flex", height: "100vh", position: "relative", transition: '.3s', boxShadow: 'none' }}>
                    <Sidebar open={Boolean(open && !isLapView)} />
                    {/* <IconButton size='small' onClick={() => { setOpen(!open) }} sx={{ display: { xs: "none", lg: "flex" }, bgcolor: "primary.main", '&:hover': { bgcolor: "primary.main" }, position: "absolute", top: 20, left: open ? 260 : 60, boxShadow: "2px 2px 5px 0px #00000008", zIndex: 100, transition: { xs: 0, md: open ? '.4s' : '.5s' }, }}>
                    <NavigateNextIcon sx={{ color: '#fff' }} />
                </IconButton> */}
                    <Box sx={{ width: { xs: '100%', md: "calc(100% - 70px)", lg: `calc(100% - ${open ? "280px" : "80px"})` }, ml: 'auto', position: 'relative', transition: { xs: 0, md: '.5s' }, overflow: "auto" }} className="scroll-bar">
                        <Box sx={{ px: { xs: "12px", sm: 2 }, py: 1.3, position: 'sticky', top: 0, zIndex: 1000, left: 0, backgroundColor: 'background.primary' }}>
                            <Navbar setOpenSideBar={() => { setOpen(!open) }} isOpenSidebar={open} />
                        </Box>
                        <Box sx={{ pb: 2, pt: { xs: 2.5, }, maxWidth: 1536, mx: "auto", px: { xs: "12px", sm: "24px" }, minHeight: 'calc(100% - 97px)' }}>
                            <Outlet />
                        </Box>
                    </Box>
                </Box>
            }
        </>
    )
}

export default MainLayout