import { Box, Button, Typography, AvatarGroup, TextField as MuiTextField, Stack, Tooltip, Autocomplete, TableContainer, InputAdornment, Table, TableHead, TableCell, TableRow, TableBody, TableFooter, TablePagination, Grid, Paper, Avatar, MenuItem, ListItemIcon, ListItemText, Menu, IconButton, Pagination, useMediaQuery, List, ListItem, ListItemAvatar, CircularProgress, Checkbox, ToggleButtonGroup, ToggleButton, Chip, Alert } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModalBox from '../../components/ModalBox';
import Popup from '../../components/Popup';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import { alertMsg } from '../../utils/basicUtils';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import useAuth from '../../hooks/use-auth';
import useAxiosPrivate from '../../hooks/use-axios-private';
import Empty from '../../components/Empty';
import DropZone from '../../components/DropZone';
import Preloader from '../../components/Preloader';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Close } from '@mui/icons-material';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import PhoneInput from 'react-phone-input-2';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import CustomAvatarGroup from '../../components/CustomAvatarGroup';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import CommentsDisabledOutlinedIcon from '@mui/icons-material/CommentsDisabledOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { useTranslation } from 'react-i18next';

const UserAction = ({ getTimesheetInfo, file, setViewComments, setTimesheetDetails, setOpen, setIsOpenInfoDetails, setShowDeleteModal }) => {
    const [isActionsOpen, setIsActionsOpen] = useState(false);
    const { t } = useTranslation()
    const handleActions = (event) => {
        setIsActionsOpen(event.currentTarget);
    };
    const { user } = useAuth()

    return (
        <Box>
            <IconButton size='small' onClick={handleActions} >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={isActionsOpen}
                open={Boolean(isActionsOpen)}
                onClose={() => setIsActionsOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {user.role_id === 1 &&
                    <MenuItem
                        onClick={async () => {
                            await getTimesheetInfo(file.id, 'edit');
                            setOpen(true)
                            setIsActionsOpen(false)
                        }}
                    >
                        <ListItemIcon sx={{ minWidth: 30 }}>
                            <EditOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('common.edit')}</ListItemText>
                    </MenuItem>
                }
                {user.role_id === 1 &&
                    <MenuItem
                        onClick={async () => {
                            await getTimesheetInfo(file.id, 'view');
                            setIsOpenInfoDetails(true);
                            setIsActionsOpen(false);
                            setTimesheetDetails(file)
                        }}
                    >

                        <ListItemIcon sx={{ minWidth: 30 }}>
                            <InfoOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('common.info')}</ListItemText>
                    </MenuItem>
                }
                {user.role_id === 1 &&
                    <MenuItem
                        onClick={async () => {
                            setViewComments(true);
                            setIsActionsOpen(false);
                            setTimesheetDetails(file)
                        }}
                        disabled={file.status !== 3}
                    >
                        <ListItemIcon sx={{ minWidth: 30 }}>
                            {
                                file.status !== 3 ?
                                    <CommentsDisabledOutlinedIcon sx={{ fontSize: "22px" }} />
                                    :
                                    <CommentOutlinedIcon sx={{ fontSize: "22px" }} />
                            }
                        </ListItemIcon>
                        <ListItemText>{t('common.comments')}</ListItemText>
                    </MenuItem>
                }
                <MenuItem onClick={() => { window.open(`${file.documents[0]?.view_file_url}`, "_blank"); setIsActionsOpen(false); }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                        <VisibilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('common.view')}</ListItemText>
                </MenuItem>
                <MenuItem download component={'a'} href={file?.documents[0]?.download_file_url} color='success' onClick={() => { setIsActionsOpen(false) }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                        <CloudDownloadOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('common.download')}</ListItemText>
                </MenuItem>
                {user.role_id === 1 &&
                    <MenuItem onClick={() => { setShowDeleteModal(true); setIsActionsOpen(false) }}>
                        <ListItemIcon sx={{ minWidth: 30 }}>
                            <DeleteOutlineOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('common.delete')}</ListItemText>
                    </MenuItem>
                }
            </Menu >
        </Box >
    )
}

const initialData = {
    "timesheet_id": "",
    "title": "",
    "document_name": "", // Optional - ""
    "period_type": 2, // Accepts 2 - Week
    "period": '',
    "project_id": '',
    "user_id": '',
    "role_id": 3,
    'users': '',
    'user_name': '',
    'project_name': ''
}


const Timesheets = () => {
    const [timeSheetList, setTimeSheetList] = useState([])
    const [timesheetDetails, setTimesheetDetails] = useState(initialData)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [open, setOpen] = useState(false)
    const [isGridView, setIsGridView] = useState(false)
    const isTabView = useMediaQuery("(max-width: 900px)")
    const [isOpenInfoDetails, setIsOpenInfoDetails] = useState(false)
    const [ShowDeleteModal, setShowDeleteModal] = useState(false)
    const [filterByUser, setFilterByUser] = useState(null)
    const [userLst, setUserLst] = useState([])
    const [projectLst, setProjectLst] = useState([])
    const [projectUserLst, setProjectUserLst] = useState([])
    const [selectedUserProject, setSelectedUserProject] = useState(null)
    const [uploadedItems, setUploadedItems] = useState([])
    const [uploading, setUploading] = useState({ load: false, upload: false, delete: false })
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [pageInfo, setPageInfo] = useState({})
    const timesheetIdRef = useRef()
    const [selectedUserLst, setSelectedUserLst] = useState([])
    const [openUserMenu, setOpenUserMenu] = useState(false)
    const [viewComments, setViewComments] = useState(false)
    const [approveForm, setApproveForm] = useState({ open: false, id: '', status: '' })
    const [viewUsersLst, setViewUsersLst] = useState({ open: false, users: [] })
    const { t } = useTranslation()

    const axios = useAxiosPrivate()
    const { user } = useAuth()


    const getTimesheetLst = async () => {
        try {
            setUploading((prev) => ({ ...prev, load: true }))
            const response = await axios.get('timesheet/list/', {
                params: {
                    limit: rowsPerPage,
                    page: page + 1,
                    search: searchText,
                    role_id: 3,
                    user_id: filterByUser?.id,
                }
            })
            setTimeSheetList(response.data.data.timesheets)
            setPageInfo(response.data.data.page_info)
            setUploading((prev) => ({ ...prev, load: false }))
        } catch (err) {
            setUploading((prev) => ({ ...prev, load: false }))
            console.error(err)
        }
    }

    const getTimesheetInfo = async (id, mode) => {
        try {
            const response = await axios.get(`timesheet/${id}`)
            setTimesheetDetails({ ...response.data.data.timesheet, 'document_name': response.data.data.timesheet.documents[0]?.document_name, 'project_id': response.data.data.timesheet.project.id })
            if (mode === 'edit') {
                timesheetIdRef.current = response.data.data.timesheet.timesheet_id
                setUploadedItems(response.data.data.timesheet.documents)
                getTimesheetProjectUserLst(response.data.data.timesheet.project.id)
                setSelectedUserLst(response.data.data.timesheet.users)
            }
        } catch (err) {
            console.error(err)
        }
    }

    const getTimesheetUserLst = async () => {
        try {
            const response = await axios.get(`users/list/`, {
                params: {
                    role_id: 3,
                    limit: 0,
                }
            })
            setUserLst(response.data.data.users)
        } catch (err) {
            console.error(err)
        }
    }


    const getTimesheetProjectLst = async () => {
        try {
            const response = await axios.get(`timesheet/project_list/`)
            setProjectLst(response.data.data.projects)
        } catch (err) {
            console.error(err)
        }
    }

    const getTimesheetProjectUserLst = async (id) => {
        try {
            const response = await axios.get(`/timesheet/project_users/${id}`)
            setProjectUserLst(response.data.data.users)
            setSelectedUserProject(null)
        } catch (err) {
            console.error(err)
        }
    }

    const fileUpload = async (e, id) => {
        const { files } = e.target
        if (files[0]) {
            const formData = new FormData()
            formData.append("file", files[0])
            formData.append("timesheet_id", id)
            getTimesheetId(formData)
            e.target.value = null;
        }
    }

    const getTimesheetId = async (data) => {
        try {
            setIsSubmitting(true)
            if (!data) {
                const response = await axios.post('timesheet/upload_doc/')
                let { timesheet_id } = response.data.data.timesheet_file
                timesheetIdRef.current = timesheet_id
                setTimesheetDetails(prev => ({ ...prev, "timesheet_id": timesheet_id }))
            }
            else {
                setUploading((prev) => ({ ...prev, upload: true }))
                const response = await axios.post('timesheet/upload_doc/', data, {
                    headers: { "Content-Type": "multipart/form-data", },
                })
                let { documents } = response.data.data.timesheet_file
                setUploadedItems(documents)
                alertMsg(response.data.message, 'success')
                setUploading((prev) => ({ ...prev, upload: false }))
            }
            setIsSubmitting(false)
        }
        catch (e) {
            setIsSubmitting(false)
            setUploading((prev) => ({ ...prev, upload: false }))
            console.error("🚀  file: fileUpload.jsx:89 ", e)
            return false
        }
    }

    const handleDeleteTimesheet = async (id) => {
        try {
            const response = await axios.delete(`timesheet/${id}`)
            alertMsg(response.data.message, 'success')
            getTimesheetLst()
        } catch (err) {
            console.error(err)
        }
    }

    const handleDeleteDocument = async (id, fileid) => {
        try {
            setUploading((prev) => ({ ...prev, delete: true }))
            const response = await axios.post(`timesheet/delete_doc/${id}`, {
                file_id: fileid
            })

            setUploadedItems([])
            setUploading((prev) => ({ ...prev, delete: false }))
            alertMsg(response.data.message, 'success')
        } catch (err) {
            setUploading((prev) => ({ ...prev, delete: false }))
            console.error(err)
        }
    }

    const handleToggleOption = (index) => {
        setSelectedUserLst(prev => {
            const selected = prev.find(selectedUser => selectedUser.id === projectUserLst[index].id)
            if (selected) {
                const indexValue = prev.indexOf(selected)
                prev.splice(indexValue, 1)
            }
            else {
                prev.push(projectUserLst[index])
            }
            return [...prev]
        })
    };

    const handleUpdateStatus = async (timesheetId, formData) => {
        try {
            const response = await axios.put(`timesheet/change_status/${timesheetId}`, formData)
            alertMsg(response.data.message, "success")
            getTimesheetLst()
            setApproveForm((prev) => ({ ...prev, open: false }))
        }
        catch (err) {
            console.error(err)
            setApproveForm((prev) => ({ ...prev, open: true }))
        }
    }

    useEffect(() => {
        if (isTabView) {
            setIsGridView(true);
        }
        else {
            setIsGridView(false);
        }
    }, [isTabView])

    useEffect(() => {
        if (user.role_id === 1 || user.role_id === 2) {
            getTimesheetProjectLst()
            getTimesheetUserLst()
        }

    }, [])

    useEffect(() => {
        getTimesheetLst()
    }, [page, rowsPerPage, searchText, filterByUser])



    return (
        <Box>
            <Paper sx={{ backgroundColor: "background.card", mt: 1.5, borderRadius: 1.5, minHeight: 'calc(90vh - 120px)' }}>
                <Stack direction={'row'} sx={{ gap: 1, alignItems: 'center', flexGrow: 1, flexDirection: 'row', p: 1, borderBottom: '1px solid', borderColor: 'primary.light' }} >
                    <Box>
                        <Avatar sx={{ backgroundColor: 'primary.main', width: 45, height: 45, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <InsertDriveFileOutlinedIcon sx={{ fontSize: 22 }} />
                        </Avatar>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography sx={{ fontSize: { xs: 14, sm: 16 }, fontWeight: 600 }} color="#000">{t("menu.timesheet")}</Typography>
                        {
                            user.role_id === 1 ?
                                <Typography color="text.secondary" sx={{ fontSize: 13 }}>{t("timesheet.heading_text_one")}</Typography>
                                :
                                <Typography color="text.secondary" sx={{ fontSize: 13 }}>{t("timesheet.heading_text_two")}</Typography>
                        }
                    </Box>
                </Stack>
                <Box sx={{ p: 1, pt: 1.5 }}>
                    <Box sx={{ display: { xs: 'block', sm: "flex" }, gap: 1, flexWrap: "wrap", alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box>
                            <MuiTextField
                                fullWidth
                                sx={{ width: "100%", backgroundColor: '#f3f3f3', "& fieldset": { border: "0 !important" } }}
                                placeholder={t('common.search')}
                                size={'small'}
                                value={searchText}
                                onChange={(e) => { setSearchText(e.target.value) }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon sx={{ ml: 1.2, fontSize: 20 }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Stack sx={{ mt: { xs: 1, md: 0 }, flexDirection: 'row', gap: 1, alignItems: 'center', flexWrap: 'wrap' }}>
                            {
                                (user.role_id === 1 || user.role_id === 2) &&
                                <>

                                    <Box sx={{ minWidth: 250 }}>
                                        <Autocomplete
                                            isOptionEqualToValue={(option, value) => (option?.id === value?.id)}
                                            onChange={(event, newValue) => {
                                                setFilterByUser(newValue)
                                            }}
                                            getOptionKey={option => option.id}
                                            value={filterByUser}
                                            disableClearable
                                            sx={{ flexGrow: 1 }}
                                            getOptionLabel={option => `${option?.first_name} ${option?.last_name}`}
                                            options={userLst}
                                            renderInput={(params) => (
                                                <MuiTextField
                                                    name="filter_by_user"
                                                    {...params}
                                                    label={t("common.filter_customer")}
                                                />
                                            )}
                                        />
                                    </Box>
                                    {
                                        filterByUser &&
                                        <Box>
                                            <Tooltip title={t("common.reset_filter")} placement='top'>
                                                <Button sx={{ height: '31px', width: '31px', p: '5px ', minWidth: 'auto' }} variant='outlined' onClick={() => {
                                                    setFilterByUser(null)
                                                }}><FilterAltOffOutlinedIcon /></Button>
                                            </Tooltip>
                                        </Box>
                                    }
                                </>
                            }
                            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                <Button sx={{ height: '31px', width: '31px', p: '5px ', minWidth: 'auto' }} variant='outlined' onClick={() => setIsGridView(prev => !prev)}>
                                    {isGridView ?
                                        <ListOutlinedIcon sx={{ fontSize: 20 }} /> : <GridViewOutlinedIcon sx={{ fontSize: 20 }} />
                                    }
                                </Button>
                            </Box>
                            {
                                (user.role_id === 1) &&
                                <Box>
                                    <Button sx={{ minWidth: 'auto' }} variant='outlined' onClick={() => {
                                        setTimesheetDetails(initialData)
                                        setUploadedItems([])
                                        setSelectedUserProject(null)
                                        getTimesheetId()
                                        setOpen(true)
                                    }} startIcon={<CloudUploadOutlinedIcon sx={{ fontSize: 20 }} />}>
                                        {t('common.upload')}
                                    </Button>
                                </Box>
                            }
                        </Stack>
                    </Box>
                    {
                        uploading.load ?
                            <Box sx={{ mt: 1, minHeight: '64vh', display: 'flex', alignItems: 'center', backgroundColor: '#ffff', border: '2px solid', borderRadius: 1, borderColor: "background.light" }}>
                                <Preloader preload={false} />
                            </Box>
                            :
                            <Box sx={{ mt: 1 }}>
                                {
                                    timeSheetList.length > 0 ?
                                        <>
                                            {
                                                !isGridView ?
                                                    <TableContainer>
                                                        <Table stickyHeader size='small' >
                                                            <TableHead>
                                                                <TableRow sx={{ '& th': { whiteSpace: 'nowrap', backgroundColor: 'background.card', p: 1.5, border: 0, borderBottom: '1px solid #ebf1fc' } }}>
                                                                    <TableCell sx={{ minWidth: { xs: 180, xl: 'auto' } }}>
                                                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'start' }}>
                                                                            <Box sx={{ height: 20 }}>
                                                                                <InsertDriveFileOutlinedIcon sx={{ fontSize: 20 }} />
                                                                            </Box>
                                                                            <Box>
                                                                                <Typography sx={{ maxLines: 1, fontWeight: 700 }} variant="body1">{t('timesheet.timesheet_title')}</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell><Typography sx={{ fontWeight: 700 }}>{t('timesheet.project_name')}</Typography></TableCell>
                                                                    <TableCell align={'center'}><Typography sx={{ fontWeight: 700 }}>{(user.role_id === 1 || user.role_id === 2) ? t('timesheet.timesheet_customers') : t('timesheet.timesheet_users')}</Typography></TableCell>
                                                                    <TableCell sx={{ width: 120 }} align={'center'}><Typography sx={{ fontWeight: 700 }}>{t("timesheet.timesheet_weeks")}</Typography></TableCell>
                                                                    <TableCell sx={{ width: 150 }} align={'center'}><Typography sx={{ fontWeight: 700 }}>{t("timesheet.timesheet_status")}</Typography></TableCell>
                                                                    <TableCell align={'center'} sx={{ width: user.role_id === 1 ? 300 : 220 }}><Typography sx={{ fontWeight: 700 }}>{t("common.action")}</Typography></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {
                                                                    timeSheetList.map((file, index) => (
                                                                        <TableRow key={index} sx={{ '& td': { px: 0, borderBottom: '1px solid #ebf1fc', py: "2px" }, '& td>div': { px: "12px", py: '2px', backgroundColor: 'background.card', minHeight: 50, }, }}>
                                                                            <TableCell sx={{ minWidth: { xs: 180, xl: 'auto' } }}>
                                                                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'start' }}>
                                                                                    <Box sx={{ height: 20 }}>
                                                                                        <InsertDriveFileOutlinedIcon sx={{ fontSize: 20 }} />
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <Tooltip title={`${file.title}`} arrow placement='top'>
                                                                                            <Typography sx={{ maxLines: 1 }} variant="body1">{file.title}</Typography>
                                                                                        </Tooltip>
                                                                                    </Box>
                                                                                </Box>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                    <Typography sx={{ maxLines: 1 }} variant="body1">{file.project?.project_name}</Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                            <TableCell align={'center'} sx={{ minWidth: 180 }}>
                                                                                <CustomAvatarGroup users={file.users} open={() => { setViewUsersLst((prev) => ({ users: file.users, open: true })) }} limit={4} />
                                                                            </TableCell>
                                                                            <TableCell align={'center'} sx={{ width: 120 }}>
                                                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                                    <Typography variant="body1">{file.period}</Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                            <TableCell sx={{ width: 150 }} align={'center'}>
                                                                                <Stack direction='row' sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                                                                    {
                                                                                        (user.role_id === 1 || user.role_id === 2) ?
                                                                                            <Chip
                                                                                                size='small'
                                                                                                icon={file.status == 1 ? <PauseCircleOutlineIcon sx={{ color: '#fff !important' }} /> : file.status == 2 ? <VerifiedOutlinedIcon sx={{ color: '#fff !important' }} /> : <DoDisturbAltOutlinedIcon sx={{ color: '#fff !important' }} />}
                                                                                                sx={{ backgroundColor: file.status == 1 ? "#ff9800" : file.status == 2 ? "#4caf50" : '#d32f2f', color: '#fff' }}
                                                                                                label={file.status == 1 ? t('timesheet.pending') : file.status == 2 ? t('timesheet.approved') : t('timesheet.denied')}></Chip>
                                                                                            :
                                                                                            <Stack direction='row' sx={{ justifyContent: 'center' }}>
                                                                                                <ToggleButtonGroup
                                                                                                    size="small"
                                                                                                    value={file.status}
                                                                                                    exclusive
                                                                                                    onChange={(e, value) => {
                                                                                                        if (value === null) {
                                                                                                            return
                                                                                                        }
                                                                                                        if (value === 1) {
                                                                                                            handleUpdateStatus(file.id, { 'status': value })
                                                                                                        }
                                                                                                        else {
                                                                                                            setApproveForm({ open: true, status: value, id: file.id })
                                                                                                        }
                                                                                                    }}
                                                                                                    color={file.status == 1 ? "warning" : file.status == 2 ? "success" : 'error'}
                                                                                                    sx={{ '&>button': { backgroundColor: '#fff', m: '0px !important', '&:hover': { m: '0px !important' } } }}
                                                                                                >
                                                                                                    <Tooltip title={t('timesheet.pending')} placement='top'>
                                                                                                        <ToggleButton disabled={file.status == 2} value={1} size='small' sx={{ color: 'warning.light', '&.Mui-selected, &:hover, &.Mui-selected:hover': { backgroundColor: '#ff9800', color: '#fff' } }}><PauseCircleOutlineIcon sx={{ fontSize: 20 }} /></ToggleButton>
                                                                                                    </Tooltip>
                                                                                                    <Tooltip title={file.status == 2 ? t('timesheet.approved') : t('timesheet.approve')} placement='top'>
                                                                                                        <ToggleButton value={2} size='small' sx={{ color: 'success.light', '&.Mui-selected, &:hover, &.Mui-selected:hover': { backgroundColor: '#4caf50', color: '#fff' } }}><VerifiedOutlinedIcon sx={{ fontSize: 20 }} /></ToggleButton>
                                                                                                    </Tooltip>
                                                                                                    <Tooltip title={t('timesheet.deny')} placement='top'>
                                                                                                        <ToggleButton disabled={file.status == 2} value={3} size='small' sx={{ color: 'error.light', '&.Mui-selected, &:hover, &.Mui-selected:hover': { backgroundColor: '#d32f2f', color: '#fff' } }}><DoDisturbAltOutlinedIcon sx={{ fontSize: 20 }} /></ToggleButton>
                                                                                                    </Tooltip>
                                                                                                </ToggleButtonGroup>

                                                                                            </Stack>
                                                                                    }

                                                                                </Stack>
                                                                            </TableCell>
                                                                            <TableCell align={'center'}>
                                                                                <Stack direction={'row'} sx={{ justifyContent: 'center', alignItems: 'center', gap: 2 }}>

                                                                                    {user.role_id === 1 &&
                                                                                        <Box>
                                                                                            <Tooltip title={t("common.edit")} arrow placement='top'>
                                                                                                <IconButton size='small' color='success'
                                                                                                    onClick={
                                                                                                        async () => {
                                                                                                            await getTimesheetInfo(file.id, 'edit');
                                                                                                            setOpen(true)
                                                                                                        }}>
                                                                                                    <EditOutlinedIcon sx={{ fontSize: "22px" }} />
                                                                                                </IconButton>
                                                                                            </Tooltip>

                                                                                        </Box>
                                                                                    }
                                                                                    {user.role_id === 1 &&
                                                                                        <Box>
                                                                                            <Tooltip title={t("common.info")} arrow placement='top'>
                                                                                                <IconButton size='small' color='info'
                                                                                                    onClick={
                                                                                                        async () => {
                                                                                                            await getTimesheetInfo(file.id, 'view');
                                                                                                            setIsOpenInfoDetails(true)
                                                                                                        }}
                                                                                                >
                                                                                                    <InfoOutlinedIcon sx={{ fontSize: "22px" }} />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Box>
                                                                                    }
                                                                                    {user.role_id === 1 &&
                                                                                        <Box>
                                                                                            <Tooltip title={t("common.comments")} arrow placement='top'>
                                                                                                <IconButton disabled={file.status !== 3} size='small' color='success'
                                                                                                    onClick={() => { setViewComments(true); setTimesheetDetails(file) }}>
                                                                                                    {
                                                                                                        file.status !== 3 ?
                                                                                                            <CommentsDisabledOutlinedIcon sx={{ fontSize: "22px" }} />
                                                                                                            :
                                                                                                            <CommentOutlinedIcon sx={{ fontSize: "22px" }} />
                                                                                                    }

                                                                                                </IconButton>
                                                                                            </Tooltip>

                                                                                        </Box>
                                                                                    }
                                                                                    <Box>
                                                                                        <Tooltip title={t("common.view")} arrow placement='top'>
                                                                                            <IconButton size='small' onClick={() => {
                                                                                                window.open(`${file.documents[0]?.view_file_url}`, "_blank");
                                                                                            }} color='warning'>
                                                                                                <VisibilityIcon sx={{ fontSize: "22px" }} />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </Box>
                                                                                    <Box>
                                                                                        <Tooltip title={t("common.download")} arrow placement='top'>
                                                                                            <IconButton size='small'
                                                                                                href={file.documents[0]?.download_file_url}
                                                                                                color='success'>
                                                                                                <CloudDownloadOutlinedIcon sx={{ fontSize: "22px" }} />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </Box>
                                                                                    {user.role_id === 1 &&
                                                                                        <Box>
                                                                                            <Tooltip title={t("common.delete")} arrow placement='top'>
                                                                                                <IconButton size='small' color='error' onClick={() => { setShowDeleteModal(true); setTimesheetDetails(file) }}>
                                                                                                    <DeleteOutlineOutlinedIcon sx={{ fontSize: "22px" }} />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Box>
                                                                                    }
                                                                                </Stack>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                }
                                                            </TableBody>
                                                            <TableFooter sx={{ backgroundColor: 'background.card', p: 2, borderRadius: '5px' }}>
                                                                <TableRow>
                                                                    <TablePagination
                                                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                                                        colSpan={10}
                                                                        sx={{ borderBottom: 0, fontSize: 12, px: 0, "& .MuiInputBase-root": { width: "50px !important", mr: 1.3 }, "& input": { visibility: "hidden" }, "& p": { fontSize: 12 }, "& .MuiTablePagination-toolbar": { minHeight: "0px !important" }, "& .MuiSvgIcon-fontSizeMedium": { fontSize: 15 } }}
                                                                        count={pageInfo.count}
                                                                        rowsPerPage={rowsPerPage}
                                                                        page={page}
                                                                        onPageChange={(e, newPage) => { setPage(newPage) }}
                                                                        onRowsPerPageChange={(e) => { setRowsPerPage(parseInt(e.target.value), 10); setPage(0); }}
                                                                    />
                                                                </TableRow>
                                                            </TableFooter>
                                                        </Table>
                                                    </TableContainer>
                                                    :
                                                    <Stack sx={{ gap: 1, justifyContent: 'space-between', minHeight: '71vh', }}>
                                                        <Box sx={{ maxHeight: { xs: 'calc(100vh - 300px)', md: 'calc(100vh - 180px)' }, overflow: 'auto', }} className='scroll-bar'>
                                                            <Grid container spacing={1.5} sx={{ pb: 1 }}>
                                                                {
                                                                    timeSheetList.map((file, index) => (
                                                                        <Grid item xs={12} md={6} key={index}>
                                                                            <Paper sx={{ p: 1, backgroundColor: 'background.card' }}>
                                                                                <Box>
                                                                                    <Stack direction={'row'} sx={{ gap: 1, alignItems: 'center' }}>
                                                                                        <Avatar sx={{ height: { xs: 65, sm: 80 }, width: { xs: 65, sm: 80 }, borderRadius: 1, backgroundColor: 'background.lightSecondary' }}>
                                                                                            <InsertDriveFileOutlinedIcon sx={{ fontSize: 30, color: 'primary.main' }} />
                                                                                        </Avatar>
                                                                                        <Box sx={{ flexGrow: 1, display: 'flex', gap: 1 }}>
                                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                                <Typography sx={{ fontWeight: 600, maxLines: 1, fontSize: 13, mb: { xs: .5, sm: 1 } }}>{file.title}</Typography>
                                                                                                <Typography variant="body2" sx={{ color: '#a1a1a1', mb: { xs: .5, sm: 1 } }}>{t('timesheet.timesheet_weeks')} : {file.period}</Typography>
                                                                                                <Typography variant="body2" sx={{ color: '#a1a1a1' }}>{t('timesheet.project_name')} : {file.project?.project_name}</Typography>
                                                                                            </Box>
                                                                                            <Stack direction='row' sx={{ alignItems: 'center' }}>
                                                                                                {
                                                                                                    (user.role_id === 1 || user.role_id === 2) ?
                                                                                                        <Chip
                                                                                                            size='small'
                                                                                                            icon={file.status == 1 ? <PauseCircleOutlineIcon sx={{ color: '#fff !important' }} /> : file.status == 2 ? <VerifiedOutlinedIcon sx={{ color: '#fff !important' }} /> : <DoDisturbAltOutlinedIcon sx={{ color: '#fff !important' }} />}
                                                                                                            sx={{ backgroundColor: file.status == 1 ? "#ff9800" : file.status == 2 ? "#4caf50" : '#d32f2f', color: '#fff' }}
                                                                                                            label={file.status == 1 ? t('timesheet.pending') : file.status == 2 ? t('timesheet.approved') : t('timesheet.denied')}></Chip>
                                                                                                        :
                                                                                                        <Box>
                                                                                                            <ToggleButtonGroup
                                                                                                                size="small"
                                                                                                                value={file.status}
                                                                                                                exclusive
                                                                                                                onChange={(e, value) => {
                                                                                                                    if (value === null) {
                                                                                                                        return
                                                                                                                    }
                                                                                                                    if (value === 1) {
                                                                                                                        handleUpdateStatus(file.id, { 'status': value })
                                                                                                                    }
                                                                                                                    else {
                                                                                                                        setApproveForm({ open: true, status: value, id: file.id })
                                                                                                                    }
                                                                                                                }}
                                                                                                                color={file.status == 1 ? "warning" : file.status == 2 ? "success" : 'error'}
                                                                                                                sx={{ '&>button': { backgroundColor: '#fff', m: '0px !important', '&:hover': { m: '0px !important' } } }}
                                                                                                            >
                                                                                                                <Tooltip title={t('timesheet.pending')} placement='top'>
                                                                                                                    <ToggleButton disabled={file.status == 2} value={1} size='small' sx={{ color: 'warning.light', '&.Mui-selected, &:hover, &.Mui-selected:hover': { backgroundColor: '#ff9800', color: '#fff' } }}><PauseCircleOutlineIcon sx={{ fontSize: 20 }} /></ToggleButton>
                                                                                                                </Tooltip>
                                                                                                                <Tooltip title={file.status == 2 ? t('timesheet.approved') : t('timesheet.approve')} placement='top'>
                                                                                                                    <ToggleButton value={2} size='small' sx={{ color: 'success.light', '&.Mui-selected, &:hover, &.Mui-selected:hover': { backgroundColor: '#4caf50', color: '#fff' } }}><VerifiedOutlinedIcon sx={{ fontSize: 20 }} /></ToggleButton>
                                                                                                                </Tooltip>
                                                                                                                <Tooltip title={t('timesheet.deny')} placement='top'>
                                                                                                                    <ToggleButton disabled={file.status == 2} value={3} size='small' sx={{ color: 'error.light', '&.Mui-selected, &:hover, &.Mui-selected:hover': { backgroundColor: '#d32f2f', color: '#fff' } }}><DoDisturbAltOutlinedIcon sx={{ fontSize: 20 }} /></ToggleButton>
                                                                                                                </Tooltip>
                                                                                                            </ToggleButtonGroup>
                                                                                                        </Box>
                                                                                                }

                                                                                            </Stack>
                                                                                        </Box>
                                                                                        <UserAction
                                                                                            setTimesheetDetails={setTimesheetDetails}
                                                                                            setIsOpenInfoDetails={setIsOpenInfoDetails}
                                                                                            setOpen={setOpen}
                                                                                            setShowDeleteModal={setShowDeleteModal}
                                                                                            file={file}
                                                                                            getTimesheetInfo={getTimesheetInfo}
                                                                                            setViewComments={setViewComments}
                                                                                        />
                                                                                    </Stack>
                                                                                </Box>
                                                                            </Paper>
                                                                        </Grid>
                                                                    ))
                                                                }
                                                            </Grid>
                                                        </Box>
                                                        <Stack sx={{ alignItems: 'center', py: 2 }}>
                                                            <Pagination
                                                                variant="outlined"
                                                                color='primary'
                                                                count={pageInfo.num_pages}
                                                                page={page + 1}
                                                                onChange={(e, newPage) => { setPage(newPage - 1) }}
                                                            />
                                                        </Stack>
                                                    </Stack>
                                            }
                                        </>
                                        :
                                        <>
                                            <Empty text={t('timesheet.empty_text')} icon={<InsertDriveFileOutlinedIcon sx={{ fontSize: 30 }} />} sx={{ minHeight: '56vh' }} />
                                        </>
                                }
                            </Box>
                    }
                </Box >
            </Paper >
            <Popup show={ShowDeleteModal} title={`${t('common.delete')} ${t('menu.timesheet')}`} consent={true} variant='error' primaryBtnTxt={t('common.delete')}
                onPrimaryClick={async (e, loader) => {
                    handleDeleteTimesheet(timesheetDetails?.id);
                    setShowDeleteModal(false);
                }}
                onSecondaryClick={() => { setShowDeleteModal(false); }}
            >
                <Box sx={{ textAlign: "center" }}>
                    <Typography color='text.secondary' >{t('timesheet.delete_text')} <span style={{ fontWeight: 600, color: "#000" }}>
                        {timesheetDetails?.title}
                    </span>. {t('common.delete_confirm_text')}</Typography>
                </Box>
            </Popup>
            <ModalBox
                open={open}
                icon={<AccessTimeOutlinedIcon color='primary' />}
                title={t("timesheet.customer_timesheet")}
                closeBtn
                onClose={(e, reason) => {
                    if (reason) return;

                    if (isSubmitting) {
                        alertMsg(t('common.upload_progress_alert'), 'warning')
                        return
                    }

                    if (timesheetDetails.id && uploadedItems.length === 0) {
                        alertMsg(t('common.document_required_alert'), 'warning')
                        return
                    }

                    if (timesheetDetails.id && timesheetDetails.documents !== uploadedItems) {
                        alertMsg(t('common.save_alert'), 'warning')
                        return
                    }
                    else {
                        setOpen(false)
                        setSelectedUserLst([])
                    }
                }}
                sx={{ width: { xs: "100%", sm: "80% !important", md: "70% !important", lg: "45% !important" }, maxWidth: { xs: "95%", sm: "80%", md: "70%", lg: "45%" }, p: { xs: .8, md: 0 } }}>
                <Box>
                    <Box sx={{ mt: 2 }}>
                        <Formik
                            initialValues={timesheetDetails}
                            validationSchema={
                                Yup.object({
                                    title: Yup.string()
                                        .required(t('timesheet.title_required')),
                                    period: Yup.number()
                                        .required(t('timesheet.week_required')),
                                    document_name:
                                        timesheetDetails.id &&
                                        Yup.string()
                                            .required(t('timesheet.document_name_required')),
                                })
                            }
                            onSubmit={async (values, { setSubmitting }) => {
                                if (uploadedItems.length === 0) {
                                    alertMsg(t('common.document_required_alert'), 'warning')
                                    return
                                }
                                const formData = {
                                    'timesheet_id': timesheetIdRef.current,
                                    'title': values.title,
                                    'document_name': values.document_name,
                                    'period_type': 2,
                                    'period': values.period,
                                    'project_id': values.project_id,
                                    'user_ids': selectedUserLst.map(user => (user.id)),
                                    'role_id': 3
                                }

                                try {
                                    if (timesheetDetails.id) {
                                        const response = await axios.put(`timesheet/${values.id}`, formData)
                                        alertMsg(response.data.message, "success")
                                    }
                                    else {
                                        const response = await axios.post('timesheet/create/', formData)
                                        alertMsg(response.data.message, "success")
                                    }
                                    getTimesheetLst()
                                    setUploadedItems({})
                                    setSelectedUserLst([])
                                    setOpen(false)
                                }
                                catch (err) {
                                    console.error(err)
                                    setOpen(true)
                                }
                                setSubmitting(false);
                            }} >
                            {({ setFieldValue, values, setFieldTouched }) => (
                                <Form>
                                    <Grid container spacing={{ xs: 2, }}>
                                        <Grid item xs={12} sm={6} >
                                            <Box>
                                                <Field
                                                    component={TextField}
                                                    name="title"
                                                    label={`${t('timesheet.timesheet_title')} *`}
                                                    fullWidth />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Box>
                                                <Autocomplete
                                                    disabled={Boolean(values.id)}
                                                    isOptionEqualToValue={(option, value) => (option?.id === value?.id)}
                                                    onChange={async (event, newValue) => {
                                                        if (newValue) {
                                                            setFieldValue("project_id", newValue.id)
                                                            setFieldValue("project_name", newValue.name)
                                                            setFieldValue("user_name", '')
                                                            setFieldValue('users', null)
                                                            setProjectUserLst([])
                                                            setSelectedUserLst([])
                                                            getTimesheetProjectUserLst(newValue.id)
                                                        }
                                                        else {
                                                            setFieldValue("project_id", '')
                                                        }
                                                    }}
                                                    defaultValue={timesheetDetails.project}
                                                    sx={{ flexGrow: 1 }}
                                                    getOptionLabel={option => option?.project_name}
                                                    options={projectLst}
                                                    renderInput={(params) => (
                                                        <MuiTextField
                                                            name="project_name"
                                                            {...params}
                                                            label={`${t('timesheet.attach_project')} *`}
                                                        />
                                                    )}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Box>
                                                <Field
                                                    component={TextField}
                                                    name="period"
                                                    label={`${t('timesheet.timesheet_weeks')} *`}
                                                    type='number'
                                                    inputProps={{
                                                        min: 0
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if ((["e", "E", "+", "-"].includes(e.key) || (e.target.value === '' && e.key === '.'))) {
                                                            e.preventDefault()
                                                        }
                                                    }}
                                                    fullWidth />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Box>
                                                <Field
                                                    component={TextField}
                                                    name="document_name"
                                                    label={t('common.document_name')}
                                                    fullWidth />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Box>
                                                <Autocomplete
                                                    multiple
                                                    open={openUserMenu}
                                                    onOpen={() => { setOpenUserMenu(true) }}
                                                    onClose={() => { setOpenUserMenu(false) }}
                                                    autoHighlight={true}
                                                    sx={{ flexGrow: 1, '.MuiChip-deleteIcon': { display: 'none' } }}
                                                    getOptionLabel={option => `${option.first_name} ${option.last_name}`}
                                                    disableCloseOnSelect
                                                    disableClearable
                                                    limitTags={4}
                                                    value={selectedUserLst}
                                                    options={projectUserLst}
                                                    disabled={Boolean(!values.project_id || values.id)}
                                                    isOptionEqualToValue={(option, value) => (option.id == value.id)}
                                                    renderOption={(props, option, { selected, index }) => {
                                                        const { key, ...optionProps } = props;
                                                        return (
                                                            <ListItem key={key} {...optionProps}
                                                                sx={{
                                                                    backgroundColor: selectedUserLst.find(selectedUser => selectedUser.id === projectUserLst[index].id) ? 'background.light' : 'transparent',
                                                                    py: .4
                                                                }}
                                                                onClick={() => handleToggleOption(index)}>
                                                                <Box component={'label'}>
                                                                    <Checkbox
                                                                        sx={{ padding: "4px" }}
                                                                        size='small'
                                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                                        checkedIcon={<CheckBoxIcon />}
                                                                        style={{ marginRight: 6 }}
                                                                        checked={selectedUserLst.find(selectedUser => selectedUser.id === projectUserLst[index].id)}
                                                                    />
                                                                    {`${option.first_name} ${option.last_name}`}
                                                                </Box>
                                                            </ListItem>
                                                        );
                                                    }}

                                                    ListboxComponent={(props) => {
                                                        return (
                                                            <ul {...props}>
                                                                <li>
                                                                    <Stack sx={{ px: 1, mb: 1, flexDirection: 'row', justifyContent: 'space-between', gap: 1 }}>
                                                                        <Button sx={{ fontSize: 12 }} size='small' variant='outlined'
                                                                            onClick={() => {
                                                                                setSelectedUserLst(prev => {
                                                                                    if (prev.length === projectUserLst.length) {
                                                                                        prev = []
                                                                                    }
                                                                                    else {
                                                                                        prev = [...projectUserLst]
                                                                                    }
                                                                                    return [...prev]
                                                                                })
                                                                            }}

                                                                        >{selectedUserLst.length === projectUserLst.length ? t('common.unselect_all') : t('common.select_all')}</Button>
                                                                        <IconButton onClick={() => { setOpenUserMenu(false) }}>
                                                                            <Close />
                                                                        </IconButton>
                                                                    </Stack>
                                                                </li>
                                                                {props.children}
                                                            </ul>
                                                        )
                                                    }
                                                    }
                                                    renderInput={(params) => (
                                                        <Field
                                                            component={TextField}
                                                            name="users"
                                                            {...params}
                                                            label={`${t('timesheet.add_customers')} *`}
                                                        />
                                                    )}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ mt: 2 }}>
                                        <DropZone
                                            getDropped={(e) => {
                                                fileUpload(e, timesheetIdRef.current);
                                                e.target.files = null
                                            }}
                                            formats={[".pdf"]}
                                            disabled={uploading.upload}
                                        >
                                            <Box component={"label"} sx={{ cursor: 'pointer', position: 'relative', border: '1.5px dashed #d3d3d3', p: 1, minHeight: { xs: 200, sm: 250 }, borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', pointerEvents: uploading.upload ? 'none' : 'auto' }} className="scroll-bar">
                                                <input disabled={uploading.upload} hidden accept={".pdf"} type="file" multiple onChange={e => { fileUpload(e, timesheetIdRef.current); e.target.files = null }} />
                                                <Box>
                                                    <Box sx={{ backgroundColor: 'background.success', width: 80, height: 80, borderRadius: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <CollectionsOutlinedIcon sx={{ fontSize: '40px' }} color='success' />
                                                    </Box>
                                                </Box>
                                                <Box sx={{ mt: 1, textAlign: 'center' }}>
                                                    <Typography component={'span'} sx={{ fontSize: { xs: 14, sm: 16, md: '18px' } }}>{t('common.upload_text')} <Typography component={'span'} sx={{ color: 'info.main', fontSize: { xs: 14, sm: 16, md: '18px' } }}>{t('common.browse_text')}</Typography></Typography>
                                                </Box>
                                                <Box sx={{ mt: .5 }}>
                                                    <Typography sx={{ fontSize: '13px', color: 'grey', fontWeight: 400 }}>{t('common.support_text')}: PDF</Typography>
                                                </Box>
                                                {
                                                    uploading.upload && <Box sx={{ position: 'absolute', inset: 0, backgroundColor: '#ffffff87', display: 'flex', alignItems: 'center', justifyContent: 'center', backdropFilter: 'blur(4px)', flexDirection: 'column', gap: 1 }}>
                                                        <CircularProgress />
                                                        <Typography variant="body1" color="initial"> {t('common.uploading')}... </Typography>
                                                    </Box>
                                                }
                                            </Box>
                                        </DropZone>
                                        {
                                            uploadedItems.length > 0 &&
                                            <>
                                                <Box sx={{ mt: 2 }}>
                                                    <Grid container spacing={1} sx={{ pb: 2 }}>
                                                        {
                                                            uploadedItems.map((doc) => (
                                                                <Grid key={doc.id} item xs={12} sm={6} md={4} lg={3} xl={2} >
                                                                    <Box sx={{ overflow: 'hidden', backgroundColor: 'background.light', position: 'relative', borderRadius: '5px' }}>
                                                                        <Box component={'a'} href={`${doc.view_file_url}`} sx={{ textDecoration: 'none', color: 'inherit' }} target='_blank'>
                                                                            <Box sx={{ height: '120px', position: 'relative', cursor: 'pointer', '&:hover .show-icon': { opacity: 1 }, overflow: 'hidden' }}>
                                                                                <Avatar sx={{ height: '100%', width: '100%', borderRadius: 0, objectFit: 'cover', backgroundColor: "primary.main", boxSizing: "border-box", fontSize: '16px' }}>
                                                                                    {
                                                                                        !uploading.delete ?
                                                                                            <InsertDriveFileOutlinedIcon sx={{ fontSize: 30, color: '#fff' }} />
                                                                                            :
                                                                                            <CircularProgress sx={{ color: '#FEF3E2', opacity: .8 }} />
                                                                                    }
                                                                                </Avatar>
                                                                            </Box>
                                                                            <Box sx={{ py: .6, px: .8, backgroundColor: 'background.light' }}>
                                                                                <Typography sx={{ display: '-webkit-box', WebkitLineClamp: 1, overflow: 'hidden', textOverflow: 'ellipsis', WebkitBoxOrient: 'vertical', fontSize: '13px' }}>
                                                                                    {!uploading.delete ? doc.name : `${t('common.deleting')}...`}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                        {
                                                                            timesheetDetails.id &&
                                                                            <IconButton onClick={(e) => {
                                                                                e.preventDefault();
                                                                                handleDeleteDocument(timesheetDetails.timesheet_id, doc.file_id)
                                                                            }} sx={{ zIndex: 10, backgroundColor: "error.main", '&:hover': { backgroundColor: "error.main", }, color: "#FFF !important", position: "absolute", top: 0, left: 0, borderRadius: "0 0 4px 0", p: .5 }}>
                                                                                <DeleteOutlineOutlinedIcon sx={{ fontSize: 16 }} />
                                                                            </IconButton>
                                                                        }
                                                                    </Box>
                                                                </Grid>
                                                            ))
                                                        }


                                                    </Grid>
                                                </Box>
                                            </>
                                        }
                                    </Box>
                                    <Box sx={{ textAlign: 'right', mt: 2 }}>
                                        <Box>
                                            <Button type="submit" variant="contained" disabled={isSubmitting}>{t('common.save')}</Button>
                                        </Box>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </ModalBox >
            <ModalBox
                open={isOpenInfoDetails}
                icon={<InfoOutlinedIcon color='primary' />}
                title={t('timesheet.timesheet_information')}
                closeBtn
                onClose={() => { setIsOpenInfoDetails(false); }} sx={{ width: { xs: "100%", sm: "80% !important", md: "70% !important", lg: "45% !important" }, maxWidth: { xs: "95%", sm: "80%", md: "70%", lg: "45%" } }}>
                <Box>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000' }}>{t('timesheet.timesheet_title')}</Typography>
                                    <Typography>{timesheetDetails?.title}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000' }}>{t('timesheet.timesheet_weeks')}</Typography>
                                    <Typography>{timesheetDetails?.period} {timesheetDetails?.period > 1 ? t('timesheet.timesheet_weeks') : t('timesheet.timesheet_week')}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000', mb: 1 }}>{t('timesheet.timesheet_status')}</Typography>
                                    <Chip
                                        size='small'
                                        icon={timesheetDetails?.status == 1 ? <PauseCircleOutlineIcon sx={{ color: '#fff !important' }} /> : timesheetDetails?.status == 2 ? <VerifiedOutlinedIcon sx={{ color: '#fff !important' }} /> : <DoDisturbAltOutlinedIcon sx={{ color: '#fff !important' }} />}
                                        sx={{ backgroundColor: timesheetDetails?.status == 1 ? "#ff9800" : timesheetDetails?.status == 2 ? "#4caf50" : '#d32f2f', color: '#fff' }}
                                        label={timesheetDetails?.status == 1 ? t('timesheet.pending') : timesheetDetails?.status == 2 ? t('timesheet.approved') : t('timesheet.denied')}></Chip>
                                </Box>
                            </Grid>
                            {
                                timesheetDetails.status === 2 &&
                                <Grid item xs={12} md={6}>
                                    <Box>
                                        <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000' }}>{t('timesheet.approved_by')}</Typography>
                                        <Stack direction='row' sx={{ gap: 1, alignItems: 'center', my: 1 }}>
                                            <Avatar sx={{ backgroundColor: '#ffd492', color: 'primary.main', fontWeight: 700, width: 30, height: 30, textTransform: "capitalize", fontSize: 15 }}>
                                                {timesheetDetails?.approver_first_name && timesheetDetails?.approver_first_name[0]}
                                            </Avatar>
                                            <Typography variant="body1" color="initial">{timesheetDetails.approver_first_name} {timesheetDetails.approver_last_name}</Typography>
                                        </Stack>
                                        <Stack direction='row' sx={{ gap: 1, alignItems: 'center' }}>
                                            <Avatar sx={{ backgroundColor: '#ffd492', color: 'primary.main', width: 30, height: 30, textTransform: "capitalize", fontSize: 15 }}>
                                                <LocalPhoneOutlinedIcon sx={{ fontSize: 18 }} />
                                            </Avatar>
                                            <Typography variant="body1" color="initial">+{timesheetDetails.approver_mobile_code} {timesheetDetails.approver_mobile_number}</Typography>
                                        </Stack>
                                    </Box>
                                </Grid>
                            }
                            {
                                timesheetDetails.status === 3 &&
                                <Grid item xs={12} md={6}>
                                    <Box>
                                        <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000', mb: 1 }}>{t('common.comments')}</Typography>
                                        <Typography>{timesheetDetails.denial_comment}</Typography>
                                    </Box>
                                </Grid>
                            }
                            {
                                timesheetDetails?.users &&
                                <Grid item xs={12} md={6}>
                                    <Box>
                                        <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000', mb: 1 }}>{t('common.assigned_users')}</Typography>
                                        <Paper className='scroll-bar' sx={{ border: '1px solid', boxShadow: 0, borderColor: '#f3f3f3', maxHeight: 200, overflow: "auto", }}>
                                            <List sx={{ m: 0 }}>
                                                {
                                                    timesheetDetails?.users?.map((user) => (
                                                        <ListItem key={user?.id} sx={{ px: .8, py: .8, gap: .5, '&:not(:last-of-type)': { borderBottom: '1px solid #f3f3f3' } }}>
                                                            <ListItemAvatar sx={{ minWidth: 'auto' }} >
                                                                <Avatar sx={{ backgroundColor: '#ffd492', color: 'primary.main', fontWeight: 700, width: 30, height: 30, textTransform: "capitalize", fontSize: 15 }}>
                                                                    {user?.first_name && user?.first_name[0]}
                                                                </Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText>
                                                                {user?.first_name}  {user?.last_name}
                                                            </ListItemText>
                                                        </ListItem>
                                                    ))
                                                }
                                            </List>
                                        </Paper>
                                    </Box>
                                </Grid>
                            }

                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000' }}>{t('common.documents')}</Typography>
                                    <Box sx={{ mt: 1 }}>
                                        <Box>
                                            <Grid container spacing={1}>
                                                {
                                                    timesheetDetails?.documents?.map(doc => (
                                                        <Grid item key={doc.id} xs={12} sm={6} md={4}>
                                                            <Box sx={{ overflow: 'hidden', backgroundColor: 'background.light', position: 'relative', textDecoration: 'none', color: 'inherit' }} component={'a'} href={doc.view_file_url} target='_blank' >
                                                                <Box sx={{ height: '120px', position: 'relative', cursor: 'pointer', '&:hover .show-icon': { opacity: 1 }, borderRadius: '5px 5px 0px 0px', overflow: 'hidden' }} >
                                                                    <Avatar sx={{ height: '100%', width: '100%', borderRadius: 0, objectFit: 'cover', backgroundColor: "primary.main", boxSizing: "border-box", fontSize: '16px' }}><InsertDriveFileOutlinedIcon sx={{ fontSize: 30, color: '#fff' }} /></Avatar>
                                                                </Box>
                                                                <Box sx={{ py: .6, px: .8, backgroundColor: 'background.light', borderRadius: '0px 0px 5px 5px' }}>
                                                                    <Typography sx={{ display: '-webkit-box', WebkitLineClamp: 1, overflow: 'hidden', textOverflow: 'ellipsis', WebkitBoxOrient: 'vertical', fontSize: '13px' }}>{doc.name}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    ))
                                                }

                                            </Grid>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>
            </ModalBox>

            <ModalBox
                open={viewComments}
                icon={<CommentOutlinedIcon color='primary' />}
                title={t('common.comments')}
                closeBtn
                onClose={() => { setViewComments(false); }} sx={{ width: { xs: "100%", sm: "80% !important", md: "70% !important", lg: "45% !important" }, maxWidth: { xs: "95%", sm: "80%", md: "70%", lg: "45%" }, p: { xs: .8, md: 0 } }}>
                <Box sx={{ minHeight: 300, display: 'flex', gap: 1, flexDirection: 'column' }}>
                    <Typography variant="body1" sx={{ fontWeight: 700, color: 'warning.main' }}>{t('timesheet.comments_reason')}</Typography>
                    <Box sx={{ p: .8, backgroundColor: '#f7f7f7', borderRadius: 1, flexGrow: 1 }}>
                        <Typography variant="body1">{timesheetDetails.denial_comment}</Typography>
                    </Box>
                </Box>
            </ModalBox>

            <ModalBox
                open={approveForm.open}
                icon={<FileOpenOutlinedIcon color='primary' />}
                title={t('timesheet.update_timesheet_status')}
                closeBtn
                onClose={() => { setApproveForm((prev) => ({ ...prev, open: false })); }} sx={{ width: { xs: "100%", sm: "80% !important", md: "70% !important", lg: "45% !important" }, maxWidth: { xs: "95%", sm: "80%", md: "70%", lg: "45%" }, p: { xs: .8, md: 0 } }}>
                <Box>
                    <Box sx={{ mb: 2 }}>
                        {
                            approveForm.status === 2 ?
                                <Alert icon={<NoteAltOutlinedIcon fontSize="inherit" />} sx={{ boxShadow: 0 }} severity="info">
                                    {t('timesheet.approve_warning')}
                                </Alert>
                                :
                                <Alert icon={<DoDisturbAltOutlinedIcon fontSize="inherit" />} sx={{ boxShadow: 0 }} severity="error">
                                    {t('timesheet.deny_warning')}
                                </Alert>
                        }
                    </Box>
                    <Formik
                        initialValues={{
                            'status': approveForm.status,
                            'approver_first_name': '',
                            'approver_last_name': '',
                            'approver_country_code': '',
                            'approver_mobile_code': 46,
                            'approver_mobile_number': '',
                            'denial_comment': ''
                        }}
                        validationSchema={
                            Yup.object({
                                approver_first_name:
                                    approveForm.status === 2 &&
                                    Yup.string()
                                        .required(t('timesheet.first_name_required')),
                                approver_last_name:
                                    approveForm.status === 2 &&
                                    Yup.string()
                                        .required(t('timesheet.last_name_required')),
                                approver_mobile_number:
                                    approveForm.status === 2 &&
                                    Yup.number()
                                        .required(t('timesheet.mobile_number_required')),
                                denial_comment:
                                    approveForm.status === 3 &&
                                    Yup.string()
                                        .required(t('timesheet.comment_required')),
                            })
                        }
                        onSubmit={async (values, { setSubmitting }) => {
                            let formData = {}
                            if (approveForm.status === 2) {
                                delete values.denial_comment
                                formData = { ...values }
                            }
                            else {
                                formData = { 'denial_comment': values.denial_comment, 'status': values.status }
                            }
                            await handleUpdateStatus(approveForm.id, formData)
                            setSubmitting(false);
                        }} >
                        {({ setFieldValue, values, setFieldTouched }) => (
                            <Form>
                                <Grid container spacing={{ xs: 2, }}>
                                    {
                                        approveForm.status === 2 &&
                                        <>
                                            <Grid item xs={12} sm={6} >
                                                <Box>
                                                    <Field
                                                        component={TextField}
                                                        name="approver_first_name"
                                                        label={`${t('timesheet.first_name')} *`}
                                                        fullWidth />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6} >
                                                <Box>
                                                    <Field
                                                        component={TextField}
                                                        name="approver_last_name"
                                                        label={`${t('timesheet.last_name')} *`}
                                                        fullWidth />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}  >
                                                <Box>
                                                    <PhoneInput
                                                        className="react-tel-input profile-pho-inp"
                                                        country={'se'}
                                                        name="approver_mobile_number"
                                                        countryCodeEditable={false}
                                                        specialLabel=''
                                                        value={(values.approver_mobile_code ? values.approver_mobile_code : "+46") + (values.approver_mobile_number ? values.approver_mobile_number : "")}
                                                        onBlur={() => { setFieldTouched('approver_mobile_number', true) }}
                                                        onChange={(phoneNumber, phoneNumberObj) => {
                                                            setFieldValue('approver_mobile_number', phoneNumber.slice(phoneNumberObj.dialCode.length))
                                                            setFieldValue('approver_mobile_code', phoneNumberObj.dialCode)
                                                            setFieldValue('approver_country_code', phoneNumberObj.countryCode)
                                                        }}
                                                        fullWidth
                                                    />
                                                    <ErrorMessage component="span" name="approver_mobile_number" />
                                                </Box>
                                            </Grid>
                                        </>
                                    }
                                    <>
                                        {
                                            approveForm.status === 3 &&
                                            <Grid item xs={12}>
                                                <Box>
                                                    <Field
                                                        multiline
                                                        component={TextField}
                                                        rows={8}
                                                        name="denial_comment"
                                                        label={`${t('common.comments')} *`}
                                                        fullWidth />
                                                </Box>
                                            </Grid>
                                        }

                                    </>


                                </Grid>
                                <Box sx={{ textAlign: 'right', mt: 2 }}>
                                    <Box>
                                        <Button type="submit" variant="contained" disabled={isSubmitting}>Save</Button>
                                    </Box>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </ModalBox>

            <ModalBox
                open={viewUsersLst.open}
                icon={<GroupOutlinedIcon color='primary' />}
                title="Timesheet Customers"
                closeBtn
                onClose={() => { setViewUsersLst((prev) => ({ ...prev, open: false })) }} sx={{ width: { xs: "100%", sm: "80% !important", md: "70% !important", lg: "45% !important" }, maxWidth: { xs: 300, sm: 500 } }}>
                <Box sx={{ p: 1 }}>
                    <Box sx={{ border: '1px solid', borderColor: '#f3f3f3', borderRadius: 1, overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 500 }} className='scroll-bar'>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow sx={{ '& th': { fontWeight: 600, fontSize: 14, whiteSpace: 'nowrap', backgroundColor: '#f3f3f3', p: 1.5, border: 0, borderBottom: '1px solid', borderColor: '#f3f3f3', } }}>
                                        <TableCell>{t('common.first_name')}</TableCell>
                                        <TableCell>{t('common.last_name')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        viewUsersLst.users?.map((user) => (
                                            <TableRow sx={{ '& td': { fontSize: 14, whiteSpace: 'nowrap', borderBottom: '1px solid', borderColor: '#f3f3f3', p: 1.5 }, '&:last-of-type td': { borderBottom: 0 } }}>
                                                <TableCell>{user.first_name}</TableCell>
                                                <TableCell>{user.last_name ? user.last_name : '-'}</TableCell>
                                            </TableRow>
                                        ))
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </ModalBox>
        </Box >
    )
}

export default Timesheets


