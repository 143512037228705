import { Avatar, Box, Paper, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../components/TabPanel';
import ContractsList from './ContractsList';
import useAuth from '../../hooks/use-auth';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useTranslation } from 'react-i18next';

const Contracts = () => {
    const { t } = useTranslation()
    const [contractTab, setContractTab] = useState(0);
    const handleUsersTab = (event, newValue) => {
        setContractTab(newValue);
    };
    const { user } = useAuth()

    return (
        <Box>
            <Paper sx={{ backgroundColor: "background.card", mt: 1.5, borderRadius: 1.5, minHeight: 'calc(90vh - 120px)' }}>
                {
                    user.role_id === 1 ?
                        <Box sx={{ p: 1 }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'background.light' }}>
                                <Tabs
                                    value={contractTab}
                                    onChange={handleUsersTab}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    <Tab label={t('contract.customer')} sx={{ color: 'text.secondary' }} />
                                    <Tab label={t('contract.employee')} sx={{ color: 'text.secondary' }} />
                                </Tabs>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <TabPanel value={contractTab} index={0}>
                                    <ContractsList contractTab={contractTab} />
                                </TabPanel>
                                <TabPanel value={contractTab} index={1}>
                                    <ContractsList contractTab={contractTab} />
                                </TabPanel>
                            </Box>
                        </Box>
                        :
                        <>
                            <Stack direction={'row'} sx={{ gap: 1, alignItems: 'center', flexGrow: 1, flexDirection: 'row', p: 1, borderBottom: '1px solid', borderColor: 'primary.light' }} >
                                <Box>
                                    <Avatar sx={{ backgroundColor: 'primary.main', width: 45, height: 45, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <InsertDriveFileOutlinedIcon sx={{ fontSize: 22 }} />
                                    </Avatar>
                                </Box>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Typography sx={{ fontSize: { xs: 14, sm: 16 }, fontWeight: 600 }} color="#000">{t('menu.contract')}</Typography>
                                    <Typography color="text.secondary" sx={{ fontSize: 13 }}>{t('contract.heading_text_one')}</Typography>
                                </Box>
                            </Stack>
                            <Box sx={{ p: 1, pt: 1.5 }}>
                                <ContractsList contractTab={user.role_id == 3 ? 0 : 1} />
                            </Box>
                        </>
                }
            </Paper>
        </Box>
    )
}

export default Contracts

