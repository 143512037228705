import { Box, Button, Typography, TextField as MuiTextField, Stack, Tooltip, TableContainer, InputAdornment, Table, TableHead, TableCell, TableRow, TableBody, TableFooter, TablePagination, Grid, Paper, Avatar, MenuItem, ListItemIcon, ListItemText, Menu, IconButton, Pagination, useMediaQuery, Autocomplete, CircularProgress } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModalBox from '../../components/ModalBox';
import Popup from '../../components/Popup';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import { alertMsg } from '../../utils/basicUtils';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import useAuth from '../../hooks/use-auth';
import DatePicker from 'react-multi-date-picker';
import useAxiosPrivate from '../../hooks/use-axios-private';
import moment from 'moment';
import Empty from '../../components/Empty';
import DropZone from '../../components/DropZone';
import Preloader from '../../components/Preloader';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import { useTranslation } from 'react-i18next';

const UserAction = ({ getSalarySlipInfo, file, setOpen, setIsOpenInfoDetails, setShowDeleteModal, setSelectedSalarySlip, }) => {
    const [isActionsOpen, setIsActionsOpen] = useState(false);
    const { t } = useTranslation()
    const handleActions = (event) => {
        setIsActionsOpen(event.currentTarget);
    };
    const { user } = useAuth()

    return (
        <Box>
            <IconButton size='small' onClick={handleActions} >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={isActionsOpen}
                open={Boolean(isActionsOpen)}
                onClose={() => setIsActionsOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {user.role_id === 1 &&
                    <MenuItem onClick={async () => {
                        await getSalarySlipInfo(file.id, 'edit');
                        setOpen(true)
                        setIsActionsOpen(false)
                    }}>
                        <ListItemIcon sx={{ minWidth: 30 }}>
                            <EditOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('common.edit')}</ListItemText>
                    </MenuItem>
                }
                {
                    user.role_id === 1 &&
                    <MenuItem
                        onClick={async () => {
                            await getSalarySlipInfo(file.id, 'view');
                            setIsOpenInfoDetails(true);
                            setIsActionsOpen(false);
                            setSelectedSalarySlip(file)
                        }}

                    >
                        <ListItemIcon sx={{ minWidth: 30 }}>
                            <InfoOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('common.info')}</ListItemText>
                    </MenuItem>
                }
                <MenuItem onClick={() => { window.open(`${file.documents[0]?.view_file_url}`, "_blank"); setIsActionsOpen(false); }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                        <VisibilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('common.view')}</ListItemText>
                </MenuItem>
                <MenuItem download component={'a'} href={file?.documents[0]?.download_file_url} color='success' onClick={() => { setIsActionsOpen(false) }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                        <CloudDownloadOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('common.download')}</ListItemText>
                </MenuItem>
                {user.role_id === 1 &&
                    <MenuItem onClick={() => { setShowDeleteModal(true); setIsActionsOpen(false); setSelectedSalarySlip(file) }}>
                        <ListItemIcon sx={{ minWidth: 30 }}>
                            <DeleteOutlineOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('common.delete')}</ListItemText>
                    </MenuItem>
                }
            </Menu >
        </Box >
    )
}

const SalarySlip = () => {

    const datePickerStyle = {
        '& .rmdp-container': {
            width: '100% ',
            display: 'block !important',
            '& .rmdp-input': { height: 38, width: '100%', pl: '14px', pr: '25px', borderColor: '#0000003b', boxSizing: 'border-box' },
            '& .rmdp-input ~ svg': { right: '8px !important', color: '#0000008a !important' },
            '& .rmdp-input:focus': { boxShadow: 'none', borderColor: 'primary.main' },
            '& .rmdp-time-picker input': { width: '25px' },

        }
    }
    const { t } = useTranslation()
    const [salarySlipList, setSalarySlipList] = useState([])
    const [selectedSalarySlip, setSelectedSalarySlip] = useState({})
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [open, setOpen] = useState(false)
    const [pageInfo, setPageInfo] = useState({})
    const [isGridView, setIsGridView] = useState(false)
    const isTabView = useMediaQuery("(max-width: 900px)")
    const [isOpenInfoDetails, setIsOpenInfoDetails] = useState(false)
    const [uploading, setUploading] = useState({ load: false, upload: false, delete: false })
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [uploadingItems, setUploadedItems] = useState([])
    const [searchText, setSearchText] = useState('')
    const [userLst, setUserLst] = useState([])
    const [ShowDeleteModal, setShowDeleteModal] = useState(false)
    const { user } = useAuth()
    const axios = useAxiosPrivate()
    const [filterByYear, setFilterByYear] = useState(null)
    const [filterByUser, setFilterByUser] = useState(null)

    const [dateRange, setDateRange] = useState({
        'month': new Date(),
        'year': new Date()
    })
    const salarySlipRef = useRef()
    const initialSalarySlipValues = {
        "salary_slip_id": "",
        "title": "",
        "month": '',
        "year": '',
        "user_id": 0,
    }
    const [salarySlipDetails, setsalarySlipDetails] = useState(initialSalarySlipValues)


    const getUserLst = async () => {
        try {
            const response = await axios.get(`users/list/`, {
                params: {
                    role_id: 4,
                    limit: 0
                }
            })
            setUserLst(response.data.data.users)
        } catch (err) {
            console.error(err)
        }
    }

    const handleDeleteSalarySlip = async (id) => {
        try {
            const response = await axios.delete(`salary_slip/${id}`)
            alertMsg(response.data.message, 'success')
            getSalarySlipLst()
        } catch (err) {
            console.error(err)
        }
    }

    const handleDeleteDocument = async (id, fileid) => {
        try {
            setUploading((prev) => ({ ...prev, delete: true }))
            const response = await axios.post(`salary_slip/delete_doc/${id}`, {
                file_id: fileid
            })
            setUploadedItems([])
            setUploading((prev) => ({ ...prev, delete: false }))
            alertMsg(response.data.message, 'success')
        } catch (err) {
            console.error(err)
            setUploading((prev) => ({ ...prev, delete: false }))
        }
    }

    const getSalarySlipInfo = async (id, mode) => {
        try {
            const response = await axios.get(`salary_slip/${id}`)
            setsalarySlipDetails({ ...response.data.data.salary_slip })
            salarySlipRef.current = response.data.data.salary_slip.salary_slip_id
            setUploadedItems(response.data.data.salary_slip.documents)
            const currentDate = new Date();
            setDateRange(prev => {
                prev.month = (currentDate.setMonth((response.data.data.salary_slip.month) - 1))
                prev.year = currentDate.setFullYear(response.data.data.salary_slip.year)
                return { ...prev }
            })

        } catch (err) {
            console.error(err)
        }
    }

    const fileUpload = async (e, id) => {
        const { files } = e.target
        if (files[0]) {
            const formData = new FormData()
            formData.append("file", files[0])
            formData.append("salary_slip_id", id)
            getSalarySlipId(formData)
            e.target.value = null;
        }
    }

    const getSalarySlipId = async (data) => {
        try {
            if (!data) {
                setIsSubmitting(true)
                const response = await axios.post('salary_slip/upload_doc/')
                let { salary_slip_id } = response.data.data.contract_files
                salarySlipRef.current = salary_slip_id
                // setsalarySlipDetails(prev => ({ ...prev, "salary_slip_id": salary_slip_id }))
                setIsSubmitting(false)
            }
            else {
                setIsSubmitting(true)
                setUploading((prev) => ({ ...prev, upload: true }))
                const response = await axios.post('salary_slip/upload_doc/', data, {
                    headers: { "Content-Type": "multipart/form-data", },
                })
                let { documents } = response.data.data.contract_files
                setUploadedItems(documents)
                alertMsg(response.data.message, 'success')
                setUploading((prev) => ({ ...prev, upload: false }))
                setIsSubmitting(false)
            }
        }
        catch (e) {
            console.error("🚀  file: fileUpload.jsx:89 ", e)
            setIsSubmitting(false)
            setUploading((prev) => ({ ...prev, upload: false }))
            return false
        }
    }

    const getSalarySlipLst = async () => {
        try {
            setUploading((prev) => ({ ...prev, load: true }))
            const response = await axios.get('salary_slip/list/', {
                params: {
                    limit: rowsPerPage,
                    page: page + 1,
                    search: searchText,
                    role_id: 4,
                    // month: filterByYear?.format('MM'),
                    user_id: filterByUser?.id,
                    year: filterByYear?.format('YYYY'),
                }
            })
            setSalarySlipList(response.data.data.salary_slips)
            setPageInfo(response.data.data.page_info)
            setUploading((prev) => ({ ...prev, load: false }))
        } catch (err) {
            setUploading((prev) => ({ ...prev, load: false }))
            console.error(err)
        }
    }

    useEffect(() => {
        if (isTabView) {
            setIsGridView(true);
        }
        else {
            setIsGridView(false);
        }
    }, [isTabView])

    useEffect(() => {
        getSalarySlipLst()
    }, [page, rowsPerPage, searchText, filterByYear, filterByUser])

    useEffect(() => {
        if (user.role_id === 1 || user.role_id === 2) {
            getUserLst()
        }
    }, [])

    return (
        <Box>
            <Paper sx={{ backgroundColor: "background.card", mt: 1.5, borderRadius: 1.5, minHeight: 'calc(90vh - 120px)' }}>
                <Stack direction={'row'} sx={{ gap: 1, alignItems: 'center', flexGrow: 1, flexDirection: 'row', p: 1, borderBottom: '1px solid', borderColor: 'primary.light' }} >
                    <Box>
                        <Avatar sx={{ backgroundColor: 'primary.main', width: 45, height: 45, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <InsertDriveFileOutlinedIcon sx={{ fontSize: 22 }} />
                        </Avatar>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography sx={{ fontSize: { xs: 14, sm: 16 }, fontWeight: 600 }} color="#000">{t('menu.salary_slip')}</Typography>
                        {
                            user.role_id === 1 ?
                                <Typography color="text.secondary" sx={{ fontSize: 13 }}>{t('salary_slip.heading_text_one')}</Typography>
                                :
                                <Typography color="text.secondary" sx={{ fontSize: 13 }}>{t('salary_slip.heading_text_two')}</Typography>
                        }
                    </Box>
                </Stack>
                <Box sx={{ p: 1, pt: 1.5 }}>
                    <Box>
                        <Box sx={{ display: { xs: 'block', sm: "flex" }, gap: 1, flexWrap: "wrap", alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box>
                                <MuiTextField
                                    fullWidth
                                    sx={{ width: "100%", backgroundColor: '#f3f3f3', "& fieldset": { border: "0 !important" } }}
                                    placeholder={t('common.search')}
                                    size={'small'}
                                    value={searchText}
                                    onChange={(e) => { setSearchText(e.target.value) }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon sx={{ ml: 1.2, fontSize: 20 }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            <Stack sx={{ mt: { xs: 1, md: 0 }, flexDirection: 'row', gap: 1, alignItems: 'center', flexWrap: 'wrap' }}>
                                {
                                    (user.role_id === 1 || user.role_id === 2) &&
                                    <Box sx={{ minWidth: 250 }}>
                                        <Autocomplete
                                            isOptionEqualToValue={(option, value) => (option?.id === value?.id)}
                                            onChange={(event, newValue) => {
                                                setFilterByUser(newValue)
                                            }}
                                            getOptionKey={option => option.id}
                                            disableClearable
                                            value={filterByUser}
                                            sx={{ flexGrow: 1 }}
                                            getOptionLabel={option => `${option?.first_name} ${option?.last_name}`}
                                            options={userLst}
                                            renderInput={(params) => (
                                                <MuiTextField
                                                    name="filter_by_user"
                                                    {...params}
                                                    label={t('common.filter_employee')}
                                                />
                                            )}
                                        />
                                    </Box>
                                }


                                <Box sx={{
                                    display: "flex", alignItems: "center", gap: 1.3, justifyContent: "end", flexGrow: 1, position: 'relative',
                                    ...datePickerStyle
                                }}>
                                    <Typography variant="body1" color="text.secondary" sx={{ fontSize: 10, bgcolor: "background.card", position: "absolute", zIndex: 1, top: -8, left: 10, px: .4 }}>{t('common.filter_year')}</Typography>
                                    <DatePicker
                                        format="YYYY"
                                        onlyYearPicker
                                        name='filter'
                                        editable={false}
                                        placeholder='Filter'
                                        value={filterByYear}
                                        onChange={(monthYear) => {
                                            if (monthYear) {
                                                setFilterByYear(monthYear)
                                            }
                                        }}
                                        onOpenPickNewDate={false}
                                    />
                                    {
                                        (filterByYear || filterByUser) &&
                                        <Box>
                                            <Tooltip title={t('common.reset_filter')} placement='top'>
                                                <Button sx={{ height: '31px', width: '31px', p: '5px ', minWidth: 'auto' }} variant='outlined' onClick={() => {
                                                    setFilterByYear(null)
                                                    setFilterByUser(null)
                                                }}><FilterAltOffOutlinedIcon /></Button>
                                            </Tooltip>
                                        </Box>
                                    }

                                </Box>
                                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                    <Button sx={{ height: '31px', width: '31px', p: '5px ', minWidth: 'auto' }} variant='outlined' onClick={() => setIsGridView(prev => !prev)}>
                                        {isGridView ?
                                            <ListOutlinedIcon sx={{ fontSize: 20 }} /> : <GridViewOutlinedIcon sx={{ fontSize: 20 }} />
                                        }
                                    </Button>
                                </Box>
                                {
                                    (user.role_id === 1) &&
                                    <Box>
                                        <Button sx={{ minWidth: 'auto' }} variant='outlined' onClick={() => {
                                            getSalarySlipId()
                                            setUploadedItems([])
                                            setsalarySlipDetails(initialSalarySlipValues)
                                            setOpen(true)
                                        }} startIcon={<CloudUploadOutlinedIcon sx={{ fontSize: 20 }} />}>
                                            {t('common.upload')}
                                        </Button>
                                    </Box>
                                }
                            </Stack>
                        </Box>
                        {
                            uploading.load ?
                                <Box sx={{ mt: 1, minHeight: '64vh', display: 'flex', alignItems: 'center', backgroundColor: '#ffff', border: '2px solid', borderRadius: 1, borderColor: "background.light" }}>
                                    <Preloader preload={false} />
                                </Box>
                                :
                                <Box sx={{ mt: 1 }}>
                                    {
                                        salarySlipList.length > 0 ?

                                            <>
                                                {
                                                    !isGridView ?
                                                        <TableContainer>
                                                            <Table stickyHeader size='small' >
                                                                <TableHead>
                                                                    <TableRow sx={{ '& th': { whiteSpace: 'nowrap', backgroundColor: 'background.card', p: 1.5, border: 0, borderBottom: '1px solid #ebf1fc', } }}>
                                                                        <TableCell sx={{ minWidth: { xs: 180, xl: 'auto' } }}>
                                                                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'start' }}>
                                                                                <Box sx={{ height: 20 }}>
                                                                                    <InsertDriveFileOutlinedIcon sx={{ fontSize: 20 }} />
                                                                                </Box>
                                                                                <Box>
                                                                                    <Typography sx={{ maxLines: 1, fontWeight: 700 }} variant="body1">{t('salary_slip.salary_slip_title')}</Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </TableCell>
                                                                        {
                                                                            (user.role_id === 1 || user.role_id === 2) &&
                                                                            <TableCell sx={{ minWidth: 180 }} align={'center'}><Typography sx={{ fontWeight: 700 }}>{t('salary_slip.salary_slip_employee_name')}</Typography></TableCell>
                                                                        }

                                                                        <TableCell sx={{ minWidth: 100 }} align={'center'}><Typography sx={{ fontWeight: 700 }}>{t('salary_slip.salary_slip_month')}</Typography></TableCell>
                                                                        <TableCell sx={{ minWidth: 100 }} align={'center'}><Typography sx={{ fontWeight: 700 }}>{t('salary_slip.salary_slip_year')}</Typography></TableCell>
                                                                        <TableCell align={'center'} sx={{ width: 300 }} ><Typography sx={{ fontWeight: 700 }}>{t('common.action')}</Typography></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {
                                                                        salarySlipList.map((file, index) => (
                                                                            <TableRow key={index} sx={{ '& td': { px: 0, borderBottom: '1px solid #ebf1fc', py: "2px" }, '& td>div': { px: "12px", py: '2px', backgroundColor: 'background.card', minHeight: 50, }, }}>
                                                                                <TableCell sx={{ minWidth: { xs: 180, xl: 'auto' } }}>
                                                                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'start' }}>
                                                                                        <Box sx={{ height: 20 }}>
                                                                                            <InsertDriveFileOutlinedIcon sx={{ fontSize: 20 }} />
                                                                                        </Box>
                                                                                        <Box>
                                                                                            <Tooltip title={file.title} arrow placement='top'>
                                                                                                <Typography sx={{ maxLines: 1 }} variant="body1">{file.title}</Typography>
                                                                                            </Tooltip>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </TableCell>
                                                                                {
                                                                                    (user.role_id === 1 || user.role_id === 2) &&
                                                                                    <TableCell align={'center'} sx={{ minWidth: 180 }}>
                                                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                                            <Typography variant="body1">{file?.user.first_name} {file?.user.last_name}</Typography>
                                                                                        </Box>
                                                                                    </TableCell>
                                                                                }
                                                                                <TableCell align={'center'} sx={{ minWidth: 100 }}>
                                                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                                        <Typography variant="body1">{moment().month(file?.month - 1).format('MMMM')}</Typography>
                                                                                    </Box>
                                                                                </TableCell>
                                                                                <TableCell align={'center'} sx={{ minWidth: 100 }}>
                                                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                                        <Typography variant="body1">{file.year}</Typography>
                                                                                    </Box>
                                                                                </TableCell>
                                                                                <TableCell align={'center'}>
                                                                                    <Stack direction={'row'} sx={{ justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                                                                                        {user.role_id === 1 &&
                                                                                            <Box>
                                                                                                <Tooltip title={t('common.edit')} arrow placement='top'>
                                                                                                    <IconButton size='small' color='success' onClick={async () => {
                                                                                                        await getSalarySlipInfo(file.id, 'edit');
                                                                                                        setOpen(true)
                                                                                                    }}>
                                                                                                        <EditOutlinedIcon sx={{ fontSize: "22px" }} />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </Box>
                                                                                        }
                                                                                        {user.role_id === 1 &&
                                                                                            <Box>
                                                                                                <Tooltip title={t('common.info')} arrow placement='top'>
                                                                                                    <IconButton size='small' color='info'
                                                                                                        onClick={async () => {
                                                                                                            await getSalarySlipInfo(file.id, 'view');
                                                                                                            setIsOpenInfoDetails(true)
                                                                                                        }}
                                                                                                    >
                                                                                                        <InfoOutlinedIcon sx={{ fontSize: "22px" }} />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </Box>
                                                                                        }
                                                                                        <Box>
                                                                                            <Tooltip title={t('common.view')} arrow placement='top'>
                                                                                                <IconButton size='small' onClick={() => {
                                                                                                    window.open(`${file.documents[0]?.view_file_url}`, "_blank");
                                                                                                }} color='warning'>
                                                                                                    <VisibilityIcon sx={{ fontSize: "22px" }} />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Box>
                                                                                        <Box>
                                                                                            <Tooltip title={t('common.download')} arrow placement='top'>
                                                                                                <IconButton size='small' href={file.documents[0]?.download_file_url} color='success'>
                                                                                                    <CloudDownloadOutlinedIcon sx={{ fontSize: "22px" }} />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Box>
                                                                                        {user.role_id === 1 &&
                                                                                            <Box>
                                                                                                <Tooltip title={t('common.delete')} arrow placement='top'>
                                                                                                    <IconButton size='small' color='error' onClick={() => { setShowDeleteModal(true); setSelectedSalarySlip(file) }}>
                                                                                                        <DeleteOutlineOutlinedIcon sx={{ fontSize: "22px" }} />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </Box>
                                                                                        }
                                                                                    </Stack>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                    }
                                                                </TableBody>
                                                                <TableFooter sx={{ backgroundColor: 'background.card', p: 2, borderRadius: '5px' }}>
                                                                    <TableRow>
                                                                        <TablePagination
                                                                            rowsPerPageOptions={[10, 25, 50, 100]}
                                                                            colSpan={10}
                                                                            sx={{ borderBottom: 0, fontSize: 12, px: 0, "& .MuiInputBase-root": { width: "50px !important", mr: 1.3 }, "& input": { visibility: "hidden" }, "& p": { fontSize: 12 }, "& .MuiTablePagination-toolbar": { minHeight: "0px !important" }, "& .MuiSvgIcon-fontSizeMedium": { fontSize: 15 } }}
                                                                            count={pageInfo.count}
                                                                            rowsPerPage={rowsPerPage}
                                                                            page={page}
                                                                            onPageChange={(e, newPage) => { setPage(newPage) }}
                                                                            onRowsPerPageChange={(e) => { setRowsPerPage(parseInt(e.target.value), 10); setPage(0); }}

                                                                        />
                                                                    </TableRow>
                                                                </TableFooter>
                                                            </Table>
                                                        </TableContainer>
                                                        :
                                                        <Stack sx={{ gap: 1, justifyContent: 'space-between', minHeight: '71vh', }}>
                                                            <Box sx={{ maxHeight: { xs: 'calc(100vh - 300px)', md: 'calc(100vh - 180px)' }, overflow: 'auto', }} className='scroll-bar'>
                                                                <Grid container spacing={1.5} sx={{ pb: 1 }}>
                                                                    {
                                                                        salarySlipList.map((file, index) => (
                                                                            <Grid item xs={12} md={6} key={index}>
                                                                                <Paper sx={{ p: 1, backgroundColor: 'background.card' }}>
                                                                                    <Box>
                                                                                        <Stack direction={'row'} sx={{ gap: 1, alignItems: 'center' }}>
                                                                                            <Avatar sx={{ height: { xs: 65, sm: 80 }, width: { xs: 65, sm: 80 }, borderRadius: 1, backgroundColor: 'background.lightSecondary' }}>
                                                                                                <InsertDriveFileOutlinedIcon sx={{ fontSize: 30, color: 'primary.main' }} />
                                                                                            </Avatar>
                                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                                <Typography sx={{ fontWeight: 600, maxLines: 1, fontSize: 13, mb: { xs: .5, sm: 1 } }}>{file.title}</Typography>
                                                                                                <Typography variant="body2" sx={{ color: '#a1a1a1', mb: { xs: .5, sm: 1 } }}>{file?.user.first_name} {file?.user.last_name}</Typography>
                                                                                                <Typography variant="body2" sx={{ color: '#a1a1a1' }}>{moment(`${file.year} ${file.month}`, 'YYYY MM').format('MMM-YYYY')}</Typography>
                                                                                            </Box>
                                                                                            <UserAction
                                                                                                getSalarySlipInfo={getSalarySlipInfo}
                                                                                                setsalarySlipDetails={setsalarySlipDetails}
                                                                                                setSelectedSalarySlip={setSelectedSalarySlip}
                                                                                                setIsOpenInfoDetails={setIsOpenInfoDetails}
                                                                                                setOpen={setOpen}
                                                                                                setShowDeleteModal={setShowDeleteModal}
                                                                                                file={file}
                                                                                            />
                                                                                        </Stack>
                                                                                    </Box>
                                                                                </Paper>
                                                                            </Grid>
                                                                        ))
                                                                    }
                                                                </Grid>
                                                            </Box>
                                                            <Stack sx={{ alignItems: 'center', py: 2 }}>
                                                                <Pagination
                                                                    variant="outlined"
                                                                    color='primary'
                                                                    count={pageInfo.num_pages}
                                                                    page={page + 1}
                                                                    onChange={(e, newPage) => { setPage(newPage - 1) }}
                                                                />
                                                            </Stack>
                                                        </Stack>
                                                }

                                            </>
                                            :
                                            <>
                                                <Empty text={t('salary_slip.empty_text')} icon={<InsertDriveFileOutlinedIcon sx={{ fontSize: 30 }} />} sx={{ minHeight: '56vh' }} />
                                            </>
                                    }
                                </Box>
                        }
                    </Box>
                </Box>
            </Paper>
            <Popup show={ShowDeleteModal} title={`${t('common.delete')} ${t('menu.salary_slip')}`} consent={true} variant='error' primaryBtnTxt={t('common.delete')}
                onPrimaryClick={async (e, loader) => {
                    handleDeleteSalarySlip(selectedSalarySlip?.id);
                    setShowDeleteModal(false);
                }}
                onSecondaryClick={() => { setShowDeleteModal(false); }}
            >
                <Box sx={{ textAlign: "center" }}>
                    <Typography color='text.secondary' >{t('salary_slip.delete_text')} <span style={{ fontWeight: 600, color: "#000" }}>
                        {selectedSalarySlip?.title}
                    </span>. {t('common.delete_confirm_text')}</Typography>
                </Box>
            </Popup>
            <ModalBox
                open={open}
                icon={<FileUploadOutlinedIcon color='primary' />}
                title={salarySlipDetails?.id ? `${t('common.edit')} ${t('menu.salary_slip')}` : `${t('common.create')} ${t('menu.salary_slip')}`}
                closeBtn

                onClose={(e, reason) => {
                    if (reason) return;

                    if (isSubmitting) {
                        alertMsg(t('common.upload_progress_alert'), 'warning')
                        return
                    }

                    if (salarySlipDetails.id && uploadingItems.length === 0) {
                        alertMsg(t('common.document_required_alert'), 'warning')
                        return
                    }

                    if (salarySlipDetails.id && salarySlipDetails.documents !== uploadingItems) {
                        alertMsg(t('common.save_alert'), 'warning')
                    }
                    else {
                        // setUploadedItems({})
                        setOpen(false)
                    }
                }}
                sx={{ width: { xs: "100%", sm: "80% !important", md: "70% !important", lg: "45% !important" }, maxWidth: { xs: "95%", sm: "80%", md: "70%", lg: "45%" }, p: { xs: .8, md: 0 } }}
            >
                <Box>

                    <Box sx={{ mt: 2 }}>
                        <Formik
                            initialValues={salarySlipDetails}
                            validationSchema={
                                Yup.object({
                                    title: Yup.string()
                                        .required(t('salary_slip.title_required')),
                                    month: Yup.string()
                                        .required(t('salary_slip.month_required')),
                                    year: Yup.string()
                                        .required(t('salary_slip.year_required')),
                                })
                            }
                            onSubmit={async (values, { setSubmitting }) => {


                                if (uploadingItems.length === 0) {
                                    alertMsg(t('common.document_required_alert'), 'warning')
                                    return
                                }
                                try {
                                    if (salarySlipDetails.id) {
                                        const response = await axios.put(`salary_slip/${salarySlipDetails.id}`, values)
                                        alertMsg(response.data.message, "success")
                                    }
                                    else {
                                        const salarySlipData = {
                                            ...values,
                                            'salary_slip_id': salarySlipRef.current
                                        }
                                        const response = await axios.post('salary_slip/create/', salarySlipData)
                                        alertMsg(response.data.message, "success")
                                    }

                                    getSalarySlipLst()
                                    setUploadedItems([])
                                    setOpen(false)
                                }
                                catch (err) {
                                    console.error(err)
                                    setOpen(true)
                                }
                                setSubmitting(false);
                            }} >
                            {({ setFieldValue, values, setFieldTouched }) => (
                                <Form>
                                    <Grid container spacing={{ xs: 2, }}>
                                        <Grid item xs={12} sm={6} >
                                            <Box>
                                                <Field
                                                    component={TextField}
                                                    name="title"
                                                    label={`${t('salary_slip.salary_slip_title')} *`}
                                                    fullWidth />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >

                                            <Box>
                                                <Autocomplete
                                                    autoHighlight={true}
                                                    sx={{ flexGrow: 1 }}
                                                    getOptionLabel={option => `${option.first_name} ${option.last_name}`}
                                                    disableClearable
                                                    disabled={salarySlipDetails.id}
                                                    defaultValue={values.user}
                                                    options={userLst}
                                                    onChange={(event, newValue) => {
                                                        setFieldValue('user', newValue)
                                                        setFieldValue('user_id', newValue.id)
                                                    }}

                                                    renderInput={(params) => (
                                                        <Field
                                                            component={TextField}
                                                            name="users"
                                                            {...params}
                                                            label={`${t('salary_slip.add_employee')} *`}
                                                        />
                                                    )}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Box sx={{
                                                display: "flex", alignItems: "center", gap: 1.3, justifyContent: "end", flexGrow: 1, position: 'relative',
                                                ...datePickerStyle
                                            }}>
                                                <Typography variant="body1" color="text.secondary" sx={{ fontSize: 10, bgcolor: "background.card", position: "absolute", zIndex: 1, top: -8, left: 10, px: .4 }}>{t('salary_slip.salary_slip_month')}</Typography>
                                                <DatePicker
                                                    format="MMMM"
                                                    // render={<InputIcon />}
                                                    // value={values.month ? values.month : new Date()}
                                                    value={values.month && dateRange.month}
                                                    onlyMonthPicker
                                                    disableYearPicker
                                                    hideYear
                                                    name='month'
                                                    editable={false}
                                                    placeholder={`${t('salary_slip.salary_slip_month')} *`}
                                                    onChange={(date) => {
                                                        if (date) {
                                                            setDateRange(prev => {
                                                                prev.month = date
                                                                return { ...prev }
                                                            })
                                                            setFieldValue("month", date.format('MM'))
                                                        }
                                                    }}
                                                />
                                            </Box>
                                            <Box sx={{ "& span": { fontSize: 12, color: "error.main", m: "4px 14px 0", } }}>
                                                <ErrorMessage component="span" name="month" />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <Box sx={{
                                                display: "flex", alignItems: "center", gap: 1.3, justifyContent: "end", flexGrow: 1, position: 'relative',
                                                ...datePickerStyle
                                            }}>
                                                <Typography variant="body1" color="text.secondary" sx={{ fontSize: 10, bgcolor: "background.card", position: "absolute", zIndex: 1, top: -8, left: 10, px: .4 }}>{t('salary_slip.salary_slip_year')}</Typography>
                                                <DatePicker
                                                    format="YYYY"
                                                    // render={<InputIcon />}
                                                    // value={values.year ? dateRange.year : new Date()}
                                                    value={values.year && dateRange.year}
                                                    onlyYearPicker
                                                    name='year'
                                                    editable={false}
                                                    placeholder={`${t('salary_slip.salary_slip_year')} *`}
                                                    onChange={(date) => {
                                                        if (date) {
                                                            setDateRange(prev => {
                                                                prev.year = date
                                                                return { ...prev }
                                                            })
                                                            setFieldValue("year", date.format('YYYY'))
                                                        }
                                                    }}
                                                />
                                            </Box>
                                            <Box sx={{ "& span": { fontSize: 12, color: "error.main", m: "4px 14px 0", } }}>
                                                <ErrorMessage component="span" name="year" />
                                            </Box>

                                        </Grid>


                                    </Grid>
                                    <Box sx={{ mt: 2 }}>

                                        <DropZone
                                            getDropped={(e) => {
                                                fileUpload(e, salarySlipRef.current);
                                                e.target.files = null
                                            }}
                                            formats={[".pdf"]}
                                            disabled={uploading.upload}
                                        >
                                            <Box component={"label"} sx={{ cursor: 'pointer', position: 'relative', border: '1.5px dashed #d3d3d3', p: 1, minHeight: { xs: 200, sm: 250 }, borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', pointerEvents: uploading.upload ? 'none' : 'auto' }} className="scroll-bar">
                                                <input disabled={uploading.upload} hidden accept={".pdf"} type="file" multiple onChange={e => { fileUpload(e, salarySlipRef.current); e.target.files = null }} />
                                                <Box>
                                                    <Box sx={{ backgroundColor: 'background.success', width: 80, height: 80, borderRadius: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <CollectionsOutlinedIcon sx={{ fontSize: '40px' }} color='success' />
                                                    </Box>
                                                </Box>
                                                <Box sx={{ mt: 1, textAlign: 'center' }}>
                                                    <Typography component={'span'} sx={{ fontSize: { xs: 14, sm: 16, md: '18px' } }}>{t('common.upload_text')} <Typography component={'span'} sx={{ color: 'info.main', fontSize: { xs: 14, sm: 16, md: '18px' } }}>{t('common.browse_text')}</Typography></Typography>
                                                </Box>
                                                <Box sx={{ mt: .5 }}>
                                                    <Typography sx={{ fontSize: '13px', color: 'grey', fontWeight: 400 }}>{t('common.support_text')}: PDF</Typography>
                                                </Box>
                                                {
                                                    uploading.upload && <Box sx={{ position: 'absolute', inset: 0, backgroundColor: '#ffffff87', display: 'flex', alignItems: 'center', justifyContent: 'center', backdropFilter: 'blur(4px)', flexDirection: 'column', gap: 1 }}>
                                                        <CircularProgress />
                                                        <Typography variant="body1" color="initial"> {t('common.uploading')}... </Typography>
                                                    </Box>
                                                }
                                            </Box>
                                        </DropZone>
                                    </Box>
                                    {
                                        uploadingItems.length > 0 &&
                                        <>
                                            <Box sx={{ mt: 2 }}>
                                                <Grid container spacing={1} sx={{ pb: 2 }}>
                                                    {
                                                        uploadingItems?.map((doc) => (
                                                            <Grid key={doc.id} item xs={12} sm={6} md={4} lg={3} xl={2} >
                                                                <Box sx={{ overflow: 'hidden', backgroundColor: 'background.light', position: 'relative', borderRadius: '5px' }}>
                                                                    <Box component={'a'} href={`${doc.view_file_url}`} sx={{ textDecoration: 'none', color: 'inherit' }} target='_blank'>
                                                                        <Box sx={{ height: '120px', position: 'relative', cursor: 'pointer', '&:hover .show-icon': { opacity: 1 }, overflow: 'hidden' }}>
                                                                            <Avatar sx={{ height: '100%', width: '100%', borderRadius: 0, objectFit: 'cover', backgroundColor: "primary.main", boxSizing: "border-box", fontSize: '16px' }}>
                                                                                {
                                                                                    !uploading.delete ?
                                                                                        <InsertDriveFileOutlinedIcon sx={{ fontSize: 30, color: '#fff' }} />
                                                                                        :
                                                                                        <CircularProgress sx={{ color: '#FEF3E2', opacity: .8 }} />
                                                                                }
                                                                            </Avatar>
                                                                        </Box>
                                                                        <Box sx={{ py: .6, px: .8, backgroundColor: 'background.light' }}>
                                                                            <Typography sx={{ display: '-webkit-box', WebkitLineClamp: 1, overflow: 'hidden', textOverflow: 'ellipsis', WebkitBoxOrient: 'vertical', fontSize: '13px' }}>
                                                                                {!uploading.delete ? doc.name : `${t('common.deleting')}...`}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    {
                                                                        salarySlipDetails.id &&
                                                                        <IconButton onClick={(e) => { e.preventDefault(); handleDeleteDocument(salarySlipDetails.salary_slip_id, doc.file_id) }} sx={{ zIndex: 10, backgroundColor: "error.main", '&:hover': { backgroundColor: "error.main", }, color: "#FFF !important", position: "absolute", top: 0, left: 0, borderRadius: "0 0 4px 0", p: .5 }}>
                                                                            <DeleteOutlineOutlinedIcon sx={{ fontSize: 16 }} />
                                                                        </IconButton>
                                                                    }
                                                                </Box>
                                                            </Grid>
                                                        ))
                                                    }
                                                </Grid>
                                            </Box>
                                        </>
                                    }
                                    <Box sx={{ textAlign: 'right', mt: 2 }}>
                                        <Box>
                                            <Button type="submit" variant="contained" disabled={isSubmitting}>{t('common.save')}</Button>
                                        </Box>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </ModalBox>

            <ModalBox
                open={isOpenInfoDetails}
                onClose={() => { setIsOpenInfoDetails(false) }}
                icon={<InfoOutlinedIcon color='primary' />}
                title={t('salary_slip.salary_slip_information')}
                closeBtn
                sx={{ width: { xs: "100%", sm: "80% !important", md: "70% !important", lg: "45% !important" }, maxWidth: { xs: "95%", sm: "80%", md: "70%", lg: "45%" } }}>
                <Box>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000' }}>{t('salary_slip.salary_slip_title')}</Typography>
                                    <Typography>{salarySlipDetails?.title}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000' }}>{t('salary_slip.salary_slip_month')}</Typography>
                                    <Typography>{moment().month(salarySlipDetails?.month - 1).format('MMMM')}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000' }}>{t('salary_slip.salary_slip_year')}</Typography>
                                    <Typography>{salarySlipDetails?.year}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000', mb: .5 }}>{t('common.assigned_users')}</Typography>
                                    <Stack direction={'row'} sx={{ gap: 1, alignItems: 'center' }}>
                                        <Avatar sx={{ backgroundColor: 'primary.main', width: 30, height: 30, textTransform: "capitalize", fontSize: 15 }}>
                                            {salarySlipDetails?.user?.first_name[0]}
                                        </Avatar>
                                        <Typography>{salarySlipDetails?.user?.first_name} {salarySlipDetails?.user?.last_name}</Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                            {
                                uploadingItems.length > 0 &&
                                <Grid item xs={12} md={12}>
                                    <Box>
                                        <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000' }}>{t('common.documents')}</Typography>
                                        <Box sx={{ mt: 1 }}>
                                            <Box>
                                                <Grid container spacing={1}>
                                                    {
                                                        uploadingItems.map((doc) => (
                                                            <Grid item key={doc.id} xs={12} sm={6} md={4} xl={2.5} >
                                                                <Box sx={{ overflow: 'hidden', backgroundColor: 'background.light', position: 'relative', textDecoration: 'none', color: 'inherit' }} component={'a'} href={`${doc.view_file_url}`} target='_blank' >
                                                                    <Box sx={{ height: '120px', position: 'relative', cursor: 'pointer', '&:hover .show-icon': { opacity: 1 }, borderRadius: '5px 5px 0px 0px', overflow: 'hidden' }} >
                                                                        <Avatar sx={{ height: '100%', width: '100%', borderRadius: 0, objectFit: 'cover', backgroundColor: "primary.main", boxSizing: "border-box", fontSize: '16px' }}><InsertDriveFileOutlinedIcon sx={{ fontSize: 30, color: '#fff' }} /></Avatar>
                                                                    </Box>
                                                                    <Box sx={{ py: .6, px: .8, backgroundColor: 'background.light', borderRadius: '0px 0px 5px 5px' }}>
                                                                        <Typography sx={{ display: '-webkit-box', WebkitLineClamp: 1, overflow: 'hidden', textOverflow: 'ellipsis', WebkitBoxOrient: 'vertical', fontSize: '13px' }}>{doc.name}</Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                        ))
                                                    }
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Box>
            </ModalBox >
        </Box>
    )
}

export default SalarySlip

