import { Box, Button, Typography, TextField as MuiTextField, Stack, Tooltip, TableContainer, Chip, InputAdornment, Table, TableHead, TableCell, TableRow, TableBody, TableFooter, TablePagination, Grid, Paper, Avatar, MenuItem, ListItemIcon, ListItemText, Menu, IconButton, Pagination, useMediaQuery, Checkbox, Autocomplete, FormControlLabel, ListItem, FormControl, InputLabel, CircularProgress, AvatarGroup, List, ListItemAvatar } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModalBox from '../../components/ModalBox';
import Popup from '../../components/Popup';
import { Formik, Form, Field } from "formik";
import { Close } from '@mui/icons-material';
import { Select, TextField } from 'formik-mui';
import * as Yup from 'yup';
import { alertMsg } from '../../utils/basicUtils';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import useAuth from '../../hooks/use-auth';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DropZone from '../../components/DropZone';
import useAxiosPrivate from '../../hooks/use-axios-private';
import Empty from '../../components/Empty';
import Preloader from '../../components/Preloader';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import CustomAvatarGroup from '../../components/CustomAvatarGroup';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { useTranslation } from 'react-i18next';

const UserAction = ({ getContractsInfo, setSelectedContract, file, setOpen, setIsOpenInfoDetails, setShowDeleteModal }) => {

    const [isActionsOpen, setIsActionsOpen] = useState(false);
    const { t } = useTranslation()
    const handleActions = (event) => {
        setIsActionsOpen(event.currentTarget);
    };
    const { user } = useAuth()


    return (
        <Box>
            <IconButton size='small' onClick={handleActions} >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={isActionsOpen}
                open={Boolean(isActionsOpen)}
                onClose={() => setIsActionsOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {user.role_id === 1 &&
                    <MenuItem onClick={async () => {
                        await getContractsInfo(file.id, 'edit');
                        setOpen(true)
                        setIsActionsOpen(false)
                    }}>
                        <ListItemIcon sx={{ minWidth: 30 }}>
                            <EditOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('common.edit')}</ListItemText>
                    </MenuItem>
                }
                {user.role_id === 1 &&
                    <MenuItem onClick={async () => {
                        await getContractsInfo(file.id, 'view');
                        setIsOpenInfoDetails(true);
                        setIsActionsOpen(false);
                        setSelectedContract(file)
                    }}>
                        <ListItemIcon sx={{ minWidth: 30 }}>
                            <InfoOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('common.info')}</ListItemText>
                    </MenuItem>
                }
                <MenuItem onClick={() => { window.open(`${file?.documents[0]?.view_file_url}`, "_blank"); setIsActionsOpen(false); }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                        <VisibilityIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('common.view')}</ListItemText>
                </MenuItem>
                <MenuItem download component={'a'} href={file?.documents[0]?.download_file_url} color='success' onClick={() => { setIsActionsOpen(false) }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                        <CloudDownloadOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('common.download')}</ListItemText>
                </MenuItem>
                {user.role_id === 1 &&
                    <MenuItem onClick={() => { setShowDeleteModal(true); setIsActionsOpen(false); setSelectedContract(file) }}>
                        <ListItemIcon sx={{ minWidth: 30 }}>
                            <DeleteOutlineOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('common.delete')}</ListItemText>
                    </MenuItem>
                }
            </Menu >
        </Box >
    )
}

const ContractsList = ({ contractTab }) => {
    const [contractLst, setContractLst] = useState([])
    const [selectedContract, setSelectedContract] = useState({})
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [pageInfo, setPageInfo] = useState({})
    const [open, setOpen] = useState(false)
    const [isGridView, setIsGridView] = useState(false)
    const isTabView = useMediaQuery("(max-width: 900px)")
    const [isOpenInfoDetails, setIsOpenInfoDetails] = useState(false)
    const [uploading, setUploading] = useState({ load: false, upload: false, delete: false })
    const [openUserMenu, setOpenUserMenu] = useState(false)
    const initialContractValues = {
        "contract_id": '',
        "contract_name": "",
        "company_id": '',
        "user_ids": [],
    }
    const [contractDetails, setContractDetails] = useState(initialContractValues)
    const contractIdRef = useRef()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [ShowDeleteModal, setShowDeleteModal] = useState(false)
    const [companyLst, setCompanyLst] = useState([])
    const { user } = useAuth()
    const [searchText, setSearchText] = useState('')
    const [userLst, setUserLst] = useState([])
    const [selectedUserLst, setSelectedUserLst] = useState([])
    const [uploadedItems, setUploadedItems] = useState([])
    const [filterByCompany, setFilterByCompany] = useState(null)
    const [filterByUser, setFilterByUser] = useState(null)
    const axios = useAxiosPrivate()
    const [viewUsersLst, setViewUsersLst] = useState({ open: false, users: [] })
    const { t } = useTranslation()


    const getCompanyList = async () => {
        try {
            const response = await axios.get(`users/company_list/`, {
                params: {
                    has_users: true
                }
            })
            setCompanyLst([...response.data.data.companies])

        } catch (err) {
            console.error(err)
        }
    }

    const getContractLst = async () => {
        try {
            setUploading((prev) => ({ ...prev, load: true }))
            const response = await axios.get('contracts/list/', {
                params: {
                    limit: rowsPerPage,
                    page: page + 1,
                    search: searchText,
                    role_id: user.role_id === 1 || user.role_id === 2 ? contractTab + 3 : Number(user.role_id),
                    company_id: filterByCompany?.id,
                    user_id: filterByUser?.id,
                }
            })
            setContractLst(response.data.data.contracts)
            setPageInfo(response.data.data.page_info)
            setUploading((prev) => ({ ...prev, load: false }))
        } catch (err) {
            setUploading((prev) => ({ ...prev, load: false }))
            console.error(err)
        }
    }

    const getContractsInfo = async (id, mode) => {
        try {
            const response = await axios.get(`contracts/${id}`)
            setContractDetails({ ...response.data.data.contract })
            if (mode === 'edit') {
                contractIdRef.current = response.data.data.contract.contract_id
                setSelectedUserLst(response.data.data.contract.users)
                if (contractTab == 0) {
                    getContractUserLst(response.data.data.contract.company.id)
                }
                setUploadedItems(response.data.data.contract.documents)
            }
        } catch (err) {
            console.error(err)
        }
    }

    const handleDeleteContract = async (id) => {
        try {
            const response = await axios.delete(`contracts/${id}`)
            alertMsg(response.data.message, 'success')
            getContractLst()
        } catch (err) {
            console.error(err)
        }
    }

    const handleDeleteDocument = async (id, fileid) => {
        try {
            setUploading((prev) => ({ ...prev, delete: true }))
            const response = await axios.post(`contracts/delete_doc/${id}`, {
                file_id: fileid
            })
            setUploadedItems([])
            setUploading((prev) => ({ ...prev, delete: false }))
            alertMsg(response.data.message, 'success')
        } catch (err) {
            setUploading((prev) => ({ ...prev, delete: false }))
            console.error(err)
        }
    }

    const getContractUserLst = async (id) => {
        try {
            if (contractTab === 0) {
                const response = await axios.get(`users/company/${id}`)
                const { users } = response.data.data
                let contractUser = users.map(user => ({ 'id': user.id, 'first_name': user.first_name, 'last_name': user.last_name, 'role_id': user.role_id }))
                setUserLst(contractUser)
            } else {
                const response = await axios.get(`users/list/`, {
                    params: {
                        role_id: contractTab + 3,
                        limit: 0,
                    }
                })
                setUserLst(response.data.data.users)
            }
        } catch (err) {
            console.error(err)
        }
    }

    const handleToggleOption = (index) => {
        setSelectedUserLst(prev => {
            const selected = prev.find(selectedUser => selectedUser.id === userLst[index].id)
            if (selected) {
                const indexValue = prev.indexOf(selected)
                prev.splice(indexValue, 1)
            }
            else {
                prev.push(userLst[index])
            }
            return [...prev]
        })

    };

    const fileUpload = async (e, id) => {
        const { files } = e.target
        if (files[0]) {
            const formData = new FormData()
            formData.append("file", files[0])
            formData.append("contract_id", id)
            getContactId(formData)
            e.target.value = null;
        }
    }

    const getContactId = async (data) => {
        try {
            setIsSubmitting(true)
            if (!data) {
                const response = await axios.post('contracts/upload_doc/')
                let { contract_id } = response.data.data.contract_files
                contractIdRef.current = contract_id
                // setContractDetails(prev => ({ ...prev, "contract_id": contract_id }))
            }
            else {
                setUploading((prev) => ({ ...prev, upload: true }))
                const response = await axios.post('contracts/upload_doc/', data, {
                    headers: { "Content-Type": "multipart/form-data", },
                })
                let { documents } = response.data.data.contract_files
                setUploadedItems(documents)
                alertMsg(response.data.message, 'success')
                setUploading((prev) => ({ ...prev, upload: false }))
            }
            setIsSubmitting(false)
        }
        catch (e) {
            setIsSubmitting(false)
            setUploading((prev) => ({ ...prev, upload: false }))
            console.error("🚀  file: fileUpload.jsx:89 ", e)
            return false
        }
    }


    useEffect(() => {
        if (isTabView) {
            setIsGridView(true);
        }
        else {
            setIsGridView(false);
        }
    }, [isTabView])


    useEffect(() => {
        getContractLst()
    }, [page, rowsPerPage, searchText, filterByCompany, filterByUser])

    useEffect(() => {
        if (contractTab === 1 && (user.role_id === 2 || user.role_id === 1)) {
            getContractUserLst()
        }
    }, [contractTab])

    useEffect(() => {
        if (contractTab === 0 && (user.role_id === 1 || user.role_id === 2)) {
            getCompanyList()
        }
    }, [])


    return (
        <Box>
            <Box sx={{ display: { xs: 'block', sm: "flex" }, gap: 1, flexWrap: "wrap", alignItems: 'center', justifyContent: 'space-between' }}>

                <Box>
                    <MuiTextField
                        fullWidth
                        sx={{ width: "100%", backgroundColor: '#f3f3f3', "& fieldset": { border: "0 !important" } }}
                        placeholder={t('common.search')}
                        size={'small'}
                        value={searchText}
                        onChange={(e) => { setSearchText(e.target.value) }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ ml: 1.2, fontSize: 20 }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Stack sx={{ mt: { xs: 1, md: 0 }, flexDirection: 'row', gap: 1, alignItems: 'center', flexWrap: 'wrap' }}>
                    {
                        (contractTab === 0 && (user.role_id === 1 || user.role_id === 2)) &&
                        <Box sx={{ minWidth: 250 }}>
                            <Autocomplete
                                isOptionEqualToValue={(option, value) => (option?.id === value?.id)}
                                onChange={async (event, newValue) => {
                                    setFilterByCompany(newValue)
                                    setFilterByUser(null)
                                    getContractUserLst(newValue?.id)
                                }}
                                value={filterByCompany}
                                disableClearable
                                sx={{ flexGrow: 1 }}
                                getOptionLabel={option => option?.company_name}
                                options={companyLst}
                                renderInput={(params) => (
                                    <MuiTextField
                                        name="filter_by_company"
                                        {...params}
                                        label={t('common.filter_company')}
                                    />
                                )}
                            />
                        </Box>
                    }
                    {
                        (user.role_id === 1 || user.role_id === 2) &&
                        <Box sx={{ minWidth: 250 }}>
                            <Autocomplete
                                isOptionEqualToValue={(option, value) => (option?.id === value?.id)}
                                onChange={(event, newValue) => {
                                    setFilterByUser(newValue)
                                }}
                                disabled={Boolean(!filterByCompany?.id && contractTab == 0)}
                                value={filterByUser}
                                disableClearable
                                sx={{ flexGrow: 1 }}
                                getOptionLabel={option => `${option?.first_name} ${option?.last_name}`}
                                options={userLst}
                                // disabled={contractTab === 0 && !filterByCompany}
                                renderInput={(params) => (
                                    <MuiTextField
                                        name="filter_by_user"
                                        {...params}
                                        label={contractTab == 0 ? t('common.filter_customer') : t('common.filter_employee')}
                                    />
                                )}
                            />
                        </Box>

                    }
                    {
                        (filterByCompany || filterByUser) &&
                        <Box>
                            <Tooltip title={t('common.reset_filter')} placement='top'>
                                <Button sx={{ height: '31px', width: '31px', p: '5px ', minWidth: 'auto' }} variant='outlined' onClick={() => {
                                    setFilterByCompany(null)
                                    setFilterByUser(null)
                                }}>
                                    <FilterAltOffOutlinedIcon />
                                </Button>
                            </Tooltip>
                        </Box>
                    }

                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Button sx={{ height: '31px', width: '31px', p: '5px ', minWidth: 'auto' }} variant='outlined' onClick={() => setIsGridView(prev => !prev)}>
                            {isGridView ?
                                <ListOutlinedIcon sx={{ fontSize: 20 }} /> : <GridViewOutlinedIcon sx={{ fontSize: 20 }} />
                            }
                        </Button>
                    </Box>
                    {
                        (user.role_id === 1) &&
                        <Box>
                            <Button sx={{ minWidth: 'auto' }} variant='outlined' onClick={() => {
                                setContractDetails(initialContractValues)
                                setSelectedUserLst([])
                                setUploadedItems([])
                                getContactId()
                                setOpen(true)
                            }} startIcon={<CloudUploadOutlinedIcon sx={{ fontSize: 20 }} />}>
                                {t('common.upload')}
                            </Button>
                        </Box>
                    }
                </Stack>
            </Box>
            {
                uploading.load ?
                    <Box sx={{ mt: 1, minHeight: '64vh', display: 'flex', alignItems: 'center', backgroundColor: '#ffff', border: '2px solid', borderRadius: 1, borderColor: "background.light" }}>
                        <Preloader preload={false} />
                    </Box>
                    :
                    <Box sx={{ mt: 1 }}>
                        {
                            contractLst.length > 0 ? <>
                                {
                                    !isGridView ?
                                        <TableContainer>
                                            <Table stickyHeader size='small' >
                                                <TableHead>
                                                    <TableRow sx={{ '& th': { whiteSpace: 'nowrap', backgroundColor: 'background.card', p: 1.5, border: 0, borderBottom: '1px solid #ebf1fc', } }}>
                                                        <TableCell sx={{ minWidth: { xs: 180, xl: 'auto' } }}>
                                                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'start' }}>
                                                                <Box sx={{ height: 20 }}>
                                                                    <InsertDriveFileOutlinedIcon sx={{ fontSize: 20 }} />
                                                                </Box>
                                                                <Box>
                                                                    <Typography sx={{ maxLines: 1, fontWeight: 700 }} variant="body1">{t('contract.contract_name')}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                        {
                                                            (contractTab === 0 && (user.role_id == 1 || user.role_id == 2)) &&
                                                            <TableCell sx={{ minWidth: 180 }} align={'center'}><Typography sx={{ fontWeight: 700 }}>{t('contract.company_name')}</Typography></TableCell>
                                                        }
                                                        {
                                                            contractTab === 0 &&
                                                            <TableCell align={'center'} ><Typography sx={{ fontWeight: 700 }}>{(user.role_id == 1 || user.role_id == 2) ? t('contract.contract_customer') : t('contract.contract_users')}</Typography></TableCell>
                                                        }
                                                        {
                                                            (contractTab === 1 && (user.role_id == 1 || user.role_id == 2)) &&
                                                            <TableCell sx={{ minWidth: 180 }} align={'center'}><Typography sx={{ fontWeight: 700 }}>{t('common.first_name')}</Typography></TableCell>
                                                        }
                                                        {
                                                            (contractTab === 1 && (user.role_id == 1 || user.role_id == 2)) &&
                                                            <TableCell sx={{ minWidth: 180 }} align={'center'}><Typography sx={{ fontWeight: 700 }}>{t('common.last_name')}</Typography></TableCell>
                                                        }
                                                        <TableCell align={'center'} sx={{ width: 300 }}><Typography sx={{ fontWeight: 700 }}>{t('common.action')}</Typography></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        contractLst.map((file, index) => (
                                                            <TableRow key={index} sx={{ '& td': { px: 0, borderBottom: '1px solid #ebf1fc', py: "2px" }, '& td>div': { px: "12px", py: '2px', backgroundColor: 'background.card', minHeight: 50, }, }}>
                                                                <TableCell sx={{ minWidth: { xs: 300, sm: 'auto' } }}>
                                                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'start' }}>
                                                                        <Box sx={{ height: 20 }}>
                                                                            <InsertDriveFileOutlinedIcon sx={{ fontSize: 20 }} />
                                                                        </Box>
                                                                        <Box>
                                                                            <Tooltip title={file.contract_name} arrow placement='top'>
                                                                                <Typography sx={{ maxLines: 1 }} variant="body1">{file.contract_name}</Typography>
                                                                            </Tooltip>
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                                {
                                                                    (contractTab === 0 && (user.role_id == 1 || user.role_id == 2)) &&
                                                                    <TableCell align={'center'} sx={{ minWidth: 180 }}>
                                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                                                            <Typography variant="body1">{file.company.company_name}</Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                }
                                                                {
                                                                    contractTab === 0 &&
                                                                    <TableCell align={'center'} sx={{ minWidth: 180 }}>
                                                                        <CustomAvatarGroup users={file.users} open={() => { setViewUsersLst((prev) => ({ users: file.users, open: true })) }} limit={4} />
                                                                    </TableCell>
                                                                }
                                                                {
                                                                    (contractTab === 1 && (user.role_id == 1 || user.role_id == 2)) &&
                                                                    <TableCell sx={{ minWidth: 180 }} align={'center'}><Typography >{file.users[0].first_name}</Typography></TableCell>
                                                                }
                                                                {
                                                                    (contractTab === 1 && (user.role_id == 1 || user.role_id == 2)) &&
                                                                    <TableCell sx={{ minWidth: 180 }} align={'center'}><Typography >{file.users[0].last_name ? file.users[0].last_name : '-'}</Typography></TableCell>
                                                                }
                                                                <TableCell align={'center'} sx={{ width: 300 }}>
                                                                    <Stack direction={'row'} sx={{ justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                                                                        {user.role_id === 1 &&
                                                                            <Box>
                                                                                <Tooltip title={t('common.edit')} arrow placement='top'>
                                                                                    <IconButton size='small' color='success'
                                                                                        onClick={
                                                                                            async () => {
                                                                                                await getContractsInfo(file.id, 'edit');
                                                                                                setOpen(true)
                                                                                                // setSelectedUserLst([])
                                                                                            }}>
                                                                                        <EditOutlinedIcon sx={{ fontSize: "22px" }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Box>
                                                                        }
                                                                        {user.role_id === 1 &&
                                                                            <Box>
                                                                                <Tooltip title={t('common.info')} arrow placement='top'>
                                                                                    <IconButton size='small' color='info'
                                                                                        onClick={
                                                                                            async () => {
                                                                                                await getContractsInfo(file.id, 'view');
                                                                                                setIsOpenInfoDetails(true)
                                                                                            }}
                                                                                    >
                                                                                        <InfoOutlinedIcon sx={{ fontSize: "22px" }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Box>
                                                                        }
                                                                        <Box>
                                                                            <Tooltip title={t('common.view')} arrow placement='top'>
                                                                                <IconButton size='small' onClick={() => {
                                                                                    window.open(`${file?.documents[0]?.view_file_url}`, "_blank");
                                                                                }} color='warning'>
                                                                                    <VisibilityIcon sx={{ fontSize: "22px" }} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Box>
                                                                        <Box>
                                                                            <Tooltip title={t('common.download')} arrow placement='top'>
                                                                                <IconButton size='small' download component={'a'} href={file?.documents[0]?.download_file_url} color='success'>
                                                                                    <CloudDownloadOutlinedIcon sx={{ fontSize: "22px" }} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Box>
                                                                        {user.role_id === 1 &&
                                                                            <Box>
                                                                                <Tooltip title={t('common.delete')} arrow placement='top'>
                                                                                    <IconButton size='small' color='error' onClick={() => { setShowDeleteModal(true); setSelectedContract(file) }}>
                                                                                        <DeleteOutlineOutlinedIcon sx={{ fontSize: "22px" }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Box>
                                                                        }
                                                                    </Stack>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                                <TableFooter sx={{ backgroundColor: 'background.card', p: 2, borderRadius: '5px' }}>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[10, 25, 50, 100]}
                                                            colSpan={10}
                                                            sx={{ borderBottom: 0, fontSize: 12, px: 0, "& .MuiInputBase-root": { width: "50px !important", mr: 1.3 }, "& input": { visibility: "hidden" }, "& p": { fontSize: 12 }, "& .MuiTablePagination-toolbar": { minHeight: "0px !important" }, "& .MuiSvgIcon-fontSizeMedium": { fontSize: 15 } }}
                                                            count={pageInfo.count}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            onPageChange={(e, newPage) => { setPage(newPage) }}
                                                            onRowsPerPageChange={(e) => { setRowsPerPage(parseInt(e.target.value), 10); setPage(0); }}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </TableContainer>
                                        :
                                        <Stack sx={{ gap: 1, justifyContent: 'space-between', minHeight: '71vh', }}>
                                            <Box sx={{ maxHeight: { xs: 'calc(100vh - 300px)', md: 'calc(100vh - 180px)' }, overflow: 'auto', }} className='scroll-bar'>
                                                <Grid container spacing={1.5} sx={{ pb: 1 }}>
                                                    {
                                                        contractLst.map((file, index) => (
                                                            <Grid item xs={12} md={6} key={index}>
                                                                <Paper sx={{ p: 1, backgroundColor: 'background.card' }}>
                                                                    <Box>
                                                                        <Stack direction={'row'} sx={{ gap: 1, alignItems: 'center' }}>
                                                                            <Avatar sx={{ height: 65, width: 65, borderRadius: 1, backgroundColor: 'background.lightSecondary' }}>
                                                                                <InsertDriveFileOutlinedIcon sx={{ fontSize: 30, color: 'primary.main' }} />
                                                                            </Avatar>
                                                                            <Box sx={{ flexGrow: 1 }}>
                                                                                <Typography sx={{ maxLines: 1, mb: { xs: .5, sm: 1 } }} variant="body1">{file.contract_name}</Typography>
                                                                                {
                                                                                    contractTab == 0 &&
                                                                                    <Typography sx={{ color: '#a1a1a1' }} variant="body2">{file.company.company_name}</Typography>
                                                                                }
                                                                                {
                                                                                    contractTab == 1 &&
                                                                                    <Typography sx={{ color: '#a1a1a1' }} variant="body2">{file.users[0].first_name} {file.users[0].last_name}</Typography>
                                                                                }
                                                                            </Box>
                                                                            <UserAction
                                                                                setSelectedContract={setSelectedContract}
                                                                                setIsOpenInfoDetails={setIsOpenInfoDetails}
                                                                                setOpen={setOpen}
                                                                                setShowDeleteModal={setShowDeleteModal}
                                                                                file={file}
                                                                                getContractsInfo={getContractsInfo}
                                                                            />
                                                                        </Stack>
                                                                    </Box>
                                                                </Paper>
                                                            </Grid>
                                                        ))
                                                    }
                                                </Grid>
                                            </Box>
                                            <Stack sx={{ alignItems: 'center', py: 2 }}>
                                                <Pagination
                                                    variant="outlined"
                                                    color='primary'
                                                    count={pageInfo.num_pages}
                                                    page={page + 1}
                                                    onChange={(e, newPage) => { setPage(newPage - 1) }}
                                                />
                                            </Stack>
                                        </Stack>
                                }
                            </>
                                : <>
                                    <Empty text={t('contract.empty_text')} icon={<InsertDriveFileOutlinedIcon sx={{ fontSize: 30 }} />} sx={{ minHeight: '56vh' }} />
                                </>
                        }
                    </Box>
            }
            <Popup show={ShowDeleteModal} title={`${t('common.delete')} ${contractTab === 0 ? t('contract.customer') : t('contract.employee')} ${t('menu.contract')}`} consent={true} variant='error' primaryBtnTxt={t("common.delete")}
                onPrimaryClick={async (e, loader) => {
                    handleDeleteContract(selectedContract?.id);
                    setShowDeleteModal(false);
                }}
                onSecondaryClick={() => { setShowDeleteModal(false); }} >
                <Box sx={{ textAlign: "center" }}>
                    <Typography color='text.secondary' >{t('contract.delete_text')} <span style={{ fontWeight: 600, color: "#000" }}>
                        {selectedContract?.contract_name}
                    </span>. {t('common.delete_confirm_text')}</Typography>
                </Box>
            </Popup>
            <ModalBox
                open={open}
                icon={<FileUploadOutlinedIcon color='primary' />}
                title={contractTab === 0 ? t('contract.customer_contract') : t('contract.employee_contract')}
                closeBtn
                onClose={(e, reason) => {
                    if (reason) return;

                    if (isSubmitting) {
                        alertMsg(t('common.upload_progress_alert'), 'warning')
                        return
                    }

                    if (contractDetails.id && uploadedItems.length === 0) {
                        alertMsg(t('common.document_required_alert'), 'warning')
                        return
                    }

                    if (contractDetails.id && contractDetails.documents !== uploadedItems) {
                        alertMsg(t('common.save_alert'), 'warning')
                    }
                    else {
                        setOpen(false)
                    }
                }}
                sx={{ width: { xs: "100%", sm: "80% !important", md: "70% !important", lg: "45% !important" }, maxWidth: { xs: "95%", sm: "80%", md: "70%", lg: "45%" }, p: { xs: .8, md: 0 } }}>
                <Box>
                    <Box sx={{ mt: 1.5 }}>
                        <Formik
                            initialValues={contractDetails}
                            validationSchema={
                                Yup.object().shape({
                                    contract_name: Yup.string()
                                        .required(t("contract.contract_name_required")),
                                    // company:
                                    //     contractTab == 0 &&
                                    //     Yup.object().shape({
                                    //         id: Yup.number().required('Company  is required'),
                                    //     })
                                })
                            }
                            onSubmit={async (values, { setSubmitting }) => {
                                if (uploadedItems.length === 0) {
                                    alertMsg(t('common.document_required_alert'), 'warning')
                                    return
                                }
                                try {
                                    if (contractDetails.id) {
                                        const contractData = {
                                            ...values,
                                            "company_id": contractTab === 0 ? values.company.id : 0,
                                            'user_ids': selectedUserLst.map(user => {
                                                return user.id
                                            }),
                                            'contract_id': contractIdRef.current,
                                            'role_id': contractTab + 3
                                        }
                                        const response = await axios.put(`contracts/${contractData.id}`, contractData)
                                        alertMsg(response.data.message, "success")
                                    }
                                    else {
                                        const contractData = {
                                            ...values,
                                            "company_id": contractTab === 0 ? values.company_id : 0,
                                            'user_ids': selectedUserLst.map(user => {
                                                return user.id
                                            }),
                                            'contract_id': contractIdRef.current,
                                            'role_id': contractTab + 3
                                        }
                                        const response = await axios.post('contracts/create/', contractData)
                                        alertMsg(response.data.message, "success")
                                    }

                                    getContractLst()
                                    setUploadedItems({})
                                    setOpen(false)
                                }
                                catch (err) {
                                    console.error(err)
                                    setOpen(true)
                                }
                                setSubmitting(false);
                            }} >
                            {({ setFieldValue, values, setFieldTouched, errors, touched }) => (
                                <Form>
                                    <Grid container spacing={{ xs: 2, }}>
                                        <Grid item xs={12}>
                                            <Box>
                                                <Field
                                                    component={TextField}
                                                    name="contract_name"
                                                    label={`${t('contract.contract_name')} *`}
                                                    fullWidth />
                                            </Box>
                                        </Grid>
                                        {
                                            contractTab === 0 &&
                                            <Grid item xs={12} >
                                                <Box>
                                                    <Autocomplete
                                                        freeSolo
                                                        isOptionEqualToValue={(option, value) => (option?.id === value?.id)}
                                                        defaultValue={contractDetails.company}
                                                        autoHighlight={true}
                                                        onChange={(event, newValue) => {
                                                            setFieldValue('company', newValue)
                                                            setFieldValue('company_id', newValue?.id)
                                                            setSelectedUserLst([])
                                                            if (newValue?.id) {
                                                                getContractUserLst(newValue?.id)
                                                            }
                                                        }}
                                                        sx={{ flexGrow: 1 }}
                                                        getOptionLabel={option => option.company_name}
                                                        options={companyLst}
                                                        renderInput={(params) => (
                                                            <Field
                                                                component={TextField}
                                                                {...params}
                                                                name="company_name"
                                                                label={`${t('contract.company_name')} *`}
                                                            />
                                                        )}
                                                    />
                                                </Box>
                                            </Grid>
                                        }
                                        {
                                            contractTab === 0 ?
                                                <Grid item xs={12}>
                                                    <Box>
                                                        <Autocomplete
                                                            multiple
                                                            open={openUserMenu}
                                                            onOpen={() => { setOpenUserMenu(true) }}
                                                            onClose={() => { setOpenUserMenu(false) }}
                                                            autoHighlight={true}
                                                            sx={{ flexGrow: 1, '.MuiChip-deleteIcon': { display: 'none' } }}
                                                            getOptionLabel={option => `${option.first_name} ${option.last_name}`}
                                                            disableCloseOnSelect
                                                            disableClearable
                                                            isOptionEqualToValue={(option, value) => (option?.id === value?.id)}
                                                            disabled={contractTab === 0 && !values.company_id && !contractDetails.id}
                                                            limitTags={4}
                                                            value={selectedUserLst}
                                                            options={userLst}
                                                            renderOption={(props, option, { selected, index }) => {
                                                                const { key, ...optionProps } = props;
                                                                return (
                                                                    <ListItem key={key} {...optionProps}
                                                                        sx={{
                                                                            backgroundColor: selectedUserLst.find(selectedUser => selectedUser.id === userLst[index].id) ? 'background.light' : 'transparent',
                                                                            py: .4
                                                                        }}
                                                                        onClick={() => handleToggleOption(index)}>
                                                                        <Box component={'label'}>
                                                                            <Checkbox
                                                                                sx={{ padding: "4px" }}
                                                                                size='small'
                                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                                checkedIcon={<CheckBoxIcon />}
                                                                                style={{ marginRight: 6 }}
                                                                                // checked={selectedUserLst.includes(userLst[index])}
                                                                                checked={selectedUserLst.find(selectedUser => selectedUser.id === userLst[index].id)}
                                                                            />
                                                                            {`${option.first_name} ${option.last_name}`}
                                                                        </Box>
                                                                    </ListItem>
                                                                );
                                                            }}

                                                            ListboxComponent={(props) => {
                                                                return (
                                                                    <ul {...props}>
                                                                        <li>
                                                                            <Stack sx={{ px: 1, mb: 1, flexDirection: 'row', justifyContent: 'space-between', gap: 1 }}>
                                                                                <Button sx={{ fontSize: 12 }} size='small' variant='outlined'
                                                                                    onClick={() => {
                                                                                        setSelectedUserLst(prev => {
                                                                                            if (prev.length === userLst.length) {
                                                                                                prev = []
                                                                                            }
                                                                                            else {
                                                                                                prev = [...userLst]
                                                                                            }
                                                                                            return [...prev]
                                                                                        })
                                                                                    }}

                                                                                >{selectedUserLst.length === userLst.length ? t('common.unselect_all') : t('common.select_all')}</Button>
                                                                                <IconButton onClick={() => { setOpenUserMenu(false) }}>
                                                                                    <Close />
                                                                                </IconButton>
                                                                            </Stack>
                                                                        </li>
                                                                        {props.children}
                                                                    </ul>
                                                                )
                                                            }
                                                            }
                                                            renderInput={(params) => (
                                                                <Field
                                                                    component={TextField}
                                                                    name="users"
                                                                    {...params}
                                                                    label={`${t('contract.add_customers')} *`}
                                                                />
                                                            )}
                                                        />
                                                    </Box>
                                                </Grid>
                                                :
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        options={userLst}
                                                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                                        getOptionLabel={option => `${option.first_name} ${option.last_name}`}
                                                        value={selectedUserLst[0]}
                                                        renderInput={(params) => (
                                                            <Field
                                                                component={TextField}
                                                                name="users"
                                                                {...params}
                                                                label={`${t('contract.add_employee')} *`}
                                                            />
                                                        )}
                                                        onChange={(event, newValue) => {
                                                            // setFieldValue('user_ids', newValue)
                                                            setSelectedUserLst([newValue])
                                                        }}
                                                    />
                                                </Grid>
                                        }
                                    </Grid>
                                    <Box sx={{ mt: 2 }}>


                                        <DropZone
                                            getDropped={(e) => {
                                                fileUpload(e, contractIdRef.current);
                                                e.target.files = null
                                            }}
                                            formats={[".pdf"]}
                                            disabled={uploading.upload}
                                        >
                                            <Box component={"label"} sx={{ cursor: 'pointer', position: 'relative', border: '1.5px dashed #d3d3d3', p: 1, minHeight: { xs: 200, sm: 250 }, borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', pointerEvents: uploading.upload ? 'none' : 'auto' }} className="scroll-bar">
                                                <input disabled={uploading.upload} hidden accept={".pdf"} type="file" multiple onChange={e => { fileUpload(e, contractIdRef.current); e.target.files = null }} />
                                                <Box>
                                                    <Box sx={{ backgroundColor: 'background.success', width: 80, height: 80, borderRadius: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <CollectionsOutlinedIcon sx={{ fontSize: '40px' }} color='success' />
                                                    </Box>
                                                </Box>
                                                <Box sx={{ mt: 1, textAlign: 'center' }}>
                                                    <Typography component={'span'} sx={{ fontSize: { xs: 14, sm: 16, md: '18px' } }}>{t('common.upload_text')} <Typography component={'span'} sx={{ color: 'info.main', fontSize: { xs: 14, sm: 16, md: '18px' } }}>{t('common.browse_text')}</Typography></Typography>
                                                </Box>
                                                <Box sx={{ mt: .5 }}>
                                                    <Typography sx={{ fontSize: '13px', color: 'grey', fontWeight: 400 }}>{t('common.support_text')}: PDF</Typography>
                                                </Box>
                                                {
                                                    uploading.upload && <Box sx={{ position: 'absolute', inset: 0, backgroundColor: '#ffffff87', display: 'flex', alignItems: 'center', justifyContent: 'center', backdropFilter: 'blur(4px)', flexDirection: 'column', gap: 1 }}>
                                                        <CircularProgress />
                                                        <Typography variant="body1" color="initial"> {t('common.uploading')}... </Typography>
                                                    </Box>
                                                }
                                            </Box>
                                        </DropZone>
                                    </Box>
                                    {
                                        uploadedItems.length > 0 &&
                                        <>
                                            <Box sx={{ mt: 2 }}>
                                                <Grid container spacing={1} sx={{ pb: 2 }}>
                                                    {
                                                        uploadedItems.map((doc) => (
                                                            <Grid key={doc.id} item xs={12} sm={6} md={4} lg={3} xl={2} >
                                                                <Box sx={{ overflow: 'hidden', backgroundColor: 'background.light', position: 'relative', borderRadius: '5px' }}>
                                                                    <Box component={'a'} href={`${doc.view_file_url}`} sx={{ textDecoration: 'none', color: 'inherit' }} target='_blank'>
                                                                        <Box sx={{ height: '120px', position: 'relative', cursor: 'pointer', '&:hover .show-icon': { opacity: 1 }, overflow: 'hidden' }}>
                                                                            <Avatar sx={{ height: '100%', width: '100%', borderRadius: 0, objectFit: 'cover', backgroundColor: "primary.main", boxSizing: "border-box", fontSize: '16px' }}>
                                                                                {
                                                                                    !uploading.delete ?
                                                                                        <InsertDriveFileOutlinedIcon sx={{ fontSize: 30, color: '#fff' }} />
                                                                                        :
                                                                                        <CircularProgress sx={{ color: '#FEF3E2', opacity: .8 }} />
                                                                                }
                                                                            </Avatar>
                                                                        </Box>
                                                                        <Box sx={{ py: .6, px: .8, backgroundColor: 'background.light' }}>
                                                                            <Typography sx={{ display: '-webkit-box', WebkitLineClamp: 1, overflow: 'hidden', textOverflow: 'ellipsis', WebkitBoxOrient: 'vertical', fontSize: '13px' }}>
                                                                                {!uploading.delete ? doc.name : `${t('common.deleting')}...`}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    {
                                                                        contractDetails.id &&
                                                                        <IconButton onClick={(e) => { e.preventDefault(); handleDeleteDocument(contractDetails.contract_id, doc.file_id) }} sx={{ zIndex: 10, backgroundColor: "error.main", '&:hover': { backgroundColor: "error.main", }, color: "#FFF !important", position: "absolute", top: 0, left: 0, borderRadius: "0 0 4px 0", p: .5 }}>
                                                                            <DeleteOutlineOutlinedIcon sx={{ fontSize: 16 }} />
                                                                        </IconButton>
                                                                    }

                                                                </Box>
                                                            </Grid>
                                                        ))
                                                    }


                                                </Grid>
                                            </Box>
                                        </>
                                    }
                                    <Box sx={{ textAlign: 'right', mt: 2 }}>
                                        <Box>
                                            <Button type="submit" variant="contained" disabled={isSubmitting}>{t('common.save')}</Button>
                                        </Box>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </ModalBox >
            <ModalBox
                open={isOpenInfoDetails}
                onClose={() => { setIsOpenInfoDetails(false) }}
                icon={<InfoOutlinedIcon color='primary' />}
                title={t('contract.contract_information')}
                closeBtn
                sx={{ width: { xs: "100%", sm: "80% !important", md: "70% !important", lg: "45% !important" }, maxWidth: { xs: "95%", sm: "80%", md: "70%", lg: "45%" } }}>
                <Box >
                    {
                        Object.keys(contractDetails).length > 0 &&
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={contractTab === 0 ? 6 : 12}>
                                    <Box>
                                        <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000' }}>{t('contract.contract_name')}</Typography>
                                        <Typography>{contractDetails.contract_name}</Typography>
                                    </Box>
                                </Grid>
                                {contractTab === 0 &&
                                    <Grid item xs={12} md={6}>
                                        <Box>
                                            <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000' }}>{t('contract.company_name')}</Typography>
                                            <Typography>{contractDetails.company?.company_name}</Typography>
                                        </Box>
                                    </Grid>
                                }
                                <Grid item xs={12} md={6}>
                                    <Box>
                                        <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000' }}>{t('common.documents')}</Typography>
                                        <Box >
                                            <>
                                                <Box sx={{ mt: 1 }}>
                                                    <Grid container spacing={1} >
                                                        {
                                                            contractDetails?.documents?.map((doc) => (
                                                                <Grid item key={doc.id} xs={12} sm={6} xl={4} >
                                                                    <Box sx={{ overflow: 'hidden', backgroundColor: 'background.light', position: 'relative', textDecoration: 'none', color: 'inherit' }} component={'a'} href={`${doc.view_file_url}`} target='_blank' >
                                                                        <Box sx={{ height: '120px', position: 'relative', cursor: 'pointer', '&:hover .show-icon': { opacity: 1 }, borderRadius: '5px 5px 0px 0px', overflow: 'hidden' }} >
                                                                            <Avatar sx={{ height: '100%', width: '100%', borderRadius: 0, objectFit: 'cover', backgroundColor: "primary.main", boxSizing: "border-box", fontSize: '16px' }}><InsertDriveFileOutlinedIcon sx={{ fontSize: 30, color: '#fff' }} /></Avatar>
                                                                        </Box>
                                                                        <Box sx={{ py: .6, px: .8, backgroundColor: 'background.light', borderRadius: '0px 0px 5px 5px' }}>
                                                                            <Typography sx={{ display: '-webkit-box', WebkitLineClamp: 1, overflow: 'hidden', textOverflow: 'ellipsis', WebkitBoxOrient: 'vertical', fontSize: '13px' }}>{doc.name}</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                </Box>
                                            </>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box>
                                        <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#000', mb: 1 }}>{t('common.assigned_users')}</Typography>
                                        <Paper className='scroll-bar' sx={{ border: '1px solid', boxShadow: 0, borderColor: '#f3f3f3', maxHeight: 200, overflow: "auto", }}>
                                            <List sx={{ m: 0 }}>
                                                {contractDetails?.users?.map(user => (
                                                    <ListItem key={user.id} sx={{ px: .8, py: .8, gap: .5, '&:not(:last-of-type)': { borderBottom: '1px solid #f3f3f3' } }}>
                                                        <ListItemAvatar sx={{ minWidth: 'auto' }} >
                                                            <Avatar sx={{ backgroundColor: 'primary.main', width: 30, height: 30, textTransform: "capitalize", fontSize: 15 }}>
                                                                {user.first_name[0]}
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText>
                                                            {user.first_name} {user.last_name}
                                                        </ListItemText>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Paper>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                </Box>
            </ModalBox>
            <ModalBox
                open={viewUsersLst.open}
                icon={<GroupOutlinedIcon color='primary' />}
                title={t('contract.contract_customer')}
                closeBtn
                onClose={() => { setViewUsersLst((prev) => ({ ...prev, open: false })) }} sx={{ width: { xs: "100%", sm: "80% !important", md: "70% !important", lg: "45% !important" }, maxWidth: { xs: 300, sm: 500 } }}>
                <Box sx={{ p: 1 }}>
                    <Box sx={{ border: '1px solid', borderColor: '#f3f3f3', borderRadius: 1, overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 500 }} className='scroll-bar'>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow sx={{ '& th': { fontWeight: 600, fontSize: 14, whiteSpace: 'nowrap', backgroundColor: '#f3f3f3', p: 1.5, border: 0, borderBottom: '1px solid', borderColor: '#f3f3f3', } }}>
                                        <TableCell>{t('common.first_name')}</TableCell>
                                        <TableCell>{t('common.last_name')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        viewUsersLst.users?.map((user) => (
                                            <TableRow key={user?.id} sx={{ '& td': { fontSize: 14, whiteSpace: 'nowrap', borderBottom: '1px solid', borderColor: '#f3f3f3', p: 1.5 }, '&:last-of-type td': { borderBottom: 0 } }}>
                                                <TableCell>{user.first_name}</TableCell>
                                                <TableCell>{user.last_name ? user.last_name : '-'}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </ModalBox>
        </Box >
    )
}

export default ContractsList
