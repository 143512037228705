import { useRef } from 'react'
import { Box, Button, Grid, InputAdornment, Typography, Stack } from '@mui/material'
import { Field, Form, Formik, ErrorMessage } from "formik";
import { TextField } from 'formik-mui'
import * as Yup from 'yup';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { alertMsg } from "../../utils/basicUtils";
import { API_KEY, MAP_SCRIPTS } from '../../constants';
import { useJsApiLoader, StandaloneSearchBox } from '@react-google-maps/api';
import { getAddressJSON } from '../../utils/basicUtils';
import useAxiosPrivate from '../../hooks/use-axios-private';
import { useTranslation } from 'react-i18next';

const PersonalDetails = ({ userDetail, setUserDetail }) => {
    const { t } = useTranslation()
    const inputRef = useRef();
    const axios = useAxiosPrivate()
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: API_KEY,
        libraries: MAP_SCRIPTS,
    })


    const handleProfile = async (values) => {
        try {
            const response = await axios.put(`users/update/${userDetail.id}`, { ...values, 'company_id': userDetail.is_admin || userDetail.is_superuser ? 0 : userDetail.company.id, 'company_name': userDetail.is_admin || userDetail.is_superuser ? '' : userDetail.company.company_name, })
            setUserDetail(response.data.data.user)
            alertMsg(response.data.message, 'success')


        }
        catch (err) {
            console.error(err)
        }
    }


    return (
        <Box>
            <Box>
                <Formik
                    initialValues={{ ...userDetail }}
                    validationSchema={
                        Yup.object({
                            first_name: Yup.string()
                                .required(t('profile.first_name_required')),
                            last_name: Yup.string()
                                .required(t('profile.last_name_required')),
                            mobile_number: Yup.string()
                                .required(t('profile.mobile_number_required')),
                        })
                    }
                    onSubmit={async (values, { setSubmitting }) => {
                        handleProfile(values)
                        setSubmitting(false)
                    }}
                    enableReinitialize={true}

                >
                    {({ isSubmitting, setFieldTouched, values, setFieldValue, setTouched, touched }) => (
                        <Form style={{ width: '100%' }}>


                            <Box sx={{ border: '1px solid', borderColor: 'background.light', borderRadius: 2 }}>
                                <Stack direction='row' sx={{ p: 1.5, gap: .5, alignItems: 'center', borderBottom: 1, borderColor: 'background.light' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 600 }}>{t('profile.personal_information')}</Typography>
                                </Stack>
                                <Box sx={{ p: 1.5, }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <Field
                                                component={TextField}
                                                fullWidth
                                                name="username"
                                                label={t('profile.username')}
                                                disabled
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <PersonIcon sx={{ color: "#93939396", mr: 1.5, fontSize: 20 }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={TextField}
                                                fullWidth
                                                name="first_name"
                                                label={`${t('profile.first_name')} *`}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <PersonIcon sx={{ color: "#93939396", mr: 1.5, fontSize: 20 }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={TextField}
                                                fullWidth
                                                name="last_name"
                                                label={`${t('profile.last_name')} *`}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <PersonIcon sx={{ color: "#93939396", mr: 1.5, fontSize: 20 }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>


                            <Box sx={{ border: '1px solid', borderColor: 'background.light', borderRadius: 2, mt: 2 }}>
                                <Stack direction='row' sx={{ p: 1.5, gap: .5, alignItems: 'center', borderBottom: 1, borderColor: 'background.light' }}>
                                    <Typography variant="body1" sx={{ fontWeight: 600 }}>{t('profile.contact_information')}</Typography>
                                </Stack>
                                <Box sx={{ p: 1.5, }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <PhoneInput
                                                className="react-tel-input profile-pho-inp"
                                                country={'se'}
                                                name="mobile_number"
                                                countryCodeEditable={false}
                                                specialLabel=''
                                                value={(values.mobile_code ? values.mobile_code : "+1") + (values.mobile_number ? values.mobile_number : "")}
                                                onBlur={() => { setFieldTouched('mobile_number', true) }}
                                                onChange={(phoneNumber, phoneNumberObj) => {
                                                    setFieldValue('mobile_number', phoneNumber.slice(phoneNumberObj.dialCode.length))
                                                    setFieldValue('mobile_code', phoneNumberObj.dialCode)
                                                    setFieldValue('country_code', phoneNumberObj.countryCode)
                                                }}
                                                fullWidth
                                            />
                                            <ErrorMessage component="span" name="mobile_number" />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            {isLoaded &&
                                                <StandaloneSearchBox
                                                    onLoad={ref => inputRef.current = ref}
                                                    onPlacesChanged={(e) => {
                                                        try {
                                                            const [place] = inputRef.current.getPlaces();
                                                            if (place) {
                                                                const placeObj = getAddressJSON(place)
                                                                setFieldValue("street", `${placeObj.street_no ? placeObj.street_no : ""}${placeObj.street ? placeObj.street : ""}`)
                                                                setFieldValue("city", placeObj.city ? placeObj.city : "")
                                                                setFieldValue("state", placeObj.state ? placeObj.state : "")
                                                                setFieldValue("country", placeObj.country ? placeObj.country : "")
                                                                setFieldValue("zipcode", placeObj.zipcode ? placeObj.zipcode : "")
                                                                setTouched({
                                                                    ...touched,
                                                                    street_address: false,
                                                                    "street": false,
                                                                    "city": false,
                                                                    "state": false,
                                                                    "country": false,
                                                                    "zipcode": false,
                                                                })
                                                            }
                                                        } catch (error) {
                                                            console.error('error: ', error.message);
                                                        }
                                                    }} >
                                                    <Field
                                                        component={TextField}
                                                        fullWidth
                                                        size="small"
                                                        name="street"
                                                        label={t('profile.street')}
                                                        placeholder=""
                                                        type="text"
                                                    />
                                                </StandaloneSearchBox>
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={TextField}
                                                fullWidth
                                                name="city"
                                                label={t('profile.city')}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LocationOnOutlinedIcon sx={{ color: "#93939396", mr: 1.5, fontSize: 20 }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={TextField}
                                                fullWidth
                                                name="state"
                                                label={t('profile.state')}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LocationOnOutlinedIcon sx={{ color: "#93939396", mr: 1.5, fontSize: 20 }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={TextField}
                                                fullWidth
                                                name="country"
                                                label={t('profile.country')}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LocationOnOutlinedIcon sx={{ color: "#93939396", mr: 1.5, fontSize: 20 }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                component={TextField}
                                                fullWidth
                                                name="zipcode"
                                                label={t('profile.zip_code')}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LocationOnOutlinedIcon sx={{ color: "#93939396", mr: 1.5, fontSize: 20 }} />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                            </Box>


                            <Box sx={{ mt: 1.5, textAlign: 'right' }}>
                                <Button disabled={isSubmitting}
                                    variant="contained"
                                    type='submit'
                                    color="primary">{t('common.save_changes')}</Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box >
    )
}

export default PersonalDetails