import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { Box, Paper, Button, Stack, Grid, Typography, InputAdornment, Container, Divider, FormControlLabel, CircularProgress, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { TextField as MuiTextField } from 'formik-mui'
import Checkbox from '@mui/material/Checkbox';
import { Field, Form, Formik } from "formik";
import GoogleIcon from '@mui/icons-material/Google';
import { alertMsg } from "../../utils/basicUtils";
import logo from '../../assets/images/logo.png';
import BgImage from '../../assets/images/login.jpeg';
import Slide from '@mui/material/Slide';
import { authActions } from "../../redux/reducers/auth-slice";
import { useDispatch, useSelector } from "react-redux";
import { axiosPrivate } from "../../utils/apiUtils";
import { REFRESH_TOKEN_EXPIRY_IN_HRS } from "../../constants";
import useAxios from "../../hooks/use-axios";
import ModalBox from '../../components/ModalBox'
import LockPersonIcon from '@mui/icons-material/LockPerson';
import * as Yup from 'yup';
import FlagSwitch from "../../components/FlagSwitch";
import { uiActions } from "../../redux/reducers/ui-slice";
import { useTranslation } from "react-i18next";

const SignIn = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const axios = useAxios()
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [isSignin, setIsSignin] = useState(true);
    const [isForgetPassword, setIsForgetPassword] = useState(false);
    const { language } = useSelector(state => state.ui)
    const { t, i18n } = useTranslation();

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSignIn = async (values) => {
        try {
            const response = await axiosPrivate.post("accounts/signin/", values)
            alertMsg(response.data.message, 'success')
            dispatch(authActions.grantLoginAccess({
                accessToken: response.data.data.access_token,
                user: { ...response.data.data.user },
                isLoggedIn: true
            }));
            localStorage.clear()
            localStorage.setItem('isLoggedIn', true)
            localStorage.setItem('lang', 'en')
            let currentTime = new Date()
            currentTime.setHours(currentTime.getHours() + +REFRESH_TOKEN_EXPIRY_IN_HRS)
            localStorage.setItem('lastLogIn', currentTime.getTime())
            navigate('/')
        }
        catch (err) {
            console.error(err)

        }
    }
    const handleSignup = async (values, { resetForm }) => {
        try {
            const response = await axios.post('accounts/signup/', { ...values })
            // console.log("🚀 ~ handleSubmitSignup ~ response:", response)
            resetForm()
            setIsSignin(true)
            alertMsg(response.data.message, 'success')
        } catch (err) {
            console.error(err)
        }
    }

    const forgotPassword = async (values, { setSubmitting, resetForm }) => {
        try {
            const response = await axios.post("accounts/forgot_password/", { email: values.email })
            resetForm()
            alertMsg(response.data.message, 'success')
            setIsForgetPassword(false)
        }
        catch (error) {
            console.error(error)
        }
        setSubmitting(false);
    }

    const handleLanguage = async (e) => {
        const check = e.target.checked
        try {
            dispatch(uiActions.setLanguage(check ? 'se' : 'en'));
            i18n.changeLanguage(check ? 'se' : 'en')
        } catch (err) {
            console.error(err)
        }
    }


    useEffect(() => {
        i18n.changeLanguage(language);
    }, [])
    return (
        <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center', overflow: "auto", backgroundColor: 'primary.light' }}>
            <Container maxWidth={'lg'} sx={{ overflow: "hidden", my: 2 }}>
                <Paper sx={{ transition: ".4s all ease", position: "relative", overflow: { xs: "hidden", md: "visible" } }} >
                    <Box sx={{ display: { xs: "none", md: "flex" }, transition: ".5s all ease", zIndex: 1, position: "absolute", top: 0, bottom: 0, transform: `translateX(${isSignin ? 0 : "100%"})`, width: "50%", boxSizing: "border-box", height: 1, p: 2, alignItems: "end", background: `url(${BgImage})`, backgroundSize: "cover", backgroundPosition: "left", backgroundRepeat: 'no-repeat', boxShadow: 'inset 0 -150px 0 0px rgb(0 0 0 / 30%)' }}>
                        <Box>
                            <Typography variant="body1" sx={{ fontSize: 15, fontWeight: 500, color: '#fff', textAlign: 'justify' }}>
                                {t('signin.banner_des')}</Typography>
                        </Box>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item sx={{ display: { xs: isSignin ? "none" : "block", md: "block" }, overflow: "hidden" }} xs={12} md={6}>
                            <Box sx={{ minHeight: { xs: "100%", sm: "60vh", lg: "60vh", xl: "75vh" }, display: "flex", alignItems: "center" }}>
                                {/* <Slide direction="left" timeout={500} in={!isSignin} mountOnEnter unmountOnExit>
                                    <Stack sx={{ justifyContent: "center", flexGrow: 1 }}>
                                        <Paper sx={{ py: 4, px: 2, bgcolor: "transparent", overflow: 'hidden', boxShadow: 'none', overflow: 'hidden' }}>
                                            <Stack gap={2}>
                                                <Box sx={{ maxWidth: 400, mx: "auto", width: 1, mt: 2 }}>
                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <img src={logo} alt="" style={{ maxWidth: "120px", maxHeight: '120px', margin: 'auto' }} />
                                                    </Box>
                                                    <Box sx={{ mt: 2 }}>
                                                        <Typography sx={{ fontSize: "22px", textAlign: "center", mb: 1, fontWeight: 600 }}>Join for success!</Typography>
                                                        <Typography sx={{ fontSize: "13px", textAlign: "center", color: '#8f8f8f', fontWeight: 500 }}>Are you ready to take the next step towards a successful future? Look no further than Circlez!</Typography>
                                                    </Box>
                                                    <Box sx={{ mt: 2 }}>
                                                        <Formik
                                                            initialValues={{
                                                                first_name: '',
                                                                last_name: '',
                                                                username: '',
                                                                email: '',
                                                                password: '',
                                                            }}
                                                            validationSchema={
                                                                Yup.object({
                                                                    first_name: Yup.string()
                                                                        .required('Name is required')
                                                                        .matches(/^[a-zA-Z\s]+$/, 'Name cannot contain special character and numbers')
                                                                        .min(3, 'Name must be at least 3 characters')
                                                                        .max(50, 'Name cannot exceed 50 characters'),
                                                                    email: Yup.string()
                                                                        .email('Invalid email address')
                                                                        .required('Email is required'),
                                                                    password: Yup.string()
                                                                        .required('Password is required')
                                                                        .min(6, "Must be at least 6 characters")
                                                                        .max(100, "Password maximum 100 characters only Allowed"),
                                                                    // .matches(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,100}$/, 'Password can contain atleast 1 number and 1 special character'),
                                                                })
                                                            }
                                                            onSubmit={handleSignup}
                                                            enableReinitialize={true}
                                                           

                                                        >
                                                            {({ errors, touched, isSubmitting, setFieldValue, values }) => (
                                                                <Form style={{ width: '100%' }}>
                                                                    <Typography variant="body1" color="initial" sx={{ fontSize: 12, mb: .5 }}>Name</Typography>
                                                                    <Field
                                                                        component={MuiTextField}
                                                                        fullWidth
                                                                        size="small"
                                                                        name="first_name"
                                                                        sx={{ ...inputStyles }}
                                                                        value={values.first_name || ''}
                                                                        InputProps={{

                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <AccountCircleOutlinedIcon sx={{ mr: 1.5, fontSize: 20 }} />
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                    <Typography variant="body1" color="initial" sx={{ fontSize: 12, mb: .5, mt: 2 }}>Email</Typography>
                                                                    <Field
                                                                        component={MuiTextField}
                                                                        fullWidth
                                                                        size="small"
                                                                        type="email"
                                                                        name="email"
                                                                        sx={{ ...inputStyles }}
                                                                        value={values.email || ''}
                                                                        InputProps={{

                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <EmailOutlinedIcon sx={{ mr: 1.5, fontSize: 20 }} />
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                    <Typography variant="body1" color="initial" sx={{ fontSize: 12, mb: .5, mt: 2 }}>Password</Typography>
                                                                    <Field
                                                                        component={MuiTextField}
                                                                        fullWidth
                                                                        type={showPassword ? "text" : "password"}
                                                                        size="small"
                                                                        sx={{ ...inputStyles }}
                                                                        name="password"
                                                                        value={values.password || ''}
                                                                        InputProps={{

                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        onClick={handleClickShowPassword}
                                                                                        onMouseDown={handleMouseDownPassword}
                                                                                        edge="end"
                                                                                        sx={{ mr: .5 }}
                                                                                    >
                                                                                        {showPassword ? (
                                                                                            <VisibilityOutlinedIcon sx={{ fontSize: 20 }} />
                                                                                        ) : (
                                                                                            <VisibilityOffOutlinedIcon sx={{ fontSize: 20 }} />
                                                                                        )}
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                    <Button disabled={isSubmitting} sx={{ width: 1, mt: 3, fontWeight: 600 }} variant="contained" fullWidth type='submit' color="primary">Sign Up</Button>
                                                                    <Box>
                                                                        <Divider sx={{ "&:before, &:after": { border: 1, borderColor: "#f0f0f0" }, my: 3 }}><Typography variant="body1" sx={{ mx: 2, fontWeight: 400 }}>or</Typography></Divider>
                                                                        <Box sx={{ display: "flex", gap: { xs: 2, sm: 4 }, "& > button": { borderRadius: 1.5, width: 1, border: 1, fontSize: 14, py: 1.2, '&:hover': { backgroundColor: 'transparent' } } }}>
                                                                            <Button variant="text" sx={{ textTransform: 'initial', color: "primary.main", border: 1, borderColor: "#dfdfdf !important", }} startIcon={<GoogleIcon sx={{ fontSize: "18px !important" }} />} >Log in with Google</Button>
                                                                        </Box>
                                                                    </Box>
                                                                    <Typography variant="body1" sx={{ mt: 3, textAlign: "center", "& span": { cursor: "pointer" } }}>Already have an account? <Typography sx={{ textDecoration: 'underline' }} component={"span"} onClick={() => { setIsSignin(true) }}>Signin</Typography></Typography>
                                                                </Form>
                                                            )}
                                                        </Formik>
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        </Paper>
                                    </Stack>
                                </Slide> */}
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: { xs: !isSignin ? "none" : "flex", md: "flex" }, alignItems: "center", overflow: "hidden" }}>
                            <Box sx={{ position: "relative", display: "flex", alignItems: "center", flexGrow: 1 }}>

                                <Slide direction="right" timeout={500} in={isSignin} mountOnEnter unmountOnExit>
                                    <Stack sx={{ justifyContent: "center", flexGrow: 1, minHeight: { xs: "100%", sm: "60vh", lg: "60vh", xl: "75vh" }, }}>
                                        <Paper sx={{ py: 4, px: 2, bgcolor: "transparent", boxShadow: 'none' }}>
                                            <Box sx={{ position: "absolute", right: 5, top: 5 }}>
                                                <FlagSwitch checked={language !== 'en'} onChange={handleLanguage} />
                                            </Box>
                                            <Stack gap={2}>
                                                <Box sx={{ maxWidth: 400, mx: "auto", width: 1, mt: 2 }}>
                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <img src={logo} alt="" style={{ maxWidth: "120px", maxHeight: '120px', margin: 'auto' }} />
                                                    </Box>
                                                    <Box sx={{ mt: 2 }}>
                                                        <Typography sx={{ fontSize: "22px", textAlign: "center", mb: 1, fontWeight: 600 }}>{t('signin.welcome')}</Typography>
                                                        <Typography sx={{ fontSize: "13px", textAlign: "center", color: '#8f8f8f', fontWeight: 500 }}>{t('signin.signin_des')}</Typography>
                                                    </Box>
                                                    <Box sx={{ mt: 2 }}>
                                                        <Formik
                                                            initialValues={{
                                                                "username": "",
                                                                "password": "",
                                                            }}
                                                            validationSchema={
                                                                Yup.object({
                                                                    username: Yup.string()
                                                                        .required(t('signin.user_name_required')),
                                                                    password: Yup.string()
                                                                        .required(t('signin.password_required')),
                                                                })
                                                            }
                                                            onSubmit={handleSignIn}
                                                            enableReinitialize={true}>
                                                            {({ errors, touched, isSubmitting, setFieldValue, values }) => (
                                                                <Form style={{ width: '100%' }}>
                                                                    <Typography variant="body1" color="initial" sx={{ fontSize: 12, mb: .5 }}>{t('signin.username')}</Typography>
                                                                    <Field
                                                                        component={MuiTextField}
                                                                        fullWidth
                                                                        size="small"
                                                                        name="username"
                                                                        sx={{ ...inputStyles }}
                                                                        value={values.username || ''}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <AccountCircleOutlinedIcon sx={{ mr: 1.5, fontSize: 20 }} />
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                    <Typography variant="body1" color="initial" sx={{ fontSize: 12, mb: .5, mt: 2 }}>{t('signin.password')}</Typography>
                                                                    <Field
                                                                        component={MuiTextField}
                                                                        fullWidth
                                                                        type={showPassword ? "text" : "password"}
                                                                        size="small"
                                                                        sx={{ ...inputStyles }}
                                                                        name="password"
                                                                        value={values.password || ''}
                                                                        InputProps={{

                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        onClick={handleClickShowPassword}
                                                                                        onMouseDown={handleMouseDownPassword}
                                                                                        edge="end"
                                                                                        sx={{ mr: .5 }}
                                                                                    >
                                                                                        {showPassword ? (
                                                                                            <VisibilityOutlinedIcon sx={{ fontSize: 20 }} />
                                                                                        ) : (
                                                                                            <VisibilityOffOutlinedIcon sx={{ fontSize: 20 }} />
                                                                                        )}
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                    <Box sx={{ display: { xs: 'block', sm: "flex" }, alignItems: "center", my: 1, justifyContent: "end" }}>
                                                                        {/* <FormControlLabel sx={{ "& span": { fontWeight: 300, fontSize: 10 } }} control={<Checkbox size="small" />} label="Keep me signed in." /> */}
                                                                        <Typography onClick={() => setIsForgetPassword(true)} sx={{ fontSize: 12, cursor: "pointer", textAlign: 'right' }}>{t('signin.forget_password')}</Typography>
                                                                    </Box>
                                                                    <Button disabled={isSubmitting} sx={{ width: 1, mt: 1, fontWeight: 600, "&:after": { boxShadow: "none !important" }, }} variant="contained" fullWidth type='submit'>{t('signin.login')}</Button>
                                                                    {/* <Box>
                                                                        <Divider sx={{ "&:before, &:after": { border: 1, borderColor: "#f0f0f0" }, my: 3 }}><Typography variant="body1" sx={{ mx: 2, fontWeight: 400 }}>or</Typography></Divider>
                                                                        <Box sx={{ display: "flex", gap: { xs: 2, sm: 4 }, "& > button": { borderRadius: 1.5, width: 1, border: 1, fontSize: 14, py: 1.2, '&:hover': { backgroundColor: 'transparent' } } }}>
                                                                            <Button variant="text" sx={{ textTransform: 'initial', color: "primary.main", border: 1, borderColor: "#dfdfdf !important", }} startIcon={<GoogleIcon sx={{ fontSize: "18px !important" }} />} >Log in with Google</Button>
                                                                        </Box>
                                                                    </Box>
                                                                    <Typography variant="body1" sx={{ mt: 3, textAlign: "center", "& span": { cursor: "pointer" } }}>Don't have an account? <Typography sx={{ textDecoration: 'underline' }} component={'span'} onClick={() => { setIsSignin(false) }}>Sign up</Typography></Typography> */}
                                                                </Form>
                                                            )}
                                                        </Formik>
                                                    </Box>
                                                </Box>
                                            </Stack>
                                        </Paper>
                                    </Stack>
                                </Slide>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper >
            </Container>
            <ModalBox
                open={isForgetPassword}
                sx={{ border: 0, boxShadow: 0, width: 400, borderRadius: 1.4, p: 1 }}
            >
                <Box sx={{ p: 2, borderRadius: 1, background: 'linear-gradient(#fef3e2 , transparent 50%)' }}>
                    <Formik
                        initialValues={{
                            "email": "",
                        }}
                        validationSchema={
                            Yup.object({
                                email: Yup.string()
                                    .required(t('signin.email_required')),
                            })
                        }
                        onSubmit={forgotPassword}
                        enableReinitialize={true}

                    >
                        {({ errors, touched, isSubmitting, setFieldValue }) => (
                            <Form style={{ width: '100%' }}>
                                <Box sx={{ textAlign: "center", width: 80, height: 80, backgroundColor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', mx: 'auto' }}>
                                    <LockPersonIcon sx={{ fontSize: 35, color: "primary.main" }} />
                                </Box>
                                <Box>
                                    <Typography sx={{ fontSize: { xs: "14px", md: '16px' }, fontWeight: "500", my: 2, textAlign: "center" }}>{t('signin.forget_password')}</Typography>
                                    <Typography sx={{ fontSize: { xs: "14px" }, fontWeight: "500", marginTop: "6px", textAlign: "center" }} color='text.secondary' >{t('signin.forget_password_des')}</Typography>
                                </Box>
                                <Box>
                                    <Field
                                        component={MuiTextField}
                                        fullWidth
                                        sx={{ mt: 2.5 }}
                                        size="small"
                                        name="email"
                                        label={`${t('signin.email')}*`}
                                        inputProps={{
                                            style: { backgroundColor: '#fff' }
                                        }}
                                        InputProps={{
                                            style: { backgroundColor: '#fff' },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <AccountCircleOutlinedIcon sx={{ color: "#93939396", mr: 1.5, fontSize: 20 }} />
                                                </InputAdornment>
                                            ),
                                        }}

                                    />
                                </Box>
                                <Box sx={{ mt: 5 }}>
                                    <Stack gap={1} direction={'row'} alignItems="center" sx={{
                                        justifyContent: 'center'
                                    }}>
                                        <Button color={"primary"} disabled={isSubmitting} type="submit" sx={{ px: 4 }} variant="contained">{t('signin.submit')}
                                            {isSubmitting && <CircularProgress sx={{ width: "20px !important", height: "20px !important", verticalAlign: "middle", ml: 1, color: "grey.500" }} />}
                                        </Button>
                                        <Button sx={{ px: 4 }} disabled={isSubmitting} variant="outlined" onClick={() => { setIsForgetPassword(false) }}>{t('signin.back')}</Button>
                                    </Stack>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </ModalBox>
        </Box>
    );
};

export default SignIn;

const inputStyles = {
    borderRadius: 1,
    transition: ".3s all linear",
    // "& *": { border: "0 !important" },
    "& .MuiFormHelperText-root": {
        color: "#ff5f5f !important"
    },
    "& .MuiInputBase-root": {
        border: 1,
        borderColor: "#ffffff38",
        "& input": { py: 1.3, fontSize: 13, px: 1.6, color: "#222", fontWeight: 400 }
    }
}