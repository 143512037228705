import { Box, List, Stack, Typography, Menu, MenuItem, ListItemButton, ListItemIcon, ListItemText, Collapse, Button, keyframes } from '@mui/material'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import dashboardIcon from '../assets/images/dashboard.png'
import SalarySlip from '../assets/images/salary.png'
import Project from '../assets/images/project.png'
import Users from '../assets/images/group.png'
import Contracts from '../assets/images/contracts.png'
import Logo from '../assets/images/logo.png'
import calendarIcon from '../assets/images/calendar.png'
import useSignout from '../hooks/use-signout';
import useAuth from '../hooks/use-auth';
import { useTranslation } from 'react-i18next';

const linkStyles = {
	"& svg": { fill: "#fff" },
	bgcolor: "#ffd492",
	color: "#fff",
	"&:hover": { bgcolor: "#ffd492" },
	"&:after": {
		content: "''",
		position: "absolute",
		height: "100%",
		width: "6px",
		bottom: "0px",
		right: "0px",
		borderRadius: "6px 0px 0px 6px",
		backgroundColor: "#e78c00",
	},
	"& .MuiListItemText-primary": {
		color: "#000",
		fontWeight: 500
	},
	"& .MuiListItemIcon-root p": {
		color: "#000",
		fontWeight: 500
	}
}

const childLinkStyles = {
	position: 'relative',
	'&:before': {
		position: 'absolute',
		content: '""',
		height: '100%',
		left: 22,
		top: 0,
		borderRight: '1px dashed #ffffff80'
	},
	"& .MuiListItemText-primary": {
		color: "#ffffff80"
	},
	".MuiListItemButton-root": {
		position: "relative",
		"&:before": {
			content: "''",
			position: "absolute",
			width: "15px",
			left: "10px",
			borderTop: '1px dashed #ffffff80'
		}
	},
	"& .active > .MuiListItemButton-root .MuiListItemText-primary": {
		color: "#fff"
	},
	"& .active > .MuiListItemButton-root:before": {
		borderColor: "#fff"
	}
}


const ListMenu = ({ menuItem, open }) => {
	const [isViewed, setIsViewed] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const { user } = useAuth()


	const handleClick = (e) => {
		if (open) {
			setIsViewed(!isViewed)
			setAnchorEl(null)
		} else {
			setAnchorEl(e.currentTarget)
		}
	};

	return (
		<>
			{menuItem.sub_menu.length ?
				<>
					<Box id={menuItem.menu_link} className={'parent-nav'}>
						<Box className='menu-head'>
							<ListItemButton onClick={(e) => { handleClick(e) }} sx={{ px: open ? 2 : 1 }}>
								<ListItemIcon sx={{ minWidth: 0, mx: 'auto', mr: open ? 1.5 : "auto", justifyContent: 'center', flexDirection: "column", alignItems: "center", textAlign: "center" }}>
									<Box component={"img"} src={menuItem.menu_icon} sx={{ height: 25, }} alt="" />
									{!open && <Typography variant="body1" sx={{ fontSize: 10, mt: .3 }} color="#000">{menuItem.menu_alias_name}</Typography>}
								</ListItemIcon>
								<ListItemText primary={menuItem.menu_alias_name} sx={{ opacity: open ? 1 : 0, transform: !open ? "scaleX(0)" : "scaleX(1)", transformOrigin: 'left', transition: '.4s' }} />
								{open && <>
									{isViewed ? <ExpandLess /> : <ExpandMore />}
								</>}
							</ListItemButton>
						</Box>
						<Collapse in={isViewed && open} timeout="auto">
							<List component="div" disablePadding sx={{ ...childLinkStyles, pl: 2 }} >
								{
									menuItem.sub_menu.map((item, index) => (
										<NavLink key={index} to={`${menuItem.menu_link}${item.menu_link}`}>
											<ListItemButton disableRipple sx={{ pl: 4 }}>
												<ListItemText primary={item.menu_alias_name} />
											</ListItemButton>
										</NavLink>
									))
								}
							</List>
						</Collapse>
						<Menu
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={() => { setAnchorEl(null) }}
							PaperProps={{
								elevation: 0,
								sx: {
									mt: 1.5,
									bgcolor: '#0f172a',
									minWidth: 150,
									width: 'auto',
									border: '1px solid grey',
									borderRadius: 1.5
								},
							}}
						>
							{menuItem.sub_menu.map((item, index) => (
								<NavLink key={index} style={{ textDecoration: "none" }} to={`${menuItem.menu_link}${item.menu_link}`}>
									<MenuItem sx={{ mb: 1, pl: 3, position: "relative", "&:after": { content: "''", position: "absolute", width: 5, height: 5, borderRadius: 10, left: 8, background: '#ffffff80' }, "&:has(.active)::after": { bgcolor: "#ffffff80" } }} onClick={() => { setAnchorEl(null) }}>
										<Typography variant="body1" color="#ffffff80">{item.menu_alias_name}</Typography>
									</MenuItem>
								</NavLink>
							))}
						</Menu>
					</Box>

				</>
				:
				menuItem.role_id.includes(user.role_id)
				&&
				<>
					<NavLink to={menuItem.menu_link} className={"parent-nav"}>
						<ListItemButton sx={{ minHeight: 48, justifyContent: 'start', px: 2 }}>
							<ListItemIcon sx={{ minWidth: open ? 0 : "-webkit-fill-available", mx: 'auto', mr: open ? 1.5 : "auto", justifyContent: 'center', flexDirection: "column", alignItems: "center" }}>
								<Box component={"img"} src={menuItem.menu_icon} sx={{ height: 20, }} alt="" />
								{!open && <Typography variant="body1" sx={{ fontSize: 10, mt: .3, textAlign: 'center', '& p': { fontWeight: 600 } }} color="#000">{menuItem.menu_alias_name}</Typography>}
							</ListItemIcon>
							<ListItemText primary={menuItem.menu_alias_name} sx={{ width: open ? "100%" : "0", visibility: open ? "visible" : "hidden", color: '#ffffff80', '& span': { fontSize: 16, } }} />
						</ListItemButton>
					</NavLink>
				</>
			}
		</>
	)
}

const Sidebar = ({ open }) => {
	const signout = useSignout()
	const { user } = useAuth()
	const { t } = useTranslation();

	const menuItems = [
		// {
		// 	"id": 1,
		// 	"menu_name": "Dashboard",
		// 	"menu_alias_name": "Dashboard",
		// 	"menu_link": "/",
		// 	"menu_icon": dashboardIcon,
		// 	"is_active": true,
		// 	"sub_menu": [],
		// 	'role_id': [1, 2, 3, 4]
		// },
		{
			"id": 2,
			"menu_name": t('menu.contract'),
			"menu_alias_name": t('menu.contract'),
			"menu_link": "/",
			"menu_icon": Contracts,
			"is_active": true,
			"sub_menu": [],
			'role_id': [1, 2, 3, 4]
		},
		{
			"id": 3,
			"menu_name": t('menu.timesheet'),
			"menu_alias_name": t('menu.timesheet'),
			"menu_link": "/timesheets",
			"menu_icon": calendarIcon,
			"is_active": true,
			"sub_menu": [],
			'role_id': [1, 2, 3]
		},
		{
			"id": 4,
			"menu_name": t('menu.salary_slip'),
			"menu_alias_name": t('menu.salary_slip'),
			"menu_link": "/salary-slip",
			"menu_icon": SalarySlip,
			"is_active": true,
			"sub_menu": [],
			'role_id': [1, 2, 4]
		},
		{
			"id": 5,
			"menu_name": t('menu.users'),
			"menu_alias_name": t('menu.users'),
			"menu_link": "/users",
			"menu_icon": Users,
			"is_active": true,
			"sub_menu": [],
			'role_id': [1, 2]
		},
		{
			"id": 6,
			"menu_name": user.role_id === 3 ? t('menu.project') : t('menu.customer'),
			"menu_alias_name": user.role_id === 3 ? t('menu.project') : t('menu.customer'),
			"menu_link": "/project",
			"menu_icon": Project,
			"is_active": true,
			"sub_menu": [],
			'role_id': [1, 2, 3]
		},

	]

	return (
		<Box sx={{ display: { xs: "none", md: "block" }, width: { md: 80, lg: open ? 280 : 80 }, overflow: "hidden", height: '100vh', flexGrow: 1, position: "sticky", top: 0, zIndex: 100, "& *": { boxSizing: "border-box" }, backgroundColor: 'background.sidebar' }} >
			<Stack className={`sidebar`} sx={{ gap: 2, justifyContent: "space-between", transition: '.5s', height: '100%', boxSizing: "border-box", position: "relative" }} >
				<Box>
					<Box sx={{ pt: 2.4, pb: 4, px: open ? 2 : 1, width: 1, boxSizing: "border-box" }}>
						<Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: open ? "start" : "center" }}>
							{/* <FitbitIcon sx={{ color: "#fff", fontSize: 30 }} /> */}
							<img src={Logo} style={{ maxWidth: open ? 100 : 65, transition: '.5s' }} alt="" />
							{/* {open && <Typography variant="body1" color="white" sx={{ fontSize: 23, fontWeight: 600 }}>iSpace</Typography>} */}
						</Box>
					</Box>
					<Box sx={{ maxHeight: "calc(100vh - 310px)", overflowY: "auto", overflowX: "hidden", height: '100%', pt: .2 }} className="scroll-bar">
						<List component="nav" sx={{ "& *": { transition: ".4s all ease" }, width: '100%', '& *': { color: "#000" }, "& .parent-nav:has(.active) .menu-head > .MuiListItemButton-root , & .parent-nav.active  > .MuiListItemButton-root": { ...linkStyles }, "& a": { textDecoration: "none" }, '& .MuiListItemText-root': { my: .2 } }}>
							{menuItems.map((item, index) => <ListMenu open={open} menuItem={item} key={index} />)}
						</List>
					</Box>
				</Box>
				<Box sx={{ px: 1, pt: 2, pb: 1, boxSizing: "border-box" }}>
					<Box sx={{ display: "flex", flexDirection: open ? "row" : "column-reverse", gap: 1, color: '#000' }}>
						<Button variant="outlined" startIcon={<LogoutIcon sx={{ rotate: "180deg", ml: open ? 0 : 1 }} />} sx={{ border: 1, flexGrow: 1, textAlign: "start", fontSize: 12, p: 1, color: 'inherit', minWidth: 0, '&:hover': { borderColor: '#000' } }}
							onClick={() => {
								signout()
							}}
						>{open && t('menu.sign_out')}</Button>
					</Box>
					<Box sx={{ mt: 1, textAlign: open ? "right" : 'center' }}>
						<Typography>V {process.env.REACT_APP_VERSION || "DEV"}</Typography>
					</Box>
				</Box>
			</Stack >
		</Box >
	)
}

export default Sidebar